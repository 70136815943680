import { createStore, applyMiddleware } from "redux";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import autoMergeLevel2 from "redux-persist/lib/stateReconciler/autoMergeLevel2";
import createSagaMiddleware from "redux-saga";
import rootReducer from "./reducers";
import rootSaga from "./sagas";

/* ------------- Redux Configuration ------------- */
const middleware = [];
const enhancers = [];

/* ------------- Saga Middleware ------------- */
const sagaMiddleware = createSagaMiddleware();
middleware.push(sagaMiddleware);

/* ------------- Assemble Middleware ------------- */
enhancers.push(applyMiddleware(...middleware));

/* ------------- Attach redux dev tool for development environment ------------- */
let composeEnhancers =
  require("redux-devtools-extension/developmentOnly").composeWithDevTools;

const persistConfig = {
  key: "root",
  storage: storage,
  whitelist: ["auth", "menu"],
  stateReconciler: autoMergeLevel2,
};
const pReducer = persistReducer(persistConfig, rootReducer);

/* ------------- Create store ------------- */
const store = createStore(pReducer, composeEnhancers(...enhancers));

// kickoff root saga
sagaMiddleware.run(rootSaga);
/* eslint import/no-anonymous-default-export: [2, {"allowObject": true}] */
const persistor = persistStore(store);
export { store, persistor };
