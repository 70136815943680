import React, { useState, useEffect } from "react";
import StringUtils from "../../../utils/StringUtils";
import DatePicker, { registerLocale } from "react-datepicker";
import ja from "date-fns/locale/ja";
import { useSelector } from "react-redux";

registerLocale("ja", ja);
export default function DatePickerCustom(props) {
  const convertDate = (value) => {
    let date = value.trim();
    let length = date.length;

    let year;
    let month;
    let day;

    if (length === 7) {
      year = date.substring(0, 4);
      month = `0${date.substring(4, 5)}`;
      day = date.substring(5, 7);
    }
    if (length === 8) {
      year = date.substring(0, 4);
      month = date.substring(4, 6);
      day = date.substring(6, 8);
    }
    return `${year}/${month}/${day}`;
  };
  const [startDate, setStartDate] = useState(new Date(convertDate(props.date)));
  const editErrors = useSelector(
    (state) => state.orderTypeMaintenanceMaster.edit_errors
  );

  const [message, setMessage] = useState("");

  useEffect(() => {
    if (
      typeof editErrors !== "undefined" &&
      editErrors &&
      props.column.field === "enddate"
    ) {
      let rowsClone = [...props.tableManager.rowsApi.rows];
      let updatedRowIndex = rowsClone.findIndex((r) => r.id === props.data.id);
      let error = editErrors[updatedRowIndex];
      setMessage(error);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editErrors]);

  return (
    <div className="datepicker-custom">
      <DatePicker
        locale="ja"
        dateFormat="yyyy/MM/dd"
        selected={startDate}
        onChange={(date) => {
          let requestData = {
            ...props.data,
            [props.column.field]: StringUtils.yyyymmdd(date),
          };
          let rowsClone = [...props.tableManager.rowsApi.rows];
          let updatedRowIndex = rowsClone.findIndex(
            (r) => r.id === props.data.id
          );
          rowsClone[updatedRowIndex] = requestData;
          props.setRowsData(rowsClone);
          setStartDate(date);
        }}
        name={props.name}
        className="form-control"
        showMonthDropdown
        showYearDropdown
        dropdownMode="select"
      />
      <div className="invalid-feedback">{message}</div>
    </div>
  );
}
