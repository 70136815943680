import { debounce } from "lodash";
import React, { useEffect, useRef, useState } from "react";
import { Form, Spinner } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { getNameByOrderTypeCode } from "../../../services/ordertype/orderTypeService";
import { selectOrderTypeError } from "../../../stores/reducers/orderTypeReducer";

const MAX_LENGTH_INPUT_ORDER_TYPE_CODE = 15;
const MAX_LENGTH_INPUT_ORDER_TYPE_NAME = 40;

function OrderTypeRow({ data, patternSource, onChange }) {
  const { t } = useTranslation();
  const orderTypeNameRef = useRef();

  // state
  const [isDuplicatedData, setIsDuplicatedData] = useState(false);
  const [nameLoading, setNameLoading] = useState(false);
  const [nameDisabled, setNameDisabled] = useState(false);

  // state redux
  const orderTypeError = useSelector(selectOrderTypeError);

  const handleDeleteChange = (e) => {
    const newData = {
      ...data,
      deleteflg: e.target.checked,
    };

    onChange && onChange(newData);
  };

  const handlePatternChange = (e) => {
    const newData = {
      ...data,
      patterncd_changed: e.target.value,
    };

    onChange && onChange(newData);
  };

  const handleChangeOrderTypeCodeDebounce = debounce((value) => {
    const newData = {
      ...data,
      order_typecd_changed: value,
    };

    onChange && onChange(newData);

    (async () => {
      setNameLoading(true);
      const nameInfo = await getNameByOrderTypeCode({ order_typecd: value });
      setNameLoading(false);
      if (nameInfo.count > 1 && nameInfo.name.length > 0) {
        setNameDisabled(true);

        if (orderTypeNameRef) {
          orderTypeNameRef.current.value = nameInfo.name;
        }
        onChange &&
          onChange({
            ...newData,
            order_typenm: nameInfo.name,
          });
      } else {
        setNameDisabled(false);
      }
    })();
  }, 500);

  const handleChangeOrderTypeCode = (e) => {
    e.target.value = e.target.value.slice(0, MAX_LENGTH_INPUT_ORDER_TYPE_CODE);

    handleChangeOrderTypeCodeDebounce(e.target.value);
  };

  //handle OrderTypeName
  const handleChangeOrderTypeNameDebounce = debounce((value) => {
    const newData = {
      ...data,
      order_typenm: value,
    };

    onChange && onChange(newData);
  }, 500);

  const handleChangeOrderTypeName = (e) => {
    e.target.value = e.target.value.slice(0, MAX_LENGTH_INPUT_ORDER_TYPE_NAME);

    handleChangeOrderTypeNameDebounce(e.target.value);
  };

  useEffect(() => {
    let hasDuplicatedData = false;
    if (orderTypeError && Array.isArray(orderTypeError)) {
      hasDuplicatedData = orderTypeError.some((errorItem) => {
        return (
          Object.keys(errorItem["error"]).includes("duplicated") &&
          errorItem["data"]["order_typecd"] === data.order_typecd &&
          errorItem["data"]["patterncd"] === data.patterncd
        );
      });
    }
    setIsDuplicatedData(hasDuplicatedData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderTypeError]);

  useEffect(() => {
    setIsDuplicatedData(false);
  }, [data]);

  return (
    <tr id={`row-${data.order_typecd}-${data.patterncd}`}>
      <td valign="middle" align="center">
        <Form.Check
          type="checkbox"
          className="large-size"
          checked={data.deleteflg || false}
          onChange={handleDeleteChange}
        />
      </td>
      <td>
        <div className="position-relative">
          <Form.Control
            size="sm"
            defaultValue={data.order_typecd_changed || data.order_typecd}
            onInput={handleChangeOrderTypeCode}
            isInvalid={
              (data.order_typecd_changed !== undefined &&
                data.order_typecd_changed.length === 0) ||
              isDuplicatedData
            }
          />
          {data.order_typecd_changed !== undefined &&
            data.order_typecd_changed.length === 0 && (
              <Form.Control.Feedback type="invalid">
                {t("ordertype.message.warn_order_typecd_required")}
              </Form.Control.Feedback>
            )}
          {isDuplicatedData && (
            <Form.Control.Feedback type="invalid">
              {t("ordertype.message.warn_data_duplicated")}
            </Form.Control.Feedback>
          )}
        </div>
      </td>
      <td>
        <div className="position-relative">
          {nameLoading && (
            <Spinner
              className="position-absolute"
              style={{ right: "7px", top: "7px" }}
              animation="border"
              size="sm"
              variant="dark"
            />
          )}
          <Form.Control
            size="sm"
            defaultValue={data.order_typenm}
            onInput={handleChangeOrderTypeName}
            isInvalid={data.order_typenm.length === 0}
            disabled={nameDisabled}
            ref={orderTypeNameRef}
          />
          <Form.Control.Feedback type="invalid">
            {t("ordertype.message.warn_order_typenm_required")}
          </Form.Control.Feedback>
        </div>
      </td>
      <td>
        {patternSource && patternSource.length > 0 ? (
          <>
            <Form.Select
              size="sm"
              aria-label=""
              value={data.patterncd_changed || data.patterncd}
              onChange={handlePatternChange}
              isInvalid={
                (data.patterncd_changed !== undefined &&
                  data.patterncd_changed.length === 0) ||
                isDuplicatedData
              }
            >
              {patternSource.map((pattern) => (
                <option
                  key={pattern.patterncd}
                  value={pattern.patterncd}
                >{`${pattern.patterncd}_${pattern.patternnm}`}</option>
              ))}
            </Form.Select>
            {data.patterncd_changed !== undefined &&
              data.patterncd_changed.length === 0 && (
                <Form.Control.Feedback type="invalid">
                  {t("ordertype.message.warn_patterncd_required")}
                </Form.Control.Feedback>
              )}
            {isDuplicatedData && (
              <Form.Control.Feedback type="invalid">
                {t("ordertype.message.warn_data_duplicated")}
              </Form.Control.Feedback>
            )}
          </>
        ) : (
          <span>{`${data.order_typecd}_${data.order_typenm}`}</span>
        )}
      </td>
    </tr>
  );
}

export default OrderTypeRow;
