import { takeEvery, call, put } from "redux-saga/effects";
import * as actions from "../actions";
import * as actionTypes from "../../constants";
import {
  checkOrderClosingTime,
  getPurchaseItems,
  confirmOrder,
} from "../../services/input-order/inputOrderService";

function* doCheckOrderClosingTime() {
  try {
    const response = yield call(checkOrderClosingTime);
    yield put(actions.doCheckOrderClosingTimeSuccess(response.data));
  } catch (error) {
    yield put(actions.throwError(error));
  }
}
function* doGetPurchaseItems(action) {
  try {
    const { data } = action;
    const response = yield call(getPurchaseItems, data);
    yield put(actions.doGetPurchaseItemsSuccess(response.data));
  } catch (error) {
    yield put(actions.throwError(error));
  }
}
function* doConfirmOrder(action) {
  try {
    const { data } = action;
    const response = yield call(confirmOrder, data);
    yield put(actions.doConfirmOrderSuccess(response.data));
  } catch (error) {
    yield put(actions.throwError(error));
  }
}
function* doConfirmOrderReset(action) {
  try {
    yield put(actions.doConfirmOrderResetSuccess());
  } catch (error) {
    yield put(actions.throwError(error));
  }
}
function* doReload(action) {
  try {
    yield put(actions.doReloadSuccess());
  } catch (error) {
    yield put(actions.throwError(error));
  }
}
function* doInputChange(action) {
  try {
    yield put(actions.doInputChangedSuccess(action.data));
  } catch (error) {
    yield put(actions.throwError(error));
  }
}
export default function* watchInputOrder() {
  yield takeEvery(
    actionTypes.INPUT_ORDER_DO_CHECK_ORDER_CLOSING_TIME,
    doCheckOrderClosingTime
  );
  yield takeEvery(
    actionTypes.INPUT_ORDER_DO_GET_PURCHASED_ITEMS_INFO,
    doGetPurchaseItems
  );
  yield takeEvery(actionTypes.INPUT_ORDER_DO_CONFIRM_ORDER, doConfirmOrder);
  yield takeEvery(
    actionTypes.INPUT_ORDER_DO_CONFIRM_ORDER_RESET,
    doConfirmOrderReset
  );
  yield takeEvery(actionTypes.INPUT_ORDER_DO_RELOAD, doReload);
  yield takeEvery(actionTypes.INPUT_ORDER_DO_INPUT_CHANGED, doInputChange);
}
