import { takeEvery, call, put } from "redux-saga/effects";
import * as actions from "../actions";
import * as actionTypes from "../../constants";
import { getBusinessInfo, patchBusinessInfo } from "../../services/notification-master/notificationMasterService";

function* doGetBusinessInfo(action) {
  try {
    const response = yield call(getBusinessInfo, action.data);
    yield put(actions.doGetBusinessInfoSuccess(response));
  } catch (error) {
    yield put(actions.doGetBusinessInfoFailed(error));
    yield put(actions.throwError(error));
  }
}

function* doPatchBusinessInfo(action) {
  try {
    const response = yield call(patchBusinessInfo, action.data);
    yield put(actions.doPatchBusinessInfoSuccess(response));
  } catch (error) {
    yield put(actions.doPatchBusinessInfoFailed(error));
    yield put(actions.throwError(error));
  }
}

export default function* watchNotificationMasterInfo() {
  yield takeEvery(actionTypes.DO_GET_NOTIFICATION_MASTER, doGetBusinessInfo);
  yield takeEvery(actionTypes.DO_PATCH_NOTIFICATION_MASTER, doPatchBusinessInfo);
}
