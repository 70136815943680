import React, { useEffect, useState } from "react";
import {
  Alert,
  Breadcrumb,
  Button,
  Card,
  Col,
  Row,
  Spinner,
} from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { FaPlus } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import {
  getOrderTypeByStoreBusinessSource,
  getOrderTypeByStoreOrderTypeSource,
  updateOrderTypeByStoreData,
} from "../../services/ordertype-bystore/orderTypeByStoreService";
import {
  doGetOrderTypeByStore,
  setOrderTypeByStoreError,
  setOrderTypeByStoreFilter,
  updateItemOrderTypeByStore,
} from "../../stores/actions";
import {
  selectOrderTypeByStoreData,
  selectOrderTypeByStoreError,
  selectOrderTypeByStoreFilter,
  selectOrderTypeByStoreModified,
} from "../../stores/reducers/orderTypeByStoreReducer";
import OrderTypeByStoreFilter from "./components/OrderTypeByStoreFilter";
import OrderTypeByStoreForm from "./components/OrderTypeByStoreForm";
import OrderTypeByStoreTable from "./components/OrderTypeByStoreTable";
import "./index.scss";

function OrderTypeByStore() {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  // state
  const [dataError, setDataError] = useState([]);
  const [updateLoading, setUpdateLoading] = useState(false);
  const [orderTypeSource, setOrderTypeSource] = useState([]);
  const [businessSource, setBusinessSource] = useState([]);
  const [showForm, setShowForm] = useState(false);
  const [showAlert, setShowAlert] = useState({
    status: false,
    message: "",
    key: "",
  });

  // state redux
  const orderTypeByStoreData = useSelector(selectOrderTypeByStoreData);
  const orderTypeByStoreModified = useSelector(selectOrderTypeByStoreModified);
  const filter = useSelector(selectOrderTypeByStoreFilter);
  const orderTypeByStoreError = useSelector(selectOrderTypeByStoreError);

  useEffect(() => {
    dispatch(doGetOrderTypeByStore(filter));
  }, [dispatch, filter]);

  useEffect(() => {
    return () => {
      dispatch(
        setOrderTypeByStoreFilter({
          ordertype_code: undefined,
          ordertype_name: undefined,
          store_code: undefined,
          store_name: undefined,
        })
      );
    };
  }, [dispatch]);

  // get order type source
  useEffect(() => {
    (async () => {
      try {
        const response = await getOrderTypeByStoreOrderTypeSource();
        setOrderTypeSource(response);
      } catch (e) {}
    })();
  }, []);

  // get business source
  useEffect(() => {
    (async () => {
      try {
        const response = await getOrderTypeByStoreBusinessSource();
        setBusinessSource(response);
      } catch (e) {}
    })();
  }, []);

  const handleChangeFilter = (newFilter) => {
    handleCloseAlert();
    dispatch(setOrderTypeByStoreFilter(newFilter));
  };

  const handleRowChange = (newRow) => {
    dispatch(updateItemOrderTypeByStore(newRow));
  };

  const handleCloseAlert = () => {
    setShowAlert({ status: false, message: "", key: "" });
  };

  const handleShowAlert = (message, key) => {
    setShowAlert({ status: true, message, key });
  };

  const handleSubmit = async () => {
    //check if any item with order_typecd = ''
    const itemErrors = [];
    Object.values(orderTypeByStoreModified).forEach((item) => {
      if (item.order_typecd.length === 0) {
        itemErrors.push(item);
      }
    });

    if (itemErrors.length > 0) {
      // show error message
      setDataError(itemErrors);
      handleShowAlert(
        t("ordertype_bystore.message.order_type_required"),
        "danger"
      );
    } else {
      // reset error
      handleCloseAlert();
      setDataError([]);

      if (Object.values(orderTypeByStoreModified).length === 0) {
        handleShowAlert(
          t("ordertype_bystore.message.no_updated_data"),
          "warning"
        );
      } else {
        //compose data
        const submitData = Object.values(orderTypeByStoreModified).map(
          (item) => {
            return {
              tenpocd: item.tenpocd,
              order_typecd: item.order_typecd,
              deleteflg: item.deleteflg,
              order_typecd_changed:
                item.order_typecd_changed !== undefined
                  ? item.order_typecd_changed
                  : undefined,
            };
          }
        );
        // submit modified data to server
        try {
          dispatch(setOrderTypeByStoreError([]));
          setUpdateLoading(true);
          await updateOrderTypeByStoreData(submitData);
          setUpdateLoading(false);
          //dispatch(resetModifiedOrderTypeByStore());
          dispatch(doGetOrderTypeByStore(filter));
          handleShowAlert(
            t("ordertype_bystore.message.update_successfully"),
            "success"
          );
        } catch (e) {
          setUpdateLoading(false);
          if (e.response && e.response.data) {
            handleShowAlert(
              t("ordertype_bystore.message.update_failed"),
              "danger"
            );
            //show error duplicated
            dispatch(setOrderTypeByStoreError(e.response.data));
          }
        }
      }
    }
  };

  // scroll to first error row
  useEffect(() => {
    if (showAlert.status) {
      if (orderTypeByStoreError.length > 0) {
        setTimeout(() => {
          document
            .getElementById(
              "row-" +
                orderTypeByStoreError[0]["data"].tenpocd +
                "-" +
                orderTypeByStoreError[0]["data"].order_typecd
            )
            .scrollIntoView({
              behavior: "auto",
              block: "center",
              inline: "center",
            });
        }, 300);
      } else if (dataError.length > 0) {
        setTimeout(() => {
          document
            .getElementById(
              "row-" + dataError[0].tenpocd + "-" + dataError[0].order_typecd
            )
            .scrollIntoView({
              behavior: "auto",
              block: "center",
              inline: "center",
            });
        }, 300);
      }
    }
  }, [dataError, orderTypeByStoreError, showAlert.status]);

  const handleCloseForm = () => {
    setShowForm(false);
  };

  const handleOpenForm = () => {
    setShowForm(true);
  };

  const handleSubmitFormSuccessfully = (formValue) => {
    handleShowAlert(t("ordertype_bystore.message.add_successfully"), "success");
    dispatch(doGetOrderTypeByStore(filter));
  };

  return (
    <>
      <Row>
        <Col md={12}>
          <Breadcrumb>
            <Breadcrumb.Item active>{t("system.label.home")}</Breadcrumb.Item>
            <Breadcrumb.Item active>
              {t("ordertype_bystore.label.headtitle")}
            </Breadcrumb.Item>
          </Breadcrumb>
          <Card>
            <Card.Header
              as="h2"
              style={{ textAlign: "center", color: "#ef8412" }}
            >
              {t("ordertype_bystore.label.headtitle")}
            </Card.Header>

            <Card.Body>
              <OrderTypeByStoreFilter
                filter={filter}
                onChange={handleChangeFilter}
              />
              <Row className="my-3">
                <Col sm={10}>
                  {showAlert.status && (
                    <Alert
                      key={showAlert.key}
                      variant={showAlert.key}
                      onClose={handleCloseAlert}
                      dismissible
                    >
                      {showAlert.message}
                    </Alert>
                  )}
                </Col>
                <Col sm={2} className="text-end">
                  <Button
                    style={{ minWidth: "150px" }}
                    onClick={handleOpenForm}
                  >
                    <FaPlus />
                    &nbsp;{t("ordertype_bystore.label.sign_up")}
                  </Button>
                </Col>
              </Row>
              <OrderTypeByStoreTable
                data={orderTypeByStoreData}
                orderTypeSource={orderTypeSource}
                onRowChange={handleRowChange}
              />
              <div className="text-center mt-5 mb-3">
                <Button
                  variant="primary"
                  style={{ minWidth: "150px" }}
                  onClick={handleSubmit}
                  disabled={updateLoading}
                >
                  {updateLoading && (
                    <Spinner
                      animation="border"
                      size="sm"
                      variant="light"
                      className="me-1"
                    />
                  )}
                  <span>{t("system.label.update")}</span>
                </Button>
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>

      <OrderTypeByStoreForm
        open={showForm}
        businessSource={businessSource}
        orderTypeSource={orderTypeSource}
        onClose={handleCloseForm}
        onSubmitSuccessfully={handleSubmitFormSuccessfully}
      />
    </>
  );
}

export default OrderTypeByStore;
