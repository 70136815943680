import React, { useEffect, useState } from "react";
import { Modal, Row, Col, Button, Form } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import {
  doGetBusinessType,
  doSaveOrderTypeMaintenanceMaster,
  doGetStoreByBusinessType,
} from "../../../stores/actions";

import DatePicker, { registerLocale } from "react-datepicker";
import ja from "date-fns/locale/ja";
registerLocale("ja", ja);

export default function OrderTypeForm(props) {
  const dispatch = useDispatch();
  const gyotai = useSelector(
    (state) => state.orderTypeMaintenanceMaster.gyotai
  );

  const stores = useSelector(
    (state) => state.orderTypeMaintenanceMaster.stores
  );
  const orderTypeList = useSelector(
    (state) => state.orderTypeMaintenanceMaster.order_type_list
  );
  const errors = useSelector((state) => state.orderTypeMaintenanceMaster.error);

  const [storeSelect, setStoreSelect] = useState("");
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());

  const handleSubmit = (event) => {
    event.preventDefault();
    const data = {
      tenpocd: event.target.tenpocd ? event.target.tenpocd.value : "",
      startdate: event.target.startdate ? event.target.startdate.value : "",
      enddate: event.target.enddate ? event.target.enddate.value : "",
      order_typecd: event.target.order_typecd
        ? event.target.order_typecd.value
        : "",
    };
    dispatch(doSaveOrderTypeMaintenanceMaster(data));
  };

  useEffect(() => {
    dispatch(doGetBusinessType());
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const handleChangeSelect = (event) => {
    let data = {
      code: event.target.value,
    };
    dispatch(doGetStoreByBusinessType(data));
  };

  useEffect(() => {
    if (stores) {
      setStoreSelect(
        <Form.Select name="tenpocd">
          {stores &&
            stores.map((tenpo, index) => (
              <option key={index} value={tenpo.code}>
                {tenpo.code}_{tenpo.meisyo}
              </option>
            ))}
        </Form.Select>
      );
    }
    if (stores && stores.length === 0) {
      setStoreSelect(<Form.Select disabled></Form.Select>);
    }
  }, [stores]);

  return (
    <Modal {...props} className="modal-add">
      <Modal.Header closeButton>
        <Modal.Title></Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={handleSubmit}>
          <Row className="mb-3">
            <Col sm={2}>業態</Col>
            <Col sm={10}>
              <Form.Select onChange={handleChangeSelect}>
                <option>業態を選択</option>
                {gyotai &&
                  gyotai.map((gyotai) => (
                    <option key={gyotai.code} value={gyotai.code}>
                      {gyotai.meisyo}
                    </option>
                  ))}
              </Form.Select>
            </Col>
          </Row>
          <Row className="mb-3">
            <Col sm={2}>店舗</Col>
            <Col sm={10}>
              {storeSelect}
              {errors["tenpocd"] && (
                <Form.Control.Feedback type="invalid">
                  {errors["tenpocd"]}
                </Form.Control.Feedback>
              )}
            </Col>
          </Row>

          <Row className="mb-3">
            <Col sm={2}>適用開始日</Col>
            <Col sm={10}>
              <DatePicker
                dateFormat="yyyy/MM/dd"
                name="startdate"
                selected={startDate}
                onChange={(date) => setStartDate(date)}
                disabledKeyboardNavigation
                selectsStart
                startDate={startDate}
                endDate={endDate}
                className="form-control"
                locale="ja"
                showMonthDropdown
                showYearDropdown
                dropdownMode="select"
              />
              {errors["startdate"] && (
                <Form.Control.Feedback type="invalid">
                  {errors["startdate"]}
                </Form.Control.Feedback>
              )}
            </Col>
          </Row>

          <Row className="mb-3">
            <Col sm={2}>適用終了日</Col>
            <Col sm={10}>
              <DatePicker
                dateFormat="yyyy/MM/dd"
                name="enddate"
                selected={endDate}
                onChange={(date) => setEndDate(date)}
                disabledKeyboardNavigation
                selectsEnd
                startDate={startDate}
                endDate={endDate}
                minDate={startDate}
                className="form-control"
                locale="ja"
                showMonthDropdown
                showYearDropdown
                dropdownMode="select"
              />
              {errors["enddate"] && (
                <Form.Control.Feedback type="invalid">
                  {errors["enddate"]}
                </Form.Control.Feedback>
              )}
            </Col>
          </Row>

          <Row className="mb-3">
            <Col sm={2}>発注タイプ</Col>
            <Col sm={10}>
              <Form.Select name="order_typecd">
                {orderTypeList &&
                  orderTypeList.map((orderType, index) => (
                    <option key={index} value={orderType.order_typecd}>
                      {orderType.order_typecd}_{orderType.order_typenm}
                    </option>
                  ))}
              </Form.Select>
              {errors["order_typecd"] && (
                <Form.Control.Feedback type="invalid">
                  {errors["order_typecd"]}
                </Form.Control.Feedback>
              )}
            </Col>
          </Row>
          <Row>
            <Col sm={12} className="text-center">
              <Button className="btn-submit" type="submit" variant="primary">
                登録
              </Button>
              <Button
                className="btn-cancel"
                variant="secondary"
                onClick={props.onHide}
              >
                戻る
              </Button>
            </Col>
          </Row>
        </Form>
      </Modal.Body>
    </Modal>
  );
}
