import * as actionTypes from "../../constants";

export function doLogin(data) {
  return {
    data,
    type: actionTypes.AUTH_DO_ACCESS_TOKEN,
  };
}

export function doLoginSuccess(data) {
  return {
    data,
    type: actionTypes.AUTH_DO_ACCESS_TOKEN_SUCCESS,
  };
}

export function doLoginFailure(data) {
  return {
    data,
    type: actionTypes.AUTH_DO_ACCESS_TOKEN_FAILURE,
  };
}

export function doLogout() {
  return {
    type: actionTypes.AUTH_DO_LOGOUT,
  };
}

export function doLogoutSuccess(data) {
  return {
    data,
    type: actionTypes.AUTH_DO_LOGOUT_SUCCESS,
  };
}

export const authThrowError = (data) => ({
  type: actionTypes.AUTH_THROW_ERROR,
  data,
});

export function doGetCompanies(data) {
  return {
    data,
    type: actionTypes.AUTH_DO_GET_COMPANIES,
  };
}

export function doGetCompaniesSuccess(data) {
  return {
    data,
    type: actionTypes.AUTH_DO_GET_COMPANIES_SUCCESS,
  };
}
