
// api
export const DO_GET_NOTIFICATION_MASTER = "DO_GET_NOTIFICATION_MASTER";
export const DO_GET_NOTIFICATION_MASTER_SUCCESS = "DO_GET_NOTIFICATION_MASTER_SUCCESS";
export const DO_GET_NOTIFICATION_MASTER_FAILED = "DO_GET_NOTIFICATION_MASTER_FAILED";

export const DO_PATCH_NOTIFICATION_MASTER = "DO_PATCH_NOTIFICATION_MASTER";
export const DO_PATCH_NOTIFICATION_MASTER_SUCCESS = "DO_PATCH_NOTIFICATION_MASTER_SUCCESS";
export const DO_PATCH_NOTIFICATION_MASTER_FAILED = "DO_PATCH_NOTIFICATION_MASTER_FAILED";

const STEAK_DON = "00000010";
const DON_TEI = "00000011";
const FOLKS = "00000012";

export const BUSINESS_TYPES = [
     {
          code: STEAK_DON,
          name: 'ステーキのどん',
          content: ''
     },
     {
          code: DON_TEI,
          name: 'どん亭',
          content: ''
     },
     {
          code: FOLKS,
          name: 'フォルクス',
          content: ''
     },

];