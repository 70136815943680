import React from "react";
import OrderTypeMaintenanceMaster from "../../containers/OrderTypeMaintenanceMaster";
import { Col, Container, Row } from "react-bootstrap";
import { FaBars } from "react-icons/fa";
import { Route, Routes } from "react-router-dom";
import DeliveryPatterns from "../../containers/DeliveryPatterns";
import InputOrder from "../../containers/InputOrder";
import OrderTypeByStore from "../../containers/OrderTypeByStore";
import OrderType from "../../containers/OrderType";
import PurchasedProduct from "../../containers/PurchasedProduct";
import ResultOrderConfirmation from "../../containers/ResultOrderConfirmation";
import OrderHistory from "../../containers/OrderHistory";
import NotificationMaster from "../../containers/NotificationMaster";

const Main = ({ handleToggleSidebar, requireAuth }) => {
  return (
    <main>
      <Container fluid>
        <Row>
          <Col>
            <div
              className="btn-toggle"
              onClick={() => handleToggleSidebar(true)}
            >
              <FaBars />
            </div>
          </Col>
        </Row>
        <Routes>
          <Route
            path="/order-history"
            element={<OrderHistory />}
            onEnter={requireAuth}
          />
          <Route
            path="/purchased-product"
            element={<PurchasedProduct />}
            onEnter={requireAuth}
          />
          <Route path="/" element={<InputOrder />} onEnter={requireAuth} />
          <Route
            path="/confirm-result-order"
            element={<ResultOrderConfirmation />}
            onEnter={requireAuth}
          />
          <Route
            path="/ordertype-by-store"
            element={<OrderTypeByStore />}
            onEnter={requireAuth}
          />
          <Route
            path="/ordertype"
            element={<OrderType />}
            onEnter={requireAuth}
          />
          <Route
            path="/delivery-patterns"
            element={<DeliveryPatterns />}
            onEnter={requireAuth}
          />
          <Route
            path="/order-type-maintenance-master"
            element={<OrderTypeMaintenanceMaster />}
            onEnter={requireAuth}
          />
          <Route
            path="/notification-master"
            element={<NotificationMaster />}
            onEnter={requireAuth}
          />
        </Routes>
      </Container>
    </main>
  );
};

export default Main;
