import * as actionTypes from "../../constants";
import * as HttpStatus from "http-status-codes";
const initialState = {
  loading: false,
  error: null,
};
const appReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.INPUT_ORDER_DO_CHECK_ORDER_CLOSING_TIME:
    case actionTypes.DO_GET_PURCHASED_PRODUCT:
    case actionTypes.INPUT_ORDER_DO_GET_PURCHASED_ITEMS_INFO:
    case actionTypes.INPUT_ORDER_DO_CONFIRM_ORDER:
    case actionTypes.RESULT_ORDER_DO_GET_ORDERED_DATES_INFO:
    case actionTypes.RESULT_ORDER_DO_GET_ORDERED_ITEMS_INFO:
    case actionTypes.RESULT_ORDER_DO_EXPORT_PDF:
    case actionTypes.DO_UPDATE_ORDER_TYPE_MAINTERNANCE_MASTER:
    case actionTypes.DO_GET_ORDER_TYPE_LIST:
    case actionTypes.DO_GET_DELIVERY_PATTERNS:
    case actionTypes.DO_GET_ORDERTYPE:
    case actionTypes.DO_GET_ORDERTYPE_BYSTORE:
    case actionTypes.DO_GET_ORDER_TYPE_MAINTERNANCE_MASTER:
    case actionTypes.DO_GET_ORDER_HISTORY:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.HANDLE_ERROR:
    case actionTypes.THROW_ERROR:
    case actionTypes.INPUT_ORDER_DO_CHECK_ORDER_CLOSING_TIME_SUCCESS:
    case actionTypes.DO_GET_PURCHASED_PRODUCT_SUCCESS:
    case actionTypes.INPUT_ORDER_DO_GET_PURCHASED_ITEMS_INFO_SUCCESS:
    case actionTypes.INPUT_ORDER_DO_CONFIRM_ORDER_SUCCESS:
    case actionTypes.RESULT_ORDER_DO_GET_ORDERED_DATES_INFO_SUCCESS:
    case actionTypes.RESULT_ORDER_DO_GET_ORDERED_ITEMS_INFO_SUCCESS:
    case actionTypes.RESULT_ORDER_DO_EXPORT_PDF_SUCCESS:
    case actionTypes.DO_UPDATE_ORDER_TYPE_MAINTERNANCE_MASTER_SUCCESS:
    case actionTypes.HANDLE_ERROR_UPDATE_ORDER_TYPE:
    case actionTypes.DO_GET_ORDER_TYPE_LIST_SUCCESS:
    case actionTypes.DO_GET_DELIVERY_PATTERNS_SUCCESS:
    case actionTypes.DO_GET_DELIVERY_PATTERNS_FAILED:
    case actionTypes.DO_GET_ORDERTYPE_SUCCESS:
    case actionTypes.DO_GET_ORDERTYPE_FAILED:
    case actionTypes.DO_GET_ORDERTYPE_BYSTORE_SUCCESS:
    case actionTypes.DO_GET_ORDERTYPE_BYSTORE_FAILED:
    case actionTypes.DO_GET_ORDER_TYPE_MAINTERNANCE_MASTER_SUCCESS:
    case actionTypes.DO_GET_ORDER_HISTORY_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case actionTypes.TIMEOUT_ERROR:
      return {
        ...state,
        error: HttpStatus.StatusCodes.REQUEST_TIMEOUT,
      };
    case actionTypes.RESET_ERROR_SUCCESS:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};

export default appReducer;
