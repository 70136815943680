import { useEffect, useRef } from "react";
import { Modal } from "react-bootstrap";

function PreviewImageModal({ code = "", name = "", imageUrl = null, onClose }) {
  const imageRef = useRef(null);

  useEffect(() => {
    var newImg = new Image();
    newImg.onload = function () {
      imageRef.current.src = this.src;
    };
    newImg.src = process.env.REACT_APP_IMAGE_URL + imageUrl;
  }, [imageUrl]);

  function handleClose() {
    onClose?.();
  }

  return (
    <Modal show backdrop="static" centered onHide={handleClose}>
      <Modal.Header closeButton>
        <div className="mb-1">
          <span className="d-inline-block">{code}</span>
          <span className="d-inline-block mx-2">|</span>
          <span className="d-inline-block">{name}</span>
        </div>
      </Modal.Header>
      <Modal.Body>{imageUrl && <img ref={imageRef} alt="" />}</Modal.Body>
    </Modal>
  );
}

export default PreviewImageModal;
