import { Breadcrumb, Row, Col, Card, Button } from "react-bootstrap";
import "./index.scss";
import React, { useEffect, Fragment, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { doGetBusinessInfo, doPatchBusinessInfo } from "../../stores/actions";
import { BUSINESS_TYPES } from "../../constants/notificationMasterConstant";
import TimeoutAlert from "../../components/Alert/Alert"


function NotificationMaster() {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const businessInfoState = useSelector((state) => state.notificationMaster);

  // list order history
  const [businessInfo, setBusinessInfo] = useState(BUSINESS_TYPES);
  // alert
  const [alerts, setAlerts] = useState([]);

  useEffect(() => {
    dispatch(doGetBusinessInfo());
  }, [dispatch]);

  useEffect(() => {
    if (businessInfoState) {
      if (Object.keys(businessInfoState.error).length === 0 && businessInfoState.isUpdateSuccess === false) {
        if (businessInfoState.data && businessInfoState.data.length > 0) {
          setBusinessInfo(businessInfoState.data);
        }
      }
      if (businessInfoState.isUpdateSuccess === true) {
        if (businessInfoState.messages && businessInfoState.messages.data) {
          setAlerts(businessInfoState.messages.data);
        }
      }
    }
  }, [businessInfoState]);

  const handleEditContent = (event) => {
    if (businessInfo && businessInfo.length > 0) {
      let value = event.currentTarget.value;
      const newBusinessInfo = businessInfo.map((obj) => {
        if (obj.code === event.currentTarget.id) {
          return { ...obj, content: value };
        }
        return obj;
      });
      setBusinessInfo(newBusinessInfo);
    }
  };


  const handleSaveBusinessType = () => {
    if (businessInfo && businessInfo.length > 0) {
      dispatch(doPatchBusinessInfo(businessInfo));
    }
  };

  return (
    <Row>
      <Col md={12}>
        <Breadcrumb>
          <Breadcrumb.Item active>{t("system.label.home")}</Breadcrumb.Item>
          <Breadcrumb.Item active>
            {t("system.label.notification_master")}
          </Breadcrumb.Item>
        </Breadcrumb>
        <Card>
          <Card.Header
            as="h2"
            bg="light"
            style={{ textAlign: "center", color: "#ef8412" }}
          >
            {t("system.label.notification_master")}
          </Card.Header>

          <Card.Body>
            {alerts.map((message, index) => {
              return <TimeoutAlert key={index} message={message} deleteAlert={(idx) => { setAlerts(alerts => alerts.splice(idx, 1)); }} index={index} />;
            })}
            {businessInfo && (
              <Fragment>
                <div className="container-fluid">
                  {businessInfo && businessInfo.length > 0 && businessInfo.map((businessType, index) => (
                    <div key={index} className="row justify-content-md-center gx-0 mb-3">
                      <div className="col-md-2 col-sm-3
                    border 
                    border-2 
                    border-color-custom 
                    border-end-0
                    text-center"
                        style={{ backgroundColor: '#ef8412' }}
                      >
                        <div className="text-white fs-6 fw-bold mt-3">
                          <div>
                            {businessType.name}
                          </div>
                          <div>業態</div>
                        </div>
                      </div>
                      <div className="col-md-8 col-sm-9">
                        <textarea onChange={handleEditContent} value={businessType.content} id={businessType.code} className="form-control rounded-0" rows={3} style={{ minWidth: '100%' }}></textarea>
                      </div>
                    </div>
                  ))}
                  {businessInfo && businessInfo.length > 0 && (
                    <div className="text-center">
                      <Button
                        className="btn-add"
                        variant="primary"
                        onClick={handleSaveBusinessType}
                      >
                        {t("system.label.register")}
                      </Button>
                    </div>
                  )}
                </div>
              </Fragment>
            )}
          </Card.Body>
        </Card>
      </Col>
    </Row>
  );
}

export default NotificationMaster;
