import {
  Breadcrumb,
  Row,
  Col,
  Card,
  Table,
  Form,
  Alert,
  Button,
} from "react-bootstrap";
import DatePicker from "react-datepicker";
import { registerLocale } from "react-datepicker";
import ja from "date-fns/locale/ja";
import "./index.scss";
import "react-datepicker/dist/react-datepicker.css";
import Carousel from "../../components/Carousel/Carousel";
import { useTranslation } from "react-i18next";
import React, { useEffect, Fragment, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  doGetOrderedDates,
  doGetOrderedItems,
  doExportPdf,
} from "../../stores/actions";
import StringUtils from "../../utils/StringUtils";
const ResultOrderConfirmation = () => {
  registerLocale("ja", ja);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const resultOrderState = useSelector((state) => state.resultOrder);
  const menuState = useSelector((state) => state.menu);
  const orderInfo = resultOrderState.orderInfo.data;
  const orderDatesInfo = resultOrderState.orderDatesInfo;
  let [foodCategorySelected, setFoodCategorySelected] = useState("");
  let [orderDate, setOrderDate] = useState(null);
  let [orderDateText, setOrderDateText] = useState(null);
  let [includeDates, setIncludeDates] = useState([]);
  const [startDate, setStartDate] = useState(new Date());
  const [isOpen, setIsOpen] = useState(false);
  let [isReset, setIsReset] = useState(false);
  let deliveryDate = "ー";
  let foodCategories = [];
  let purchasedList = [];
  let selected_tab_total_amount = 0;
  useEffect(() => {
    if (menuState.storeSelected) {
      setFoodCategorySelected("");
      setIsReset(true);
      setOrderDate(null);
      dispatch(doGetOrderedDates({ tenpo_cd: menuState.storeSelected }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [menuState.storeSelected]);

  useEffect(() => {
    if (menuState.storeSelected && orderDate) {
      setFoodCategorySelected("");
      setIsReset(true);
      dispatch(
        doGetOrderedItems({
          tenpo_cd: menuState.storeSelected,
          order_date: orderDate,
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderDate]);

  const handleFoodCategoryChanged = (key) => {
    setIsReset(false);
    setFoodCategorySelected(key);
  };

  const handleOrderDateChange = (e) => {
    setIsOpen(!isOpen);
    setStartDate(e);
    for (let idx in orderDatesInfo.dates) {
      if (orderDatesInfo.dates[idx]["order_date"] === StringUtils.yyyymmdd(e)) {
        setOrderDateText(orderDatesInfo.dates[idx]["order_date_text"]);
        setOrderDate(orderDatesInfo.dates[idx]["order_date"]);
      }
    }
  };

  const handleOrderDateClick = (e) => {
    e.preventDefault();
    setIsOpen(!isOpen);
  };

  const handleExportPdf = (e) => {
    dispatch(
      doExportPdf({
        tenpo_cd: menuState.storeSelected,
        order_date: orderDate,
        store_name: menuState.storeNameSelected,
        order_date_text: orderDateText,
      })
    );
  };

  if (!orderDatesInfo.isLoaded && menuState.storeSelected) return <Fragment />;
  if (!orderDate && orderDatesInfo.dates.length > 0) {
    let orderDateList = [];
    for (let idx in orderDatesInfo.dates) {
      orderDateList.push(new Date(orderDatesInfo.dates[idx]["order_date_for_calendar"]));
    }
    setOrderDate(orderDatesInfo.dates[0]["order_date"]);
    setOrderDateText(orderDatesInfo.dates[0]["order_date_text"]);
    setIncludeDates(orderDateList);
    setStartDate(orderDateList[0]);
  }
  if (!foodCategorySelected && orderInfo) {
    const foodCateCdList = Object.keys(orderInfo.purchased_items);
    if (orderInfo.food_categories && foodCateCdList.length > 0) {
      foodCategorySelected = foodCateCdList[0];
    }
  }
  if (foodCategorySelected) {
    deliveryDate =
      orderInfo.food_categories[foodCategorySelected].delivery_date;
    Object.keys(orderInfo.food_categories)
      .sort()
      .map(function (code) {
        if (
          orderInfo.purchased_items[code] &&
          orderInfo.purchased_items[code].length > 0
        ) {
          foodCategories.push(
            <div
              key={code}
              className={code === foodCategorySelected ? "active" : ""}
              onClick={() => handleFoodCategoryChanged(code)}
            >
              <div
                className={
                  "carousel-item-content " +
                  (code === foodCategorySelected ? "active" : "")
                }
              >
                {orderInfo.food_categories[code].name}
              </div>
            </div>
          );
        }
        if (
          foodCategorySelected === code &&
          orderInfo.purchased_items[foodCategorySelected] &&
          orderInfo.purchased_items[foodCategorySelected].length > 0
        ) {
          selected_tab_total_amount = 0;
          orderInfo.purchased_items[foodCategorySelected].map(function (
            item,
            index
          ) {
            selected_tab_total_amount += item.count;
            return purchasedList.push(
              <tr key={item.code}>
                <td>{item.toricode}</td>
                <td>{item.name}</td>
                <td style={{ textAlign: "right" }}>
                  {StringUtils.moneyFormatter(item.unit_price)}
                </td>
                <td style={{ textAlign: "right" }}>{item.order_quantity}</td>
                <td>{item.unit}</td>
                <td style={{ textAlign: "right" }}>
                  {StringUtils.moneyFormatter(item.count)}
                </td>
              </tr>
            );
          });
        }
        return code;
      });
  }
  return (
    <Row>
      <Col md={12}>
        <Breadcrumb>
          <Breadcrumb.Item active>{t("system.label.home")}</Breadcrumb.Item>
          <Breadcrumb.Item active>
            {t("result_order.label.result_order_screen")}
          </Breadcrumb.Item>
        </Breadcrumb>
        <Card>
          <Card.Header
            as="h2"
            bg="light"
            style={{ textAlign: "center", color: "#ef8412" }}
          >
            {t("result_order.label.result_order_screen")}
          </Card.Header>
          <Card.Body>
            {menuState.storeSelected && orderDatesInfo.dates.length === 0 && (
              <Alert variant="danger">
                {t("result_order.message.no_confirmation_result_order")}
              </Alert>
            )}
            {!menuState.storeSelected && (
              <Alert variant="warning">
                {t("system.message.select_store")}
              </Alert>
            )}
            {resultOrderState.orderInfo.error_code && (
              <Alert variant="danger">
                {t("system.label.error") +
                  t(
                    "input_order.message." +
                      resultOrderState.orderInfo.error_code
                  )}
              </Alert>
            )}
            {resultOrderState.orderInfo.error_message && (
              <Alert variant="danger">
                {t("system.label.error") +
                  resultOrderState.orderInfo.error_message}
              </Alert>
            )}
            {resultOrderState.orderInfo.isLoaded &&
              !resultOrderState.orderInfo.error_message &&
              !resultOrderState.orderInfo.error_code && (
                <Fragment>
                  <Table bordered responsive className="custom-row text-center">
                    <thead className="bg-common">
                      <tr className="bg-title-table">
                        <th>{t("result_order.label.orderer")}</th>
                        <th>{t("result_order.label.order_date_and_time")}</th>
                        <th>{t("input_order.label.delivery_date")}</th>
                      </tr>
                    </thead>
                    <tbody className="list-body">
                      <tr>
                        <td width={"33%"} className="pt-20">
                          <Form.Label>{orderInfo.shainnm}</Form.Label>
                        </td>
                        <td width={"33%"}>
                          <Button
                            variant="outline-secondary"
                            onClick={handleOrderDateClick}
                          >
                            {orderDateText}
                          </Button>
                          {isOpen && (
                            <DatePicker
                              locale={ja}
                              selected={startDate}
                              onChange={handleOrderDateChange}
                              includeDates={includeDates}
                              inline
                              showMonthDropdown
                              showYearDropdown
                              dropdownMode="select"
                            />
                          )}
                        </td>
                        <td className="pt-20">
                          <Form.Label>{deliveryDate}</Form.Label>
                        </td>
                      </tr>
                    </tbody>
                  </Table>
                  <div className="category-list">
                    <Carousel show={4} isReset={isReset}>
                      {foodCategories}
                    </Carousel>
                  </div>
                  <div
                    className="table-fixed-header"
                    style={{ maxHeight: "500px" }}
                  >
                    <Table bordered hover className="tbl-result">
                      <thead>
                        <tr className="bg-title-table text-center">
                          <th>{t("input_order.label.purchase_code")}</th>
                          <th>{t("input_order.label.purchase_name")}</th>
                          <th>{t("result_order.label.unit_price")}</th>
                          <th>{t("input_order.label.number_of_orders")}</th>
                          <th>{t("input_order.label.unit")}</th>
                          <th>{t("result_order.label.count")}</th>
                        </tr>
                      </thead>
                      <tbody>{purchasedList}</tbody>
                    </Table>
                  </div>
                  <Row>
                    <Col md={6} className="text-center">
                      <h4 className="text-danger">
                        {t("result_order.label.tab_subtotal_amount").replace(
                          "{0}",
                          StringUtils.moneyFormatter(selected_tab_total_amount)
                        )}
                      </h4>
                    </Col>
                    <Col md={6} className="text-center">
                      <h4 className="text-danger">
                        {t("result_order.label.total_amount").replace(
                          "{0}",
                          StringUtils.moneyFormatter(orderInfo.total_amount)
                        )}
                      </h4>
                    </Col>
                  </Row>
                  <Row>
                    <Col className="text-center">
                      <Button size="lg" onClick={handleExportPdf}>
                        {t("result_order.label.export_pdf")}
                      </Button>
                    </Col>
                  </Row>
                </Fragment>
              )}
          </Card.Body>
        </Card>
      </Col>
    </Row>
  );
};
export default ResultOrderConfirmation;
