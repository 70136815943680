import * as actionTypes from "../../constants";

export function doGetOrderTypeByStore(data) {
  return {
    data,
    type: actionTypes.DO_GET_ORDERTYPE_BYSTORE,
  };
}

export function doGetOrderTypeByStoreSuccess(data) {
  return {
    data,
    type: actionTypes.DO_GET_ORDERTYPE_BYSTORE_SUCCESS,
  };
}

export function doGetOrderTypeByStoreFailed(data) {
  return {
    data,
    type: actionTypes.DO_GET_ORDERTYPE_BYSTORE_FAILED,
  };
}

export function setOrderTypeByStoreFilter(data) {
  return {
    data,
    type: actionTypes.DO_SET_FILTER_ORDERTYPE_BYSTORE,
  };
}

export function updateItemOrderTypeByStore(data) {
  return {
    data,
    type: actionTypes.DO_UPDATE_ITEM_ORDERTYPE_BYSTORE,
  };
}

export function resetModifiedOrderTypeByStore() {
  return {
    type: actionTypes.RESET_MODIFIED_ORDERTYPE_BYSTORE,
  };
}

export function setOrderTypeByStoreError(data) {
  return {
    data,
    type: actionTypes.SET_ERROR_ORDERTYPE_BYSTORE,
  };
}
