// config aggregate date range
export const PERIOD_MONTH = 12; // month range
// 1,2,3,4,5,6,0 means that Mon, Tue, Wed, Thu, Fri, Sat, Sun
export const DATE_START_WEEK = 5; // Friday
// 0,1,2,3,4,5 means that Month, First Week, Second Week, Third Week, Fourth Week, Fifth Week
export const DEFAULT_PATTERN_WEEK = 0;
// api
export const DO_GET_ORDER_HISTORY = "DO_GET_ORDER_HISTORY";
export const DO_GET_ORDER_HISTORY_SUCCESS = "DO_GET_ORDER_HISTORY_SUCCESS";
export const DO_GET_ORDER_HISTORY_FAILED = "DO_GET_ORDER_HISTORY_FAILED";
