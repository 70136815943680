import { takeEvery, call, put } from "redux-saga/effects";
import * as actions from "../actions";
import * as actionTypes from "../../constants";
import { getOrderTypeByStoreData } from "../../services/ordertype-bystore/orderTypeByStoreService";

function* doGetOrderTypeByStoreData(action) {
  try {
    const response = yield call(getOrderTypeByStoreData, action.data);
    yield put(actions.doGetOrderTypeByStoreSuccess(response));
  } catch (error) {
    yield put(actions.doGetOrderTypeByStoreFailed(error));
    yield put(actions.throwError(error));
  }
}

export default function* watchOrderTypeByStore() {
  yield takeEvery(
    actionTypes.DO_GET_ORDERTYPE_BYSTORE,
    doGetOrderTypeByStoreData
  );
}
