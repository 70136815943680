import { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import ReactDOM from "react-dom";
import { useTranslation } from "react-i18next";
import { MdPhotoCamera } from "react-icons/md";
import PreviewImageModal from "./PreviewImageModal";
import UploadImageModal from "./UploadImageModal";
import "./style.scss";

function PurchasedProductImage({
  code = "",
  name = "",
  imageUrl = null,
  showAddButton = false,
}) {
  const { t } = useTranslation();
  const [showPreviewImageModal, setShowPreviewImageModal] = useState(false);
  const [showUploadImageModal, setShowUploadImageModal] = useState(false);
  const [imagePath, setImagePath] = useState({
    filename: imageUrl,
    version: new Date().getTime(),
  });

  useEffect(() => {
    setImagePath({
      ...imagePath,
      filename: imageUrl,
      version: new Date().getTime(),
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [imageUrl]);

  function handleShowPreviewImageModal() {
    setShowPreviewImageModal(true);
  }

  function handleClosePreviewImageModal() {
    setShowPreviewImageModal(false);
  }

  function handleShowUploadImageModal() {
    setShowUploadImageModal(true);
  }

  function handleCloseUploadImageModal() {
    setShowUploadImageModal(false);
  }

  function handleReflect(filename) {
    setImagePath({
      ...imagePath,
      filename: filename,
      version: new Date().getTime(),
    });
  }

  return (
    <>
      <div className="d-flex align-items-center justify-content-center">
        {imagePath.filename && (
          <Button variant="link" onClick={handleShowPreviewImageModal}>
            <MdPhotoCamera size="1.5em" />
          </Button>
        )}

        {showAddButton && (
          <Button size="sm" onClick={handleShowUploadImageModal}>
            {t("purchased_product.label.image_registration")}
          </Button>
        )}
      </div>

      {showPreviewImageModal &&
        ReactDOM.createPortal(
          <PreviewImageModal
            code={code}
            name={name}
            imageUrl={imagePath.filename + "?" + imagePath.version}
            onClose={handleClosePreviewImageModal}
          />,
          document
        )}

      {showUploadImageModal &&
        ReactDOM.createPortal(
          <UploadImageModal
            code={code}
            onClose={handleCloseUploadImageModal}
            onReflect={handleReflect}
          />,
          document
        )}
    </>
  );
}

export default PurchasedProductImage;
