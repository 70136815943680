import { combineReducers } from "redux";
import * as actionTypes from "../../constants";
import { handleLogout } from "../../services/storages";
import appReducer from "./appReducer";
import authReducer from "./authReducer";
import deliveryPatternsReducer from "./deliveryPatternsReducer";
import inputOrderReducer from "./inputOrderReducer";
import menuReducer from "./menuReducer";
import orderTypeByStoreReducer from "./orderTypeByStoreReducer";
import orderTypeReducer from "./orderTypeReducer";
import purchasedProductReducer from "./purchasedProductReducer";
import resultOrderReducer from "./resultOrderReducer";
import orderTypeMaintenanceMasterReducer from "./orderTypeMaintenanceMasterReducer";
import orderHistoryReducer from "./orderHistoryReducer";
import notificationMasterReducer from "./notificationMasterReducer";

const appReducers = combineReducers({
  app: appReducer,
  auth: authReducer,
  inputOrder: inputOrderReducer,
  menu: menuReducer,
  deliveryPatterns: deliveryPatternsReducer,
  purchasedProduct: purchasedProductReducer,
  resultOrder: resultOrderReducer,
  orderTypeMaintenanceMaster: orderTypeMaintenanceMasterReducer,
  orderTypeByStore: orderTypeByStoreReducer,
  orderType: orderTypeReducer,
  orderHistory: orderHistoryReducer,
  notificationMaster: notificationMasterReducer
});

const rootReducers = (state, action) => {
  if (action.type === actionTypes.AUTH_DO_LOGOUT_SUCCESS) {
    handleLogout();
    window.location.reload();
    return appReducers(undefined, action);
  }
  return appReducers(state, action);
};

export default rootReducers;
