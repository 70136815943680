import * as actionTypes from "../../constants";

const initialState = {
  data: null,
  error: {},
  messages: null,
  isUpdateSuccess: false,
};

const notificationMasterReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.DO_GET_NOTIFICATION_MASTER_SUCCESS:
      return {
        ...state,
        error: {},
        isUpdateSuccess: false,
        data: action.data.data
      };
    case actionTypes.DO_PATCH_NOTIFICATION_MASTER_SUCCESS:
      return {
        ...state,
        isUpdateSuccess: true,
        messages: action.data
      };
    case actionTypes.DO_PATCH_NOTIFICATION_MASTER_FAILED:
      return {
        ...state,
        isUpdateSuccess: false,
        error: action.data
      };
    default:
      return state;
  }
};

export default notificationMasterReducer;
