import React from "react";
import { Table } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { selectOrderTypeLoading } from "../../../stores/reducers/orderTypeReducer";
import OrderTypeRow from "./OrderTypeRow";

function OrderTypeTable({ data, patternSource, onRowChange }) {
  const { t } = useTranslation();
  const orderTypeLoading = useSelector(selectOrderTypeLoading);

  const handleRowChange = (newRow) => {
    onRowChange && onRowChange(newRow);
  };

  return (
    <div className="table-fixed-header" style={{ maxHeight: "520px" }}>
      <Table bordered hover>
        <thead>
          <tr className="bg-title-table text-center">
            <th>{t("ordertype.table.thead.delete")}</th>
            <th>{t("ordertype.table.thead.ordertype_code")}</th>
            <th>{t("ordertype.table.thead.ordertype_name")}</th>
            <th>{t("ordertype.table.thead.pattern_name")}</th>
          </tr>
        </thead>
        <tbody>
          {data.length > 0 &&
            data.map((item) => {
              return (
                <OrderTypeRow
                  key={item.order_typecd + "_" + item.patterncd}
                  data={item}
                  patternSource={patternSource} //patternSource
                  onChange={handleRowChange}
                />
              );
            })}
          {!orderTypeLoading && data && data.length === 0 && (
            <tr>
              <td colSpan={6} className="text-center">
                {t("system.data.no_result")}
              </td>
            </tr>
          )}
        </tbody>
      </Table>
    </div>
  );
}

export default OrderTypeTable;
