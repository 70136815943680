import { takeEvery, call, put } from "redux-saga/effects";
import * as actions from "../actions";
import * as actionTypes from "../../constants";
import {
  apiGetOrderTypeMaintenanceMaster,
  apiSaveOrderType,
  apiUpdateOrderType,
  apiGetBusinessType,
  apiGetStoreByBusinessType,
  apiGetOrderTypeList,
  apiOrderTypeImportCsv,
} from "../../services/order-type-maintenance-master/orderTypeMaintenanceMasterService";
function* doGetOrderTypeMaintenanceMaster(data) {
  try {
    const response = yield call(apiGetOrderTypeMaintenanceMaster, data);
    yield put(actions.doGetOrderTypeMaintenanceMasterSuccess(response.data));
  } catch (error) {
    yield put(actions.throwError(error));
  }
}

function* doSaveOrderTypeMaintenanceMaster(data) {
  try {
    const response = yield call(apiSaveOrderType, data);
    yield put(actions.doSaveOrderTypeMaintenanceMasterSuccess(response.data));
  } catch (error) {
    yield put(actions.handleErrorSaveForm(error.response.data));
  }
}

function* doUpdateOrderTypeMaintenanceMaster(data) {
  try {
    const response = yield call(apiUpdateOrderType, data);
    yield put(actions.doUpdateOrderTypeMaintenanceMasterSuccess(response.data));
  } catch (error) {
    yield put(actions.handleErrorUpdateForm(error.response.data));
  }
}
function* doGetBusinessType(data) {
  try {
    const response = yield call(apiGetBusinessType, data);
    yield put(actions.doGetBusinessTypeSuccess(response.data));
  } catch (error) {
    yield put(actions.handleError(error.response.data));
  }
}

function* doGetStoreBusinessType(data) {
  try {
    const response = yield call(apiGetStoreByBusinessType, data);
    yield put(actions.doGetStoreByBusinessTypeSuccess(response.data));
  } catch (error) {
    yield put(actions.handleError(error.response.data));
  }
}

function* doGetOrderTypeList(data) {
  try {
    const response = yield call(apiGetOrderTypeList, data);
    yield put(actions.doGetOrderTypeListSuccess(response.data));
  } catch (error) {
    yield put(actions.handleError(error.response.data));
  }
}

function* doOrderTypeImportCsv(dataForm) {
  try {
    const response = yield call(apiOrderTypeImportCsv, dataForm["data"]);
    yield put(actions.doOrderTypeImportCsvSuccess(response.data));
  } catch (error) {
    yield put(actions.handleError(error.response.data));
  }
}

export default function* watchOrderTypeMaintenanceMaster() {
  yield takeEvery(
    actionTypes.DO_GET_ORDER_TYPE_MAINTERNANCE_MASTER,
    doGetOrderTypeMaintenanceMaster
  );
  yield takeEvery(
    actionTypes.DO_SAVE_ORDER_TYPE_MAINTERNANCE_MASTER,
    doSaveOrderTypeMaintenanceMaster
  );
  yield takeEvery(
    actionTypes.DO_UPDATE_ORDER_TYPE_MAINTERNANCE_MASTER,
    doUpdateOrderTypeMaintenanceMaster
  );
  yield takeEvery(actionTypes.DO_GET_GYOTAI, doGetBusinessType);
  yield takeEvery(
    actionTypes.DO_GET_STORE_BY_BUSINESS_TYPE,
    doGetStoreBusinessType
  );
  yield takeEvery(actionTypes.DO_GET_ORDER_TYPE_LIST, doGetOrderTypeList);
  yield takeEvery(actionTypes.DO_ORDER_TYPE_IMPORT_CSV, doOrderTypeImportCsv);
}
