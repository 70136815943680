import * as actionTypes from "../../constants";

export function doGetBusinessInfo(data) {
  return {
    data,
    type: actionTypes.DO_GET_NOTIFICATION_MASTER,
  };
}

export function doGetBusinessInfoSuccess(data) {
  return {
    data,
    type: actionTypes.DO_GET_NOTIFICATION_MASTER_SUCCESS,
  };
}

export function doGetBusinessInfoFailed(data) {
  return {
    data,
    type: actionTypes.DO_GET_NOTIFICATION_MASTER_FAILED,
  };
}

export function doPatchBusinessInfo(data) {
  return {
    data,
    type: actionTypes.DO_PATCH_NOTIFICATION_MASTER,
  };
}

export function doPatchBusinessInfoSuccess(data) {
  return {
    data,
    type: actionTypes.DO_PATCH_NOTIFICATION_MASTER_SUCCESS,
  };
}

export function doPatchBusinessInfoFailed(data) {
  return {
    data,
    type: actionTypes.DO_PATCH_NOTIFICATION_MASTER_FAILED,
  };
}