import { yupResolver } from "@hookform/resolvers/yup";
import { debounce } from "lodash";
import React, { useState } from "react";
import { Button, Form, Modal, Spinner } from "react-bootstrap";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import * as yup from "yup";
import {
  addOrderTypeData,
  getNameByOrderTypeCode,
} from "../../../services/ordertype/orderTypeService";

const MAX_LENGTH_INPUT_ORDER_TYPE_CODE = 15;
const MAX_LENGTH_INPUT_ORDER_TYPE_NAME = 40;

function OrderTypeForm({
  open = false,
  patternSource,
  onClose,
  onSubmitSuccessfully,
}) {
  const { t } = useTranslation();

  //state
  const [nameLoading, setNameLoading] = useState(false);
  const [nameDisabled, setNameDisabled] = useState(false);

  const schema = yup
    .object({
      order_typecd: yup
        .string()
        .required(t("ordertype.message.warn_order_typecd_required")),
      patterncd: yup
        .string()
        .required(t("ordertype.message.warn_patterncd_required")),
      order_typenm: yup
        .string()
        .required(t("ordertype.message.warn_order_typenm_required")),
    })
    .required();

  const {
    control,
    handleSubmit,
    clearErrors,
    reset,
    setError,
    setValue,
    formState: { errors, isSubmitting },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const onSubmit = async (formValue) => {
    try {
      await addOrderTypeData(formValue);
      onSubmitSuccessfully && onSubmitSuccessfully();
      handleClose();
    } catch (e) {
      if (e.response && e.response.data) {
        if (e.response.data["non_field_errors"]) {
          setError("common", {
            type: "custom",
            message: e.response.data["non_field_errors"],
          });
        } else {
          setError("common", {
            type: "custom",
            message: t("ordertype_bystore.message.add_failed"),
          });
        }
      }
    }
  };

  const handleClose = () => {
    clearErrors();
    reset();
    setNameDisabled(false);
    onClose && onClose();
  };

  const handleChangeOrderTypeCodeDebounce = debounce((value) => {
    (async () => {
      setNameLoading(true);
      const nameInfo = await getNameByOrderTypeCode({ order_typecd: value });
      setNameLoading(false);
      if (nameInfo.name.length > 0) {
        setNameDisabled(true);
        setValue("order_typenm", nameInfo.name);
      } else {
        setNameDisabled(false);
      }
    })();
  }, 500);

  const handleOrderTypeCodeChange = (onChange) => (e) => {
    const value = e.target.value
      .trim()
      .slice(0, MAX_LENGTH_INPUT_ORDER_TYPE_CODE);
    onChange(value);
    handleChangeOrderTypeCodeDebounce(value);
  };

  const handleOrderTypeNameChange = (onChange) => (e) => {
    const value = e.target.value
      .trim()
      .slice(0, MAX_LENGTH_INPUT_ORDER_TYPE_NAME);
    onChange(value);
  };

  return (
    <Modal show={open} onHide={handleClose} backdrop="static" centered>
      <form noValidate onSubmit={handleSubmit(onSubmit)}>
        <Modal.Header closeButton>
          <Modal.Title>
            {t("ordertype.label.form_title_add_new_data")}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group className="mb-3">
            <Form.Label>{t("ordertype.label.ordertype_code")}</Form.Label>
            <Controller
              control={control}
              name="order_typecd"
              render={({ field: { onChange, onBlur, value, name, ref } }) => (
                <Form.Control
                  name={name}
                  value={value || ""}
                  onChange={handleOrderTypeCodeChange(onChange)}
                  onBlur={onBlur}
                  ref={ref}
                  className={errors.order_typecd && "is-invalid"}
                />
              )}
            />

            {errors.order_typecd && (
              <div className="mt-1 text-danger">{`${errors.order_typecd?.message}`}</div>
            )}
          </Form.Group>

          <Form.Group className="mb-3">
            <div className="d-flex">
              <Form.Label>{t("ordertype.label.ordertype_name")}</Form.Label>
              {nameLoading && (
                <Spinner
                  animation="border"
                  size="sm"
                  variant="dark"
                  className="ms-1"
                />
              )}
            </div>
            <Controller
              control={control}
              name="order_typenm"
              render={({ field: { onChange, onBlur, value, name, ref } }) => (
                <Form.Control
                  name={name}
                  value={value || ""}
                  onChange={handleOrderTypeNameChange(onChange)}
                  onBlur={onBlur}
                  ref={ref}
                  className={errors.order_typenm && "is-invalid"}
                  disabled={nameDisabled}
                />
              )}
            />

            {errors.order_typenm && (
              <div className="mt-1 text-danger">{`${errors.order_typenm?.message}`}</div>
            )}
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label>{t("ordertype.label.patterncd")}</Form.Label>
            <Controller
              control={control}
              name="patterncd"
              render={({ field: { onChange, onBlur, value, name, ref } }) => (
                <Form.Select
                  name={name}
                  aria-label=""
                  onChange={onChange}
                  onBlur={onBlur}
                  value={value}
                  ref={ref}
                  className={errors.patterncd && "is-invalid"}
                >
                  <option value="">
                    {t("ordertype.label.select_patterncd")}
                  </option>
                  {patternSource &&
                    patternSource.map((patternItem) => (
                      <option
                        key={patternItem.patterncd}
                        value={patternItem.patterncd}
                      >{`${patternItem.patterncd}_${patternItem.patternnm}`}</option>
                    ))}
                </Form.Select>
              )}
            />
            {errors.patterncd && (
              <div className="mt-1 text-danger">{`${errors.patterncd?.message}`}</div>
            )}
          </Form.Group>
        </Modal.Body>
        <Modal.Footer className="text-center d-flex">
          <div>
            <Button
              type="submit"
              className="me-3"
              style={{ minWidth: "150px" }}
              disabled={isSubmitting}
            >
              {isSubmitting && (
                <Spinner
                  animation="border"
                  size="sm"
                  variant="light"
                  className="me-1"
                />
              )}
              {t("ordertype.label.register")}
            </Button>
            <Button
              variant="secondary"
              style={{ minWidth: "150px" }}
              onClick={handleClose}
            >
              {t("ordertype.label.return")}
            </Button>
          </div>

          {errors.common && (
            <div className="mt-3 fw-bold text-danger">{`${errors.common.message}`}</div>
          )}
        </Modal.Footer>
      </form>
    </Modal>
  );
}

export default OrderTypeForm;
