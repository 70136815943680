import * as actionTypes from "../../constants";
const initialState = {
  closingOrderTime: {
    isLoaded: false,
    isClosingOrderTime: false,
  },
  inputOrderInfo: {
    isLoaded: false,
    error_code: null,
    data: null,
    isChangedInput: false,
  },
  orderInfo: {
    isLoaded: false,
    error_code: null,
    error_message: null,
  },
};
const inputOrderReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.INPUT_ORDER_DO_GET_PURCHASED_ITEMS_INFO:
      return {
        ...state,
        inputOrderInfo: {
          ...state.inputOrderInfo,
          isLoaded: false,
        },
      };
    case actionTypes.INPUT_ORDER_DO_CHECK_ORDER_CLOSING_TIME_SUCCESS:
      return {
        ...state,
        closingOrderTime: {
          isLoaded: true,
          isClosingOrderTime: action.data.result,
        },
        inputOrderInfo: {
          isLoaded: false,
          error_code: null,
          data: null,
          isChangedInput: false,
        },
      };
    case actionTypes.INPUT_ORDER_DO_GET_PURCHASED_ITEMS_INFO_SUCCESS:
      return {
        ...state,
        inputOrderInfo: {
          ...state.inputOrderInfo,
          isLoaded: true,
          error_code: action.data.error_code,
          error_message: action.data.error_message,
          data: action.data.data,
        },
      };
    case actionTypes.INPUT_ORDER_DO_CONFIRM_ORDER_SUCCESS:
      return {
        ...state,
        orderInfo: {
          isLoaded: true,
          error_code: action.data.error_code,
          error_message: action.data.error_message,
        },
      };
    case actionTypes.INPUT_ORDER_DO_CONFIRM_ORDER_RESET_SUCCESS:
      return {
        ...state,
        orderInfo: {
          isLoaded: false,
          error_code: null,
          error_message: null,
        },
      };
    case actionTypes.INPUT_ORDER_DO_RELOAD_SUCCESS:
      return {
        ...state,
        closingOrderTime: {
          isLoaded: false,
          isClosingOrderTime: false,
        },
        inputOrderInfo: {
          isLoaded: false,
          error_code: null,
          data: null,
          isChangedInput: false,
        },
        orderInfo: {
          isLoaded: false,
          error_code: null,
          error_message: null,
        },
      };
    case actionTypes.INPUT_ORDER_DO_INPUT_CHANGED_SUCCESS:
      return {
        ...state,
        inputOrderInfo: {
          ...state.inputOrderInfo,
          isChangedInput: action.data,
        },
      };
    default:
      return state;
  }
};

export default inputOrderReducer;
