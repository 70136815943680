import React, { useEffect, useState } from "react";
import {
  Alert,
  Breadcrumb,
  Button,
  Card,
  Col,
  Row,
  Spinner,
} from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { FaPlus } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import {
  getOrderTypePatternSource,
  updateOrderTypeData,
} from "../../services/ordertype/orderTypeService";
import {
  doGetOrderType,
  setOrderTypeError,
  setOrderTypeFilter,
  updateItemOrderType,
} from "../../stores/actions";
import {
  selectOrderTypeData,
  selectOrderTypeError,
  selectOrderTypeFilter,
  selectOrderTypeModified,
} from "../../stores/reducers/orderTypeReducer";
import OrderTypeFilter from "./components/OrderTypeFilter";
import OrderTypeForm from "./components/OrderTypeForm";
import OrderTypeTable from "./components/OrderTypeTable";
import { isArray } from "lodash";
function OrderType() {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  // state
  const [dataError, setDataError] = useState([]);
  const [updateLoading, setUpdateLoading] = useState(false);
  const [patternSource, setPatternSource] = useState([]);
  const [showForm, setShowForm] = useState(false);
  const [showAlert, setShowAlert] = useState({
    status: false,
    message: "",
    key: "",
  });

  // state redux
  const orderTypeData = useSelector(selectOrderTypeData);
  const orderTypeModified = useSelector(selectOrderTypeModified);
  const filter = useSelector(selectOrderTypeFilter);
  const orderTypeError = useSelector(selectOrderTypeError);

  //get data
  useEffect(() => {
    dispatch(doGetOrderType(filter));
  }, [dispatch, filter]);

  //remove filter
  useEffect(() => {
    return () => {
      dispatch(
        setOrderTypeFilter({
          order_typecd: undefined,
          order_typenm: undefined,
          patterncd: undefined,
          patternnm: undefined,
        })
      );
    };
  }, [dispatch]);

  // get order type source
  useEffect(() => {
    (async () => {
      try {
        const response = await getOrderTypePatternSource();
        setPatternSource(response);
      } catch (e) {}
    })();
  }, []);

  const handleChangeFilter = (newFilter) => {
    handleCloseAlert();
    dispatch(setOrderTypeFilter(newFilter));
  };

  const handleRowChange = (newRow) => {
    dispatch(updateItemOrderType(newRow));
  };

  const handleCloseAlert = () => {
    setShowAlert({ status: false, message: "", key: "" });
  };

  const handleShowAlert = (message, key) => {
    setShowAlert({ status: true, message, key });
  };

  const handleSubmit = async () => {
    const itemErrors = [];
    Object.values(orderTypeModified).forEach((item) => {
      // check required
      if (
        (item.order_typecd_changed !== undefined &&
          item.order_typecd_changed.length === 0) ||
        item.order_typenm.length === 0 ||
        (item.patterncd_changed !== undefined &&
          item.patterncd_changed.length === 0)
      ) {
        itemErrors.push(item);
      }
    });

    if (itemErrors.length > 0) {
      // show error message
      setDataError(itemErrors);
      handleShowAlert(t("ordertype.message.warn_invalid_data"), "danger");
    } else {
      // reset error
      handleCloseAlert();
      setDataError([]);

      if (Object.values(orderTypeModified).length === 0) {
        handleShowAlert(
          t("ordertype_bystore.message.no_updated_data"),
          "warning"
        );
      } else {
        //compose data
        const submitData = Object.values(orderTypeModified).map((item) => {
          return item;
        });
        // submit modified data to server
        try {
          dispatch(setOrderTypeError([]));
          setUpdateLoading(true);
          await updateOrderTypeData(submitData);
          // dispatch(resetModifiedOrderType());
          dispatch(doGetOrderType(filter));
          setUpdateLoading(false);
          handleShowAlert(
            t("ordertype.message.update_successfully"),
            "success"
          );
        } catch (e) {
          setUpdateLoading(false);
          if (e.response && e.response.data) {
            handleShowAlert(t("ordertype.message.update_failed"), "danger");
            if (!isArray(e.response.data))
              handleShowAlert(e.response.data, "danger");

            //show error duplicated
            dispatch(setOrderTypeError(e.response.data));
          }
        }
      }
    }
  };

  // scroll to first error row
  useEffect(() => {
    if (showAlert.status) {
      if (orderTypeError.length > 0) {
        setTimeout(() => {
          document
            .getElementById(
              "row-" +
                orderTypeError[0]["data"].order_typecd +
                "-" +
                orderTypeError[0]["data"].patterncd
            )
            .scrollIntoView({
              behavior: "auto",
              block: "center",
              inline: "center",
            });
        }, 300);
      } else if (dataError.length > 0) {
        setTimeout(() => {
          document
            .getElementById(
              "row-" + dataError[0].order_typecd + "-" + dataError[0].patterncd
            )
            .scrollIntoView({
              behavior: "auto",
              block: "center",
              inline: "center",
            });
        }, 300);
      }
    }
  }, [dataError, orderTypeError, showAlert.status]);

  const handleCloseForm = () => {
    setShowForm(false);
  };

  const handleOpenForm = () => {
    setShowForm(true);
  };

  const handleSubmitFormSuccessfully = () => {
    handleShowAlert(t("ordertype.message.add_successfully"), "success");
    dispatch(doGetOrderType(filter));
  };

  return (
    <>
      <Row>
        <Col md={12}>
          <Breadcrumb>
            <Breadcrumb.Item active>{t("system.label.home")}</Breadcrumb.Item>
            <Breadcrumb.Item active>
              {t("ordertype.label.headtitle")}
            </Breadcrumb.Item>
          </Breadcrumb>
          <Card>
            <Card.Header
              as="h2"
              style={{ textAlign: "center", color: "#ef8412" }}
            >
              {t("ordertype.label.headtitle")}
            </Card.Header>

            <Card.Body>
              <OrderTypeFilter filter={filter} onChange={handleChangeFilter} />

              <Row className="my-3">
                <Col sm={10}>
                  {showAlert.status && (
                    <Alert
                      key={showAlert.key}
                      variant={showAlert.key}
                      onClose={handleCloseAlert}
                      dismissible
                    >
                      {showAlert.message}
                    </Alert>
                  )}
                </Col>
                <Col sm={2} className="text-end">
                  <Button
                    style={{ minWidth: "150px" }}
                    onClick={handleOpenForm}
                  >
                    <FaPlus />
                    &nbsp;{t("ordertype.label.sign_up")}
                  </Button>
                </Col>
              </Row>
              <OrderTypeTable
                data={orderTypeData}
                patternSource={patternSource}
                onRowChange={handleRowChange}
              />
              <div className="text-center mt-5 mb-3">
                <Button
                  variant="primary"
                  style={{ minWidth: "150px" }}
                  onClick={handleSubmit}
                  disabled={updateLoading}
                >
                  {updateLoading && (
                    <Spinner
                      animation="border"
                      size="sm"
                      variant="light"
                      className="me-1"
                    />
                  )}
                  <span>{t("system.label.update")}</span>
                </Button>
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>

      <OrderTypeForm
        open={showForm}
        patternSource={patternSource}
        onClose={handleCloseForm}
        onSubmitSuccessfully={handleSubmitFormSuccessfully}
      />
    </>
  );
}

export default OrderType;
