import * as actionTypes from "../../constants";

export function doGetMenu(data) {
  return {
    data,
    type: actionTypes.DO_GET_MENU,
  };
}

export function doGetMenuSuccess(data) {
  return {
    data,
    type: actionTypes.DO_GET_MENU_SUCCESS,
  };
}

export function doSetJob(data) {
  return {
    data,
    type: actionTypes.DO_SET_JOB,
  };
}

export function doSetJobSuccess(data) {
  return {
    data,
    type: actionTypes.DO_SET_JOB_SUCCESS,
  };
}

export function doChooseStore(data) {
  return {
    data,
    type: actionTypes.DO_CHOOSE_STORE,
  };
}

export function doChooseStoreSuccess(data) {
  return {
    data,
    type: actionTypes.DO_CHOOSE_STORE_SUCCESS,
  };
}
