import { takeEvery, call, put } from "redux-saga/effects";
import * as actions from "../actions";
import * as actionTypes from "../../constants";
import { menu } from "../../services/menu/menuService";
function* doGetMenu(data) {
  try {
    const response = yield call(menu, data);
    yield put(actions.doGetMenuSuccess(response.data));
  } catch (error) {
    yield put(actions.throwError(error));
  }
}

function* doChooseStore(action) {
  try {
    const { data } = action;
    yield put(actions.doChooseStoreSuccess(data));
  } catch (error) {
    yield put(actions.throwError(error));
  }
}

export default function* watchMenu() {
  yield takeEvery(actionTypes.DO_GET_MENU, doGetMenu);
  yield takeEvery(actionTypes.DO_CHOOSE_STORE, doChooseStore);
}
