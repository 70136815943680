import { takeEvery, put } from "redux-saga/effects";
import * as actions from "../actions";
import * as actionTypes from "../../constants";
function* catchError(action) {
  const { data } = action;
  if (data.code === "ECONNABORTED") {
    yield put(actions.doShowTimeoutError());
  }
}
function* resetError() {
  yield put(actions.doResetErrorSuccess());
}
export default function* watchError() {
  yield takeEvery(actionTypes.THROW_ERROR, catchError);
  yield takeEvery(actionTypes.RESET_ERROR, resetError);
}
