import * as actionTypes from "../../constants";

const initialState = {
  data: null,
  status_add: false,
  status_edit: false,
  status_import: false,
  error: {},
  gyotai: [],
  stores: [],
  order_type_list: [],
  csv_errors: [],
  edit_errors: [],
};

const orderTypeMaintenanceMasterReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.DO_GET_ORDER_TYPE_MAINTERNANCE_MASTER_SUCCESS:
      return {
        ...state,
        data: action.data,
        status_add: false,
        status_edit: false,
        status_import: false,
        error: {},
        stores: [],
        csv_errors: [],
        edit_errors: [],
      };
    case actionTypes.DO_SAVE_ORDER_TYPE_MAINTERNANCE_MASTER_SUCCESS:
      return {
        ...state,
        data: action.data,
        status_add: true,
        error: {},
      };
    case actionTypes.DO_UPDATE_ORDER_TYPE_MAINTERNANCE_MASTER_SUCCESS:
      return {
        ...state,
        data: action.data,
        status_edit: true,
        error: {},
      };
    case actionTypes.DO_GET_GYOTAI_SUCCESS:
      return {
        ...state,
        gyotai: action.data,
      };
    case actionTypes.DO_GET_STORE_BY_BUSINESS_TYPE_SUCCESS:
      return {
        ...state,
        stores: action.data,
      };
    case actionTypes.DO_GET_ORDER_TYPE_LIST_SUCCESS:
      return {
        ...state,
        order_type_list: action.data,
      };

    case actionTypes.DO_ORDER_TYPE_IMPORT_CSV_SUCCESS:
      return {
        ...state,
        data: action.data.items,
        csv_errors:
          action.data.csv_errors.length > 0 ? action.data.csv_errors : [],
        status_import: true,
      };
    case actionTypes.HANDLE_ERROR_SAVE_ORDER_TYPE:
      return {
        ...state,
        error: action.data,
      };
    case actionTypes.HANDLE_ERROR_UPDATE_ORDER_TYPE:
      return {
        ...state,
        edit_errors: action.data.errors,
      };
    default:
      return state;
  }
};

export default orderTypeMaintenanceMasterReducer;
