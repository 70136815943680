import apiClient from "../../utils/apiClient";

export const getDeliveryPatternsData = async (params) => {
  const response = await apiClient.get("/api/v1/delivery-patterns", { params });
  return response.data;
};

export const updateDeliveryPatternsData = async (data) => {
  const response = await apiClient.patch("/api/v1/delivery-patterns", data);
  return response;
};

export const addDeliveryPatternsData = async (data) => {
  const response = await apiClient.post("/api/v1/delivery-patterns", data);
  return response.data;
};
