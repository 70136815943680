import apiClient from "../../utils/apiClient";

export const getBusinessInfo = async () => {
  const response = await apiClient.get("/api/v1/business-info");
  return response;
};

export const patchBusinessInfo = async (params) => {
  const response = await apiClient.post("/api/v1/business-info-update", { params });
  return response;
};