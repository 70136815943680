export const RESULT_ORDER_DO_GET_ORDERED_DATES_INFO =
  "RESULT_ORDER_DO_GET_ORDERED_DATES_INFO";
export const RESULT_ORDER_DO_GET_ORDERED_DATES_INFO_SUCCESS =
  "RESULT_ORDER_DO_GET_ORDERED_DATES_INFO_SUCCESS";
export const RESULT_ORDER_DO_GET_ORDERED_ITEMS_INFO =
  "RESULT_ORDER_DO_GET_ORDERED_ITEMS_INFO";
export const RESULT_ORDER_DO_GET_ORDERED_ITEMS_INFO_SUCCESS =
  "RESULT_ORDER_DO_GET_ORDERED_ITEMS_INFO_SUCCESS";
export const RESULT_ORDER_DO_EXPORT_PDF = "RESULT_ORDER_DO_EXPORT_PDF";
export const RESULT_ORDER_DO_EXPORT_PDF_SUCCESS =
  "RESULT_ORDER_DO_EXPORT_PDF_SUCCESS";
