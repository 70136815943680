export const DO_GET_ORDER_TYPE_MAINTERNANCE_MASTER =
  "DO_GET_ORDER_TYPE_MAINTERNANCE_MASTER";
export const DO_GET_ORDER_TYPE_MAINTERNANCE_MASTER_SUCCESS =
  "DO_GET_ORDER_TYPE_MAINTERNANCE_MASTER_SUCCESS";
export const DO_SAVE_ORDER_TYPE_MAINTERNANCE_MASTER =
  "DO_SAVE_ORDER_TYPE_MAINTERNANCE_MASTER";
export const DO_SAVE_ORDER_TYPE_MAINTERNANCE_MASTER_SUCCESS =
  "DO_SAVE_ORDER_TYPE_MAINTERNANCE_MASTER_SUCCESS";
export const DO_UPDATE_ORDER_TYPE_MAINTERNANCE_MASTER =
  "DO_UPDATE_ORDER_TYPE_MAINTERNANCE_MASTER";
export const DO_UPDATE_ORDER_TYPE_MAINTERNANCE_MASTER_SUCCESS =
  "DO_UPDATE_ORDER_TYPE_MAINTERNANCE_MASTER_SUCCESS";
export const DO_GET_GYOTAI = "DO_GET_GYOTAI";
export const DO_GET_GYOTAI_SUCCESS = "DO_GET_GYOTAI_SUCCESS";
export const DO_GET_STORE_BY_BUSINESS_TYPE = "DO_GET_STORE_BY_BUSINESS_TYPE";
export const DO_GET_STORE_BY_BUSINESS_TYPE_SUCCESS =
  "DO_GET_STORE_BY_BUSINESS_TYPE_SUCCESS";
export const DO_GET_ORDER_TYPE_LIST = "DO_GET_ORDER_TYPE_LIST";
export const DO_GET_ORDER_TYPE_LIST_SUCCESS = "DO_GET_ORDER_TYPE_LIST_SUCCESS";
export const HANDLE_ERROR_SAVE_ORDER_TYPE = "HANDLE_ERROR_SAVE_ORDER_TYPE";
export const HANDLE_ERROR_UPDATE_ORDER_TYPE = "HANDLE_ERROR_UPDATE_ORDER_TYPE";
export const DO_ORDER_TYPE_IMPORT_CSV = "DO_ORDER_TYPE_IMPORT_CSV";
export const DO_ORDER_TYPE_IMPORT_CSV_SUCCESS =
  "DO_ORDER_TYPE_IMPORT_CSV_SUCCESS";
