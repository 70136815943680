import { Alert } from "react-bootstrap";
import React, { useEffect } from "react";

// component alert
const TimeoutAlert = ({ message, deleteAlert, index }) => {
     const onClick = () => deleteAlert(index);
     useEffect(() => {
          const timer = setTimeout(onClick, 2000);
          return () => clearTimeout(timer);
     }, []);
     let idx = index;
     return (
          <>
               {Object.entries(message).map(([key, value]) => {
                    return <Alert
                         key={idx++}
                         variant={key}
                         dismissible
                         onClick={onClick}
                    >
                         {value}
                    </Alert>;
               })}
          </>
     );
}

export default TimeoutAlert;
