import * as actionTypes from "../../constants";

export function doGetOrderType(data) {
  return {
    data,
    type: actionTypes.DO_GET_ORDERTYPE,
  };
}

export function doGetOrderTypeSuccess(data) {
  return {
    data,
    type: actionTypes.DO_GET_ORDERTYPE_SUCCESS,
  };
}

export function doGetOrderTypeFailed(data) {
  return {
    data,
    type: actionTypes.DO_GET_ORDERTYPE_FAILED,
  };
}

export function setOrderTypeFilter(data) {
  return {
    data,
    type: actionTypes.DO_SET_FILTER_ORDERTYPE,
  };
}

export function updateItemOrderType(data) {
  return {
    data,
    type: actionTypes.DO_UPDATE_ITEM_ORDERTYPE,
  };
}

export function resetModifiedOrderType() {
  return {
    type: actionTypes.RESET_MODIFIED_ORDERTYPE,
  };
}

export function setOrderTypeError(data) {
  return {
    data,
    type: actionTypes.SET_ERROR_ORDERTYPE,
  };
}
