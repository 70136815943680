import React, { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { AREA_KBN } from "../../../constants";
import { selectOrderTypeByStoreError } from "../../../stores/reducers/orderTypeByStoreReducer";

function OrderTypeByStoreRow({ data, orderTypeSource, onChange }) {
  const { i18n } = useTranslation();
  const { t } = useTranslation();

  // state
  const [isDuplicatedData, setIsDuplicatedData] = useState(false);

  // state redux
  const orderTypeByStoreError = useSelector(selectOrderTypeByStoreError);

  const handleDeleteChange = (e) => {
    const newData = {
      ...data,
      deleteflg: e.target.checked,
    };

    onChange && onChange(newData);
  };

  const handleOrderTypeChange = (e) => {
    const newData = {
      ...data,
      order_typecd_changed: e.target.value,
    };

    onChange && onChange(newData);
  };

  useEffect(() => {
    let hasDuplicatedData = false;
    if (orderTypeByStoreError && Array.isArray(orderTypeByStoreError)) {
      if (orderTypeByStoreError.length > 0) {
        hasDuplicatedData = orderTypeByStoreError.some((errorItem) => {
          return (
            Object.keys(errorItem["error"]).includes("duplicated") &&
            errorItem["data"]["order_typecd"] === data.order_typecd &&
            errorItem["data"]["tenpocd"] === data.tenpocd
          );
        });
      }
    }
    setIsDuplicatedData(hasDuplicatedData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderTypeByStoreError]);

  useEffect(() => {
    setIsDuplicatedData(false);
  }, [data]);

  return (
    <tr id={`row-${data.tenpocd}-${data.order_typecd}`}>
      <td align="center" valign="middle">
        <Form.Check
          type="checkbox"
          className="large-size"
          checked={data.deleteflg || false}
          onChange={handleDeleteChange}
        />
      </td>
      <td valign="middle">{data.gyotai_meisyo}</td>
      <td valign="middle">{AREA_KBN[i18n.language][data.area_kbn]}</td>
      <td valign="middle">{`${data.tenpocd4} ${data.meisyo}`}</td>
      <td>
        {orderTypeSource && orderTypeSource.length > 0 ? (
          <>
            <Form.Select
              size="sm"
              aria-label=""
              value={data.order_typecd_changed || data.order_typecd}
              onChange={handleOrderTypeChange}
              isInvalid={isDuplicatedData}
            >
              {orderTypeSource &&
                orderTypeSource.map((ordertype) => (
                  <option
                    key={ordertype.order_typecd}
                    value={ordertype.order_typecd}
                  >{`${ordertype.order_typecd}_${ordertype.order_typenm}`}</option>
                ))}
            </Form.Select>
            {isDuplicatedData && (
              <Form.Control.Feedback type="invalid">
                {t("ordertype.message.warn_data_duplicated")}
              </Form.Control.Feedback>
            )}
          </>
        ) : (
          <span>{`${data.order_typecd}_${data.order_typenm}`}</span>
        )}
      </td>
    </tr>
  );
}

export default OrderTypeByStoreRow;
