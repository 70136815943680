import { debounce } from "lodash";
import React, { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { selectDeliveryPatternsError } from "../../../stores/reducers/deliveryPatternsReducer";

const MAX_LENGTH_INPUT_NAME = 40;
const MAX_LENGTH_INPUT_REMARK = 40;
const MAX_LENGTH_INPUT_DAY = 3;

function DeliveryPatternsRow({ data, onChange }) {
  const { t } = useTranslation();
  const deliveryPatternsError = useSelector(selectDeliveryPatternsError);

  //state
  const [errorPatterncd, setErrorPatterncd] = useState(undefined);

  // handle change checkbox delete
  const handleDeleteChange = (e) => {
    const newData = {
      ...data,
      deleteflg: e.target.checked,
    };

    onChange && onChange(newData);
  };

  const handleChangeFieldDebounce = debounce((field, value) => {
    const newData = {
      ...data,
    };
    newData[field] = value;

    onChange && onChange(newData);
  }, 500);

  // hanel change name
  const handleChangeName = (e) => {
    e.target.value = e.target.value.slice(0, MAX_LENGTH_INPUT_NAME);

    handleChangeFieldDebounce("patternnm", e.target.value);
  };

  const handleChangeDate = (e, field) => {
    if (e.target.validity.valid) {
      e.target.value = e.target.value.slice(0, MAX_LENGTH_INPUT_DAY);
      handleChangeFieldDebounce(field, e.target.value);
    } else {
      e.target.value = data[field];
    }
  };

  //handle change mon
  const handleChangeMon = (e) => {
    handleChangeDate(e, "mon_delivery_days");
  };

  //handle change tue
  const handleChangeTue = (e) => {
    handleChangeDate(e, "tue_delivery_days");
  };

  //handle change wed
  const handleChangeWed = (e) => {
    handleChangeDate(e, "wed_delivery_days");
  };

  //handle change thu
  const handleChangeThu = (e) => {
    handleChangeDate(e, "thu_delivery_days");
  };

  //handle change fri
  const handleChangeFri = (e) => {
    handleChangeDate(e, "fri_delivery_days");
  };

  //handle change sat
  const handleChangeSat = (e) => {
    handleChangeDate(e, "sat_delivery_days");
  };

  //handle change sun
  const handleChangeSun = (e) => {
    handleChangeDate(e, "sun_delivery_days");
  };

  //handle change remarks
  const handleChangeRemark = (e) => {
    e.target.value = e.target.value.slice(0, MAX_LENGTH_INPUT_REMARK);

    handleChangeFieldDebounce("remarks", e.target.value);
  };

  useEffect(() => {
    const findErrorPatterncd = deliveryPatternsError.find((errorData) => {
      return (
        Object.keys(errorData.error).includes("patterncd") &&
        errorData.data["patterncd"] === data.patterncd
      );
    });
    setErrorPatterncd(findErrorPatterncd);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deliveryPatternsError]);

  useEffect(() => {
    setErrorPatterncd(undefined);
  }, [data]);

  return (
    <tr id={`row-${data.patterncd}`}>
      <td valign="middle" align="center">
        <Form.Check
          type="checkbox"
          className="large-size"
          checked={data.deleteflg || false}
          onChange={handleDeleteChange}
        />
      </td>
      <td valign="middle" align="center">
        <div className={errorPatterncd ? "is-invalid" : ""}>
          {data.patterncd}
        </div>
        {errorPatterncd && (
          <Form.Control.Feedback type="invalid">
            {errorPatterncd["error"]["patterncd"]}
          </Form.Control.Feedback>
        )}
      </td>
      <td>
        <div className="position-relative">
          <Form.Control
            size="sm"
            defaultValue={data.patternnm.trim()}
            onInput={handleChangeName}
            isInvalid={data.patternnm.length === 0}
          />
          <Form.Control.Feedback type="invalid">
            {t("delivery_patterns.message.warn_patternnm_required")}
          </Form.Control.Feedback>
        </div>
      </td>
      <td>
        <div className="position-relative">
          <Form.Control
            type="number"
            pattern="[0-9]*"
            min="0"
            size="sm"
            defaultValue={data.mon_delivery_days}
            onInput={handleChangeMon}
          />
        </div>
      </td>
      <td>
        <div className="position-relative">
          <Form.Control
            type="number"
            pattern="[0-9]*"
            min="0"
            size="sm"
            defaultValue={data.tue_delivery_days}
            onInput={handleChangeTue}
          />
        </div>
      </td>
      <td>
        <div className="position-relative">
          <Form.Control
            type="number"
            pattern="[0-9]*"
            min="0"
            size="sm"
            defaultValue={data.wed_delivery_days}
            onInput={handleChangeWed}
          />
        </div>
      </td>
      <td>
        <div className="position-relative">
          <Form.Control
            type="number"
            pattern="[0-9]*"
            min="0"
            size="sm"
            defaultValue={data.thu_delivery_days}
            onInput={handleChangeThu}
          />
        </div>
      </td>
      <td>
        <div className="position-relative">
          <Form.Control
            type="number"
            pattern="[0-9]*"
            min="0"
            size="sm"
            defaultValue={data.fri_delivery_days}
            onInput={handleChangeFri}
          />
        </div>
      </td>
      <td>
        <div className="position-relative">
          <Form.Control
            type="number"
            pattern="[0-9]*"
            min="0"
            size="sm"
            defaultValue={data.sat_delivery_days}
            onInput={handleChangeSat}
          />
        </div>
      </td>
      <td>
        <div className="position-relative">
          <Form.Control
            type="number"
            pattern="[0-9]*"
            min="0"
            size="sm"
            defaultValue={data.sun_delivery_days}
            onInput={handleChangeSun}
          />
        </div>
      </td>
      <td>
        <div className="position-relative last">
          <Form.Control
            size="sm"
            defaultValue={data.remarks ? data.remarks.trim() : ""}
            onInput={handleChangeRemark}
          />
        </div>
      </td>
    </tr>
  );
}

export default DeliveryPatternsRow;
