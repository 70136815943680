import * as actionTypes from "../../constants";

export function doGetPurchasedProduct(data) {
  return {
    data,
    type: actionTypes.DO_GET_PURCHASED_PRODUCT,
  };
}

export function doGetPurchasedProductSuccess(data) {
  return {
    data,
    type: actionTypes.DO_GET_PURCHASED_PRODUCT_SUCCESS,
  };
}
