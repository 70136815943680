import React, { useState } from "react";
import { Row, Col, Button, Form } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { doGetOrderTypeMaintenanceMaster } from "../../../stores/actions";
import { useTranslation } from "react-i18next";
import { FaSearch } from "react-icons/fa";
import DatePicker, { registerLocale } from "react-datepicker";
import ja from "date-fns/locale/ja";

registerLocale("ja", ja);
export default function OrderTypeSearch(props) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [startDate, setStartDate] = useState("");
  const [endDate, setEnddate] = useState("");

  const handleSearch = (event) => {
    event.preventDefault();
    const data = {
      order_typecd: event.target.order_typecd.value,
      order_typenm: event.target.order_typenm.value,
      code: event.target.code.value,
      meisyo: event.target.meisyo.value,
      startdate: event.target.startdate.value,
      enddate: event.target.enddate.value,
    };
    dispatch(doGetOrderTypeMaintenanceMaster(data));
  };

  return (
    <Form className="formSearch" onSubmit={handleSearch}>
      <Row>
        <Col sm={3}>
          <Form.Label>{t("order_type.search.order_typecd")}</Form.Label>
          <Form.Control name="order_typecd" />
        </Col>
        <Col sm={3}>
          <Form.Label>{t("order_type.search.code")}</Form.Label>
          <Form.Control name="code" />
        </Col>
        <Col sm={3}>
          <Form.Label>{t("order_type.search.startdate")}</Form.Label>
          <DatePicker
            dateFormat="yyyy/MM/dd"
            selected={startDate}
            name="startdate"
            onChange={(date) => setStartDate(date)}
            locale="ja"
            showMonthDropdown
            showYearDropdown
            dropdownMode="select"
            autoComplete="off"
          />
        </Col>
        <Col sm={3}>
          <Form.Label>{t("order_type.search.endate")}</Form.Label>
          <DatePicker
            dateFormat="yyyy/MM/dd"
            selected={endDate}
            name="enddate"
            onChange={(date) => setEnddate(date)}
            locale="ja"
            showMonthDropdown
            showYearDropdown
            dropdownMode="select"
            autoComplete="off"
          />
        </Col>
        <Col sm={6}>
          <Form.Label>{t("order_type.search.order_typenm")}</Form.Label>
          <Form.Control name="order_typenm" />
        </Col>
        <Col sm={6}>
          <Form.Label>{t("order_type.search.meisyo")}</Form.Label>
          <Form.Control name="meisyo" />
        </Col>
        <Col xs="auto">
          <Button type="submit" className="button-search">
            <FaSearch /> {t("order_type.search.button")}
          </Button>
        </Col>
      </Row>
    </Form>
  );
}
