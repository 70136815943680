import apiClient from "../../utils/apiClient";
export const checkOrderClosingTime = () => {
  return apiClient
    .get(`/api/v1/check-order-closing-time`)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
};

export const getPurchaseItems = (tenpo_cd) => {
  const params = { tenpo_cd };
  return apiClient
    .get(`/api/v1/order`, { params })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
};

export const confirmOrder = (data) => {
  return apiClient
    .post(`/api/v1/order`, JSON.stringify(data))
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
};
