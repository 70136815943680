import React from "react";
import { Table } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { selectDeliveryPatternsLoading } from "../../../stores/reducers/deliveryPatternsReducer";
import DeliveryPatternsRow from "./DeliveryPatternsRow";

function DeliveryPatternsTable({ data, onRowChange }) {
  const { t } = useTranslation();
  const isLoading = useSelector(selectDeliveryPatternsLoading);

  const handleRowChange = (newRow) => {
    onRowChange && onRowChange(newRow);
  };

  return (
    <div className="table-fixed-header" style={{ maxHeight: "520px" }}>
      <Table bordered hover>
        <thead>
          <tr className="bg-title-table text-center">
            <th width="5%">{t("delivery_patterns.table.thead.delete")}</th>
            <th width="8%">
              <span
                dangerouslySetInnerHTML={{
                  __html: t("delivery_patterns.table.thead.code"),
                }}
              ></span>
            </th>
            <th width="15%">
              <span
                dangerouslySetInnerHTML={{
                  __html: t("delivery_patterns.table.thead.name"),
                }}
              ></span>
            </th>
            <th>
              <span
                dangerouslySetInnerHTML={{
                  __html: t("delivery_patterns.table.thead.mon"),
                }}
              ></span>
            </th>
            <th>
              <span
                dangerouslySetInnerHTML={{
                  __html: t("delivery_patterns.table.thead.tue"),
                }}
              ></span>
            </th>
            <th>
              <span
                dangerouslySetInnerHTML={{
                  __html: t("delivery_patterns.table.thead.wed"),
                }}
              ></span>
            </th>
            <th>
              <span
                dangerouslySetInnerHTML={{
                  __html: t("delivery_patterns.table.thead.thu"),
                }}
              ></span>
            </th>
            <th>
              <span
                dangerouslySetInnerHTML={{
                  __html: t("delivery_patterns.table.thead.fri"),
                }}
              ></span>
            </th>
            <th>
              <span
                dangerouslySetInnerHTML={{
                  __html: t("delivery_patterns.table.thead.sat"),
                }}
              ></span>
            </th>
            <th>
              <span
                dangerouslySetInnerHTML={{
                  __html: t("delivery_patterns.table.thead.sun"),
                }}
              ></span>
            </th>
            <th width="20%">{t("delivery_patterns.table.thead.remark")}</th>
          </tr>
        </thead>
        <tbody>
          {/* {isLoading && (
            <tr>
              <td colSpan={11} className="text-center">
                <Spinner
                  animation="border"
                  size="lg"
                  variant="dark"
                  className="me-1"
                />
              </td>
            </tr>
          )} */}
          {data.length > 0 &&
            data.map((item) => {
              return (
                <DeliveryPatternsRow
                  key={item.patterncd}
                  data={item}
                  onChange={handleRowChange}
                />
              );
            })}
          {!isLoading && data && data.length === 0 && (
            <tr>
              <td colSpan={11} className="text-center">
                {t("system.data.no_result")}
              </td>
            </tr>
          )}
        </tbody>
      </Table>
    </div>
  );
}

export default DeliveryPatternsTable;
