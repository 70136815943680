import * as actionTypes from "../../constants";

export function doGetOrderedDates(data) {
  return {
    data,
    type: actionTypes.RESULT_ORDER_DO_GET_ORDERED_DATES_INFO,
  };
}

export function doGetOrderedDatesSuccess(data) {
  return {
    data,
    type: actionTypes.RESULT_ORDER_DO_GET_ORDERED_DATES_INFO_SUCCESS,
  };
}

export function doGetOrderedItems(data) {
  return {
    data,
    type: actionTypes.RESULT_ORDER_DO_GET_ORDERED_ITEMS_INFO,
  };
}

export function doGetOrderedItemsSuccess(data) {
  return {
    data,
    type: actionTypes.RESULT_ORDER_DO_GET_ORDERED_ITEMS_INFO_SUCCESS,
  };
}

export function doExportPdf(data) {
  return {
    data,
    type: actionTypes.RESULT_ORDER_DO_EXPORT_PDF,
  };
}

export function doExportPdfSuccess(data) {
  return {
    data,
    type: actionTypes.RESULT_ORDER_DO_EXPORT_PDF_SUCCESS,
  };
}
