import "./index.scss";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Modal, Button, Form, Spinner } from "react-bootstrap";
import { doGetCompanies, doLogin, doSetJob } from "../../stores/actions";
import { useTranslation } from "react-i18next";
import * as constants from "../../constants";

function Login() {
  const dispatch = useDispatch();
  const companyList = useSelector((state) => state.auth.companies.data);
  const error = useSelector((state) => state.auth.error);
  const jobs = useSelector((state) => state.auth.jobs);
  const auth = useSelector((state) => state.auth);
  const menu = useSelector((state) => state.menu);
  const handleClose = () => setShow(false);
  const { t } = useTranslation();
  const [validated, setValidated] = useState(false);
  const [show, setShow] = useState(false);
  const [jobSelected, setJobSelected] = useState(null);
  const [messsage, setMessage] = useState("");
  const [isSubmit, setIsSubmit] = useState(false);
  const [compamySelected, setCompamySelected] = useState(
    localStorage.getItem(constants.COMPAMY_ID)
  );

  //load company
  useEffect(() => {
    dispatch(doGetCompanies());
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  //submit form
  const submitForm = (event) => {
    const form = event.currentTarget;
    event.preventDefault();
    if (form.checkValidity() === false) {
      setValidated(true);
    } else {
      const data = {
        company_code: event.target.company_code.value,
        employee_code: event.target.employee_code.value,
        password: event.target.password.value,
      };
      dispatch(doLogin(data));
      setIsSubmit(true);
    }
  };

  // show popup when login success
  useEffect(() => {
    if (menu.jobSelected === null && auth.access) {
      setShow(true);
      setJobSelected(auth.jobs[0]);
    }
  }, [menu.jobSelected, auth.access, auth.jobs]);

  useEffect(() => {
    if (jobSelected !== null) {
      setJobSelected(jobSelected);
    }
  }, [jobSelected]);

  useEffect(() => {
    if (error.message) {
      setMessage(error.message);
      setIsSubmit(false);
    }
  }, [error]);

  useEffect(() => {
    if (jobs.length === 0 || jobs.length === 1) {
      setShow(false);
      if (jobs.length === 1) {
        setJobSelected(auth.jobs[0]);
        const data = {
          job: auth.jobs[0],
        };
        dispatch(doSetJob(data));
      }
      if (isSubmit === true) {
        const intervalId = setInterval(() => {
          window.location.reload();
        }, 2000 * 1);
        return () => clearInterval(intervalId);
      }
    }
    setIsSubmit(false);
  }, [auth.jobs, dispatch, isSubmit, jobs]);

  const handleChooseJob = () => {
    const data = {
      job: jobSelected,
    };
    dispatch(doSetJob(data));
    window.location.reload();
  };

  const handleChangeUserName = () => {
    setMessage("");
    setIsSubmit(false);
  };

  const handleChangePassword = () => {
    setMessage("");
    setIsSubmit(false);
  };

  const handleChangeCompany = (e) => {
    setMessage("");
    setCompamySelected(e.target.value);
    setIsSubmit(false);
  };

  return (
    <div className="container">
      <div className="card-login">
        <div className="text">
          <h3>{t("login.lable.title")}</h3>
        </div>
        <Form noValidate validated={validated} onSubmit={submitForm}>
          <Form.Group className="mb-3">
            <Form.Label>{t("login.lable.company_code")}</Form.Label>
            <Form.Select
              name="company_code"
              value={compamySelected}
              onChange={(e) => handleChangeCompany(e)}
            >
              {companyList &&
                companyList.map((company, index) => (
                  <option key={index} value={company.kaisyacd}>
                    {company.kaisyaryakunm}
                  </option>
                ))}
            </Form.Select>
          </Form.Group>
          <div className="mb-3">
            <Form.Label>{t("login.lable.employee_code")}</Form.Label>
            <Form.Control
              type="text"
              name="employee_code"
              onChange={handleChangeUserName}
              required
            />
            <Form.Control.Feedback type="invalid">
              {t("login.error.employee_code")}
            </Form.Control.Feedback>
          </div>
          <div className="mb-3">
            <Form.Label>{t("login.lable.password")}</Form.Label>
            <Form.Control
              type="password"
              name="password"
              onChange={handleChangePassword}
              required
            />
            <Form.Control.Feedback type="invalid">
              {t("login.error.password")}
            </Form.Control.Feedback>
          </div>

          {messsage ? (
            <div className="error my-3">
              <span>{messsage}</span>
            </div>
          ) : (
            ""
          )}

          <div className="buttons">
            <Button type="submit">
              {t("login.lable.ok")}
              {isSubmit && (
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                />
              )}
            </Button>
          </div>
        </Form>
      </div>

      <Modal show={show} onHide={handleClose} backdrop="static">
        <Modal.Body>
          <div className="title text-center">
            <h5>
              <div
                dangerouslySetInnerHTML={{
                  __html: t("login.lable.title_confirm"),
                }}
              />
            </h5>
          </div>
          <div className="roles">
            <div className="mb-3">
              {auth.jobs &&
                auth.jobs.length > 0 &&
                auth.jobs.map((job, index) => (
                  <Form.Check
                    key={index}
                    type="radio"
                    name="job"
                    label={`${job.kaisyanm} - ${job.syokumunm} - ${job.syozokunm}`}
                    defaultChecked={index === 0 ? true : false}
                    onClick={(e) => {
                      setJobSelected(job);
                    }}
                  />
                ))}
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer className="text-center buttons-confirm">
          <Button variant="primary" onClick={handleChooseJob}>
            {t("login.lable.accept_confirm")}
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}
export default Login;
