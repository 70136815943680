import React from "react";
import { useDispatch } from "react-redux";
import { FaUpload } from "react-icons/fa";
import { doOrderTypeImportCsv } from "../../../stores/actions";

export default function ImportDataCSV() {
  const dispatch = useDispatch();
  const onFileChange = (event) => {
    const file = event.target.files[0];
    const file_type = file.type;
    if (file_type !== "text/csv") {
      alert("File Type required is csv file type");
      return false;
    }
    const formData = new FormData();
    formData.append("file", file);
    dispatch(doOrderTypeImportCsv(formData));
  };

  return (
    <label className="custom-file-upload">
      <input
        type="file"
        className="btn btn-primary"
        onChange={onFileChange}
        onClick={(e) => (e.target.value = null)}
      />
      <FaUpload /> CSVアップロード
    </label>
  );
}
