import React, { useEffect, useState } from "react";
import { Button, Form, Modal, Spinner } from "react-bootstrap";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import {
  addOrderTypeByStoreData,
  getOrderTypeByStoreStoreSource,
} from "../../../services/ordertype-bystore/orderTypeByStoreService";

function OrderTypeByStoreForm({
  open = false,
  businessSource,
  orderTypeSource,
  onClose,
  onSubmitSuccessfully,
}) {
  const { t } = useTranslation();

  //state
  const [isReadyData, setIsReadyData] = useState(false);
  const [storeLoading, setStoreLoading] = useState(false);
  const [storeList, setStoreList] = useState([]);

  const schema = yup
    .object({
      business_code: yup
        .string()
        .required(
          t("ordertype_bystore.message.warn_required") +
            t("ordertype_bystore.label.business_format")
        ),
      tenpocd: yup
        .string()
        .required(
          t("ordertype_bystore.message.warn_required") +
            t("ordertype_bystore.label.store")
        ),
      order_typecd: yup
        .string()
        .required(
          t("ordertype_bystore.message.warn_required") +
            t("ordertype_bystore.label.order_type")
        ),
    })
    .required();

  const {
    control,
    handleSubmit,
    clearErrors,
    reset,
    resetField,
    setError,
    setValue,
    formState: { errors, isSubmitting },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const onSubmit = async (formValue) => {
    formValue["business_code"] = undefined;

    try {
      await addOrderTypeByStoreData(formValue);
      onSubmitSuccessfully && onSubmitSuccessfully();
      handleClose();
    } catch (e) {
      if (e.response && e.response.data) {
        if (e.response.data["non_field_errors"]) {
          setError("common", {
            type: "custom",
            message: e.response.data["non_field_errors"],
          });
        } else if (e.response.data["tenpocd"]) {
          setError("tenpocd", {
            type: "custom",
            message: e.response.data["tenpocd"],
          });
        } else if (e.response.data["order_typecd"]) {
          setError("order_typecd", {
            type: "custom",
            message: e.response.data["order_typecd"],
          });
        } else {
          setError("common", {
            type: "custom",
            message: t("ordertype_bystore.message.add_failed"),
          });
        }
      }
    }
  };

  useEffect(() => {
    setValue("tenpocd", "");
    setValue("order_typecd", "");
  }, [resetField, isReadyData, setValue]);

  const handleBusinessChange = (value, onChange) => {
    clearErrors();

    if (value.length === 0) {
      setStoreList([]);
      setIsReadyData(false);
    } else {
      (async () => {
        try {
          setStoreLoading(true);
          setIsReadyData(false);
          const response = await getOrderTypeByStoreStoreSource({
            business_code: value,
          });
          setStoreLoading(false);
          setIsReadyData(true);
          setStoreList(response);
        } catch (e) {
          setStoreList([]);
        }
      })();
    }

    onChange(value);
  };

  const handleClose = () => {
    clearErrors();
    reset();
    setIsReadyData(false);
    setStoreList([]);
    onClose && onClose();
  };

  return (
    <Modal show={open} onHide={handleClose} backdrop="static" centered>
      <form noValidate onSubmit={handleSubmit(onSubmit)}>
        <Modal.Header closeButton>
          <Modal.Title>
            {t("ordertype_bystore.label.form_title_add_new_data")}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group className="mb-3">
            <Form.Label>
              {t("ordertype_bystore.label.business_format")}
            </Form.Label>
            <Controller
              control={control}
              name="business_code"
              render={({ field: { onChange, onBlur, value, name, ref } }) => (
                <Form.Select
                  name={name}
                  aria-label=""
                  onChange={(e) =>
                    handleBusinessChange(e.target.value, onChange)
                  }
                  onBlur={onBlur}
                  value={value}
                  ref={ref}
                  className={errors.business_code && "is-invalid"}
                >
                  <option value="">
                    {t("ordertype_bystore.label.select_business_format")}
                  </option>
                  {businessSource &&
                    businessSource.map((businessItem) => (
                      <option
                        key={businessItem.code}
                        value={businessItem.code}
                      >{`${businessItem.meisyo}`}</option>
                    ))}
                </Form.Select>
              )}
            />

            {errors.business_code && (
              <div className="mt-1 text-danger">{`${errors.business_code?.message}`}</div>
            )}
          </Form.Group>

          <Form.Group className="mb-3">
            <div className="d-flex">
              <Form.Label>{t("ordertype_bystore.label.store")}</Form.Label>
              {storeLoading && (
                <Spinner
                  animation="border"
                  size="sm"
                  variant="dark"
                  className="ms-1"
                />
              )}
            </div>
            <Controller
              control={control}
              name="tenpocd"
              render={({ field: { onChange, onBlur, value, name, ref } }) => (
                <Form.Select
                  name={name}
                  aria-label=""
                  onChange={onChange}
                  onBlur={onBlur}
                  value={value}
                  ref={ref}
                  className={errors.tenpocd && "is-invalid"}
                  disabled={!isReadyData || storeLoading}
                >
                  <option value="">
                    {t("ordertype_bystore.label.select_store")}
                  </option>
                  {storeList &&
                    storeList.map((storeItem) => (
                      <option
                        key={storeItem.tenpocd4}
                        value={storeItem.tenpocd4}
                      >{`${storeItem.tenpocd4}_${storeItem.meisyo}`}</option>
                    ))}
                </Form.Select>
              )}
            />

            {errors.tenpocd && (
              <div className="mt-1 text-danger">{`${errors.tenpocd?.message}`}</div>
            )}
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label>{t("ordertype_bystore.label.order_type")}</Form.Label>

            <Controller
              control={control}
              name="order_typecd"
              render={({ field: { onChange, onBlur, value, name, ref } }) => (
                <Form.Select
                  name={name}
                  aria-label=""
                  onChange={onChange}
                  onBlur={onBlur}
                  value={value}
                  ref={ref}
                  className={errors.order_typecd && "is-invalid"}
                  disabled={!isReadyData}
                >
                  <option value="">
                    {t("ordertype_bystore.label.select_order_type")}
                  </option>
                  {orderTypeSource &&
                    orderTypeSource.map((ordertype) => (
                      <option
                        key={ordertype.order_typecd}
                        value={ordertype.order_typecd}
                      >{`${ordertype.order_typecd}_${ordertype.order_typenm}`}</option>
                    ))}
                </Form.Select>
              )}
            />
            {errors.order_typecd && (
              <div className="mt-1 text-danger">{`${errors.order_typecd?.message}`}</div>
            )}
          </Form.Group>
        </Modal.Body>
        <Modal.Footer className="text-center d-flex">
          <div>
            <Button
              type="submit"
              className="me-3"
              style={{ minWidth: "150px" }}
              disabled={isSubmitting}
            >
              {isSubmitting && (
                <Spinner
                  animation="border"
                  size="sm"
                  variant="light"
                  className="me-1"
                />
              )}
              {t("ordertype_bystore.label.register")}
            </Button>
            <Button
              variant="secondary"
              style={{ minWidth: "150px" }}
              onClick={handleClose}
            >
              {t("ordertype_bystore.label.return")}
            </Button>
          </div>

          {errors.common && (
            <div className="mt-3 fw-bold text-danger">{`${errors.common.message}`}</div>
          )}
        </Modal.Footer>
      </form>
    </Modal>
  );
}

export default OrderTypeByStoreForm;
