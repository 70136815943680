import React, { useState } from "react";
import "./App.scss";
import Aside from "../Sidebar/Aside";
import Main from "../Sidebar/Main";
import Navbar from "../Sidebar/Navbar";
import { BrowserRouter as Router } from "react-router-dom";

const Layout = ({ requireAuth, handleLogout }) => {
  const [collapsed, setCollapsed] = useState(false);
  const [image] = useState(false);
  const [toggled, setToggled] = useState(false);

  const handleCollapsedChange = (checked) => {
    setCollapsed(checked);
  };

  const handleToggleSidebar = (value) => {
    setToggled(value);
  };

  return (
    <div className={`app ${toggled ? "toggled" : ""}`}>
      <Router>
        <Navbar />
        <Aside
          image={image}
          collapsed={collapsed}
          toggled={toggled}
          handleToggleSidebar={handleToggleSidebar}
          handleLogout={handleLogout}
        />
        <Main
          handleToggleSidebar={handleToggleSidebar}
          handleCollapsedChange={handleCollapsedChange}
          requireAuth={requireAuth}
        />
      </Router>
    </div>
  );
};

export default Layout;
