import * as actionTypes from "../../constants";

export function doCheckOrderClosingTime(data) {
  return {
    data,
    type: actionTypes.INPUT_ORDER_DO_CHECK_ORDER_CLOSING_TIME,
  };
}

export function doCheckOrderClosingTimeSuccess(data) {
  return {
    data,
    type: actionTypes.INPUT_ORDER_DO_CHECK_ORDER_CLOSING_TIME_SUCCESS,
  };
}

export function doGetPurchaseItems(data) {
  return {
    data,
    type: actionTypes.INPUT_ORDER_DO_GET_PURCHASED_ITEMS_INFO,
  };
}

export function doGetPurchaseItemsSuccess(data) {
  return {
    data,
    type: actionTypes.INPUT_ORDER_DO_GET_PURCHASED_ITEMS_INFO_SUCCESS,
  };
}

export function doConfirmOrder(data) {
  return {
    data,
    type: actionTypes.INPUT_ORDER_DO_CONFIRM_ORDER,
  };
}

export function doConfirmOrderSuccess(data) {
  return {
    data,
    type: actionTypes.INPUT_ORDER_DO_CONFIRM_ORDER_SUCCESS,
  };
}
export function doConfirmOrderReset(data) {
  return {
    data,
    type: actionTypes.INPUT_ORDER_DO_CONFIRM_ORDER_RESET,
  };
}
export function doConfirmOrderResetSuccess(data) {
  return {
    data,
    type: actionTypes.INPUT_ORDER_DO_CONFIRM_ORDER_RESET_SUCCESS,
  };
}
export function doReload(data) {
  return {
    data,
    type: actionTypes.INPUT_ORDER_DO_RELOAD,
  };
}
export function doReloadSuccess(data) {
  return {
    data,
    type: actionTypes.INPUT_ORDER_DO_RELOAD_SUCCESS,
  };
}
export function doInputChanged(data) {
  return {
    data,
    type: actionTypes.INPUT_ORDER_DO_INPUT_CHANGED,
  };
}
export function doInputChangedSuccess(data) {
  return {
    data,
    type: actionTypes.INPUT_ORDER_DO_INPUT_CHANGED_SUCCESS,
  };
}
