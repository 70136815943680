import * as actionTypes from "../../constants";

const initialState = {
  data: null,
  error: {},
};

const orderHistoryReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.DO_GET_ORDER_HISTORY_SUCCESS:
      return {
        ...state,
        data: action.data,
      };
    default:
      return state;
  }
};

export default orderHistoryReducer;
