import * as actionTypes from "../../constants";

export function doGetOrderTypeMaintenanceMaster(data) {
  return {
    data,
    type: actionTypes.DO_GET_ORDER_TYPE_MAINTERNANCE_MASTER,
  };
}

export function doGetOrderTypeMaintenanceMasterSuccess(data) {
  return {
    data,
    type: actionTypes.DO_GET_ORDER_TYPE_MAINTERNANCE_MASTER_SUCCESS,
  };
}

export function doSaveOrderTypeMaintenanceMaster(data) {
  return {
    data,
    type: actionTypes.DO_SAVE_ORDER_TYPE_MAINTERNANCE_MASTER,
  };
}

export function doSaveOrderTypeMaintenanceMasterSuccess(data) {
  return {
    data,
    type: actionTypes.DO_SAVE_ORDER_TYPE_MAINTERNANCE_MASTER_SUCCESS,
  };
}

export function doUpdateOrderTypeMaintenanceMaster(data) {
  return {
    data,
    type: actionTypes.DO_UPDATE_ORDER_TYPE_MAINTERNANCE_MASTER,
  };
}

export function doUpdateOrderTypeMaintenanceMasterSuccess(data) {
  return {
    data,
    type: actionTypes.DO_UPDATE_ORDER_TYPE_MAINTERNANCE_MASTER_SUCCESS,
  };
}

export function doGetBusinessType(data) {
  return {
    data,
    type: actionTypes.DO_GET_GYOTAI,
  };
}

export function doGetBusinessTypeSuccess(data) {
  return {
    data,
    type: actionTypes.DO_GET_GYOTAI_SUCCESS,
  };
}

export function doGetStoreByBusinessType(data) {
  return {
    data,
    type: actionTypes.DO_GET_STORE_BY_BUSINESS_TYPE,
  };
}

export function doGetStoreByBusinessTypeSuccess(data) {
  return {
    data,
    type: actionTypes.DO_GET_STORE_BY_BUSINESS_TYPE_SUCCESS,
  };
}

export function doGetOrderTypeList(data) {
  return {
    data,
    type: actionTypes.DO_GET_ORDER_TYPE_LIST,
  };
}

export function doGetOrderTypeListSuccess(data) {
  return {
    data,
    type: actionTypes.DO_GET_ORDER_TYPE_LIST_SUCCESS,
  };
}

export function handleErrorSaveForm(data) {
  return { data, type: actionTypes.HANDLE_ERROR_SAVE_ORDER_TYPE };
}

export function handleErrorUpdateForm(data) {
  return { data, type: actionTypes.HANDLE_ERROR_UPDATE_ORDER_TYPE };
}

export function doOrderTypeImportCsv(data) {
  return {
    data,
    type: actionTypes.DO_ORDER_TYPE_IMPORT_CSV,
  };
}

export function doOrderTypeImportCsvSuccess(data) {
  return {
    data,
    type: actionTypes.DO_ORDER_TYPE_IMPORT_CSV_SUCCESS,
  };
}
