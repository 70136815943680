import {
  Breadcrumb,
  Row,
  Col,
  Card,
  Table,
  Form,
  Button,
  Alert,
  Modal,
} from "react-bootstrap";
import "./index.scss";

import Carousel from "../../components/Carousel/Carousel";
import { useTranslation } from "react-i18next";
import React, { useEffect, Fragment, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import PurchasedProductImage from "../../components/PurchaseProductImage";
import {
  doCheckOrderClosingTime,
  doGetPurchaseItems,
  doConfirmOrder,
  doConfirmOrderReset,
  doReload,
  doInputChanged,
} from "../../stores/actions";
const InputOrder = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const inputOrderState = useSelector((state) => state.inputOrder);
  const menuState = useSelector((state) => state.menu);
  const shaincd = useSelector((state) => state.auth.user.shaincd);
  const kaisyacd = useSelector((state) => state.auth.user.kaisyacd);
  const inputOrderInfo = inputOrderState.inputOrderInfo.data;
  const orderInfo = inputOrderState.orderInfo;
  let [foodCategorySelected, setFoodCategorySelected] = useState("");
  let [inputOrderList, setInputOrderList] = useState({});
  let [limitQuantityPurchaseList, setLimitQuantityPurchaseList] = useState({});
  let [showLimitQuantityWarning, setShowLimitQuantityWarning] = useState(false);
  let [orderLimitNum, setOrderLimitNum] = useState(0);
  let [isReset, setIsReset] = useState(false);
  const [purchaseLimitWarningList, setPurchaseLimitWarningList] = useState([]);
  let deliveryDate = "ー";
  let foodCategories = [];
  let purchasedList = [];
  let info = [];
  const purchaseLimitNumber = 99;

  useEffect(() => {
    dispatch(doCheckOrderClosingTime());
    return () => {
      dispatch(doReload());
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  // Get input order information if not closing time
  useEffect(() => {
    if (
      inputOrderState.closingOrderTime.isLoaded &&
      !inputOrderState.closingOrderTime.isClosingOrderTime &&
      menuState.storeSelected
    ) {
      setFoodCategorySelected("");
      setIsReset(true);
      setInputOrderList({});
      setPurchaseLimitWarningList([]);
      setOrderLimitNum(0);
      dispatch(doGetPurchaseItems(menuState.storeSelected));
      dispatch(doInputChanged(false));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputOrderState.closingOrderTime.isLoaded, menuState.storeSelected]);

  useEffect(() => {
    if (inputOrderState.inputOrderInfo.isLoaded) {
      if (inputOrderInfo && Object.keys(inputOrderList).length === 0) {
        let newInputOrderList = {};
        Object.keys(inputOrderInfo.food_categories).map(function (code) {
          if (inputOrderInfo.purchased_items[code]) {
            inputOrderInfo.purchased_items[code].map(function (item, index) {
              if (item.order_quantity > 0)
                newInputOrderList[code + "_" + item.code] = item.order_quantity;
              return item;
            });
          }
          return code;
        });
        setInputOrderList(newInputOrderList);
      }
      let newLimitQuantityPurchaseList = {};
      if (inputOrderInfo) {
        Object.keys(inputOrderInfo.food_categories).map(function (code) {
          if (inputOrderInfo.purchased_items[code]) {
            inputOrderInfo.purchased_items[code].map(function (item, index) {
              if (item.limit_quantity) {
                newLimitQuantityPurchaseList[item.toricode] =
                  item.limit_quantity;
              }
              return item;
            });
          }
          return code;
        });
        setLimitQuantityPurchaseList(newLimitQuantityPurchaseList);
      }
    }
  }, [inputOrderState.inputOrderInfo.isLoaded]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleFoodCategoryChanged = (key) => {
    setIsReset(false);
    setFoodCategorySelected(key);
  };

  const handleReloadPurchased = () => {
    dispatch(doGetPurchaseItems(menuState.storeSelected));
  };

  const handleChangeInputOrder = (e) => {
    e.preventDefault();
    e.stopPropagation();
    const value = e.target.value;
    const toricode = e.target.attributes["toricode"].value;
    // purchaseLimit check
    if(value > purchaseLimitNumber){
      // update to purchaseLimitNumber
      e.target.value = purchaseLimitNumber;
      value = purchaseLimitNumber;
    } else if(value < 0) {
      // update to Zero
      e.target.value = 0;
      value = 0;
    }
    // Alert check
    let limit_quantity = limitQuantityPurchaseList[toricode];
    if (
      limit_quantity &&
      limit_quantity < value &&
      !purchaseLimitWarningList.includes(toricode)
    ) {
      setShowLimitQuantityWarning(true);
      purchaseLimitWarningList.push(toricode);
      setPurchaseLimitWarningList(purchaseLimitWarningList);
      setOrderLimitNum(limit_quantity);
    }
    const purchased_cd = e.target.attributes["code"].value;
    const key = foodCategorySelected + "_" + purchased_cd;
    let newInputOrderList = Object.assign({}, inputOrderList);
    delete newInputOrderList[key];
    if (/^-{0,1}\d*\.{0,1}\d+$/.test(value)) {
      newInputOrderList[key] = value;
    }
    setInputOrderList(newInputOrderList);
    dispatch(doInputChanged(true));
  };
  const handleRegister = () => {
    let requestData = {};
    requestData["tenpo_cd"] = menuState.storeSelected;
    requestData["shain_cd"] = shaincd;
    requestData["kaisya_cd"] = kaisyacd;
    const purchase_items = [];
    for (let foodCategoryItem in inputOrderInfo.food_categories) {
      if (inputOrderInfo.purchased_items[foodCategoryItem]) {
        let purchase_item = {};
        let items = [];
        inputOrderInfo.purchased_items[foodCategoryItem].map(function (
          item,
          index
        ) {
          if (
            inputOrderList[foodCategoryItem + "_" + item.code] &&
            inputOrderList[foodCategoryItem + "_" + item.code] > 0
          )
            return items.push({
              siirehin_cd: item.toricode,
              order_quantity: inputOrderList[foodCategoryItem + "_" + item.code]
                ? inputOrderList[foodCategoryItem + "_" + item.code]
                : 0,
            });
          return item;
        });
        purchase_item["pattern_cd"] = foodCategoryItem.split("_")[1];
        purchase_item["bunrui_cd"] = foodCategoryItem.split("_")[0];
        purchase_item["nouhin_date"] = "";
        if (
          inputOrderInfo.food_categories[foodCategoryItem]["delivery_date"] !==
          "ー"
        ) {
          const re = new RegExp(/(\d{4})年(\d{2})月(\d{2})日/);
          const dateObj =
            inputOrderInfo.food_categories[foodCategoryItem][
              "delivery_date"
            ].match(re);
          if (dateObj && dateObj.length >= 4) {
            delete dateObj[0];
            purchase_item["nouhin_date"] = dateObj.join("");
          }
        }
        purchase_item["items"] = items;
        purchase_items.push(purchase_item);
      }
    }
    requestData["purchase_items"] = purchase_items;
    dispatch(doConfirmOrder(requestData));
    dispatch(doInputChanged(false));
  };
  const handleGoBack = () => {
    setFoodCategorySelected("");
    setIsReset(true);
    dispatch(doConfirmOrderReset());
  };
  const handleClose = () => {
    setShowLimitQuantityWarning(false);
  };

  if (
    !inputOrderState.closingOrderTime.isLoaded ||
    (!inputOrderState.closingOrderTime.isClosingOrderTime &&
      menuState.storeSelected &&
      !inputOrderState.inputOrderInfo.isLoaded)
  )
    return <Fragment />;
  if (!foodCategorySelected && inputOrderInfo) {
    const foodCateCdList = Object.keys(inputOrderInfo.food_categories);
    if (foodCateCdList.length > 0) {
      foodCategorySelected = foodCateCdList[0];
    }
  }
  if (foodCategorySelected) {
    info.push(
      <div className="category-list">
          <Carousel show={2} isReset={isReset}>
            <div className="carousel-item-content active" style={{width: "10%"}}>
              {inputOrderInfo?.gyotai?.meisyo} <br/>
              お知らせ
            </div>
            <div  style={{width: "70%", backgroundColor: "white"}}>
              
              <textarea value={inputOrderInfo?.m_info?.content} className="form-control rounded-0" rows={3} style={{ minWidth: '100%' }}></textarea>
            </div>
          </Carousel>
      </div>
    );

    deliveryDate =
      inputOrderInfo.food_categories[foodCategorySelected].delivery_date;
    Object.keys(inputOrderInfo.food_categories).map(function (code) {
      if (
        inputOrderInfo.purchased_items[code] &&
        inputOrderInfo.food_categories[code].delivery_date !== "ー"
      ) {
        foodCategories.push(
          <div
            key={code}
            className={code === foodCategorySelected ? "active" : ""}
            onClick={() => handleFoodCategoryChanged(code)}
          >
            <div
              className={
                "carousel-item-content " +
                (code === foodCategorySelected ? "active" : "")
              }
            >
              {inputOrderInfo.food_categories[code].name}
            </div>
          </div>
        );
      }
      if (
        foodCategorySelected === code &&
        inputOrderInfo.purchased_items[foodCategorySelected] &&
        inputOrderInfo.purchased_items[foodCategorySelected].length > 0
      ) {
        inputOrderInfo.purchased_items[foodCategorySelected].map(function (
          item,
          index
        ) {
          return purchasedList.push(
            <tr key={item.code}>
              <td className="pt-20">{item.toricode}</td>
              <td className="pt-20">{item.name}</td>
              <td className="pt-20">
                <PurchasedProductImage
                  code={item.toricode}
                  name={item.name}
                  imageUrl={item.url_img}
                  showAddButton={false}
                />
              </td>
              <td style={{ textAlign: "right" }} className="pt-20">
                {item.shelves}
              </td>
              <td width={"150px"}>
                <Form.Control
                  key={foodCategorySelected + "_" + item.code}
                  type="number"
                  name="input_order"
                  min="0"
                  max="99"
                  code={item.code}
                  toricode={item.toricode}
                  onChange={handleChangeInputOrder}
                  value={
                    inputOrderList[foodCategorySelected + "_" + item.code]
                      ? inputOrderList[foodCategorySelected + "_" + item.code]
                      : ""
                  }
                />
              </td>
              <td className="pt-20">{item.unit}</td>
              <td className="pt-20">{item.quantity}</td>
            </tr>
          );
        });
      }
      return code;
    });
  }
  return (
    <Row>
      <Col md={12}>
        <Breadcrumb>
          <Breadcrumb.Item active>{t("system.label.home")}</Breadcrumb.Item>
          <Breadcrumb.Item active>
            {t("input_order.label.input_order_screen")}
          </Breadcrumb.Item>
        </Breadcrumb>
        <Card>
          <Card.Header
            as="h2"
            bg="light"
            style={{ textAlign: "center", color: "#ef8412" }}
          >
            {t("input_order.label.input_order_screen")}
          </Card.Header>
          <Card.Body>
            {inputOrderState.closingOrderTime.isClosingOrderTime && (
              <Alert variant="warning">
                {t("input_order.message.out_of_order_time")}
              </Alert>
            )}
            {!inputOrderState.closingOrderTime.isClosingOrderTime &&
              !menuState.storeSelected && (
                <Alert variant="warning">
                  {t("system.message.select_store")}
                </Alert>
              )}
            {inputOrderState.inputOrderInfo.error_code && (
              <Alert variant="danger">
                {t("system.label.error") +
                  t(
                    "input_order.message." +
                      inputOrderState.inputOrderInfo.error_code
                  )}
              </Alert>
            )}
            {inputOrderState.inputOrderInfo.error_message && (
              <Alert variant="danger">
                {t("system.label.error") +
                  inputOrderState.inputOrderInfo.error_message}
              </Alert>
            )}
            {orderInfo.error_message && (
              <Fragment>
                <Alert variant="danger">
                  {t("system.label.error") + orderInfo.error_message}
                </Alert>
                <Row>
                  <Col className="text-center">
                    <Button size="lg" onClick={handleGoBack}>
                      {t("input_order.label.return")}
                    </Button>
                  </Col>
                </Row>
              </Fragment>
            )}
            {orderInfo.isLoaded &&
              !orderInfo.error_code &&
              !orderInfo.error_message && (
                <Fragment>
                  <Alert variant="success">
                    {t("input_order.message.confirm_order_success")}
                  </Alert>
                  <Row>
                    <Col className="text-center">
                      <Button size="lg" onClick={handleGoBack}>
                        {t("input_order.label.return")}
                      </Button>
                    </Col>
                  </Row>
                </Fragment>
              )}

            {!inputOrderState.closingOrderTime.isClosingOrderTime &&
              inputOrderState.inputOrderInfo.isLoaded &&
              !orderInfo.isLoaded &&
              !inputOrderState.inputOrderInfo.error_message &&
              !inputOrderState.inputOrderInfo.error_code && (
                <Fragment>
                  <Table bordered responsive className="custom-row text-center">
                    <thead className="bg-common">
                      <tr className="bg-title-table">
                        <th>{t("input_order.label.order_date")}</th>
                        <th>{t("input_order.label.delivery_date")}</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td width={"50%"}>
                          <Form.Label>{inputOrderInfo.order_date}</Form.Label>
                        </td>
                        <td>
                          <Form.Label>{deliveryDate}</Form.Label>
                        </td>
                      </tr>
                    </tbody>
                  </Table>
                  {info}
                  <div className="category-list">
                    <Carousel show={4} isReset={isReset}>
                      {foodCategories}
                    </Carousel>
                  </div>
                  <div
                    className="table-fixed-header"
                    style={{ maxHeight: "500px" }}
                  >
                    <Table bordered hover className="tbl-result">
                      <thead>
                        <tr className="bg-title-table text-center">
                          <th>{t("input_order.label.purchase_code")}</th>
                          <th>{t("input_order.label.purchase_name")}</th>
                          <th></th>
                          <th>{t("input_order.label.number_of_shelves")}</th>
                          <th width={"150px"}>
                            {t("input_order.label.number_of_orders")}
                          </th>
                          <th>{t("input_order.label.unit")}</th>
                          <th>{t("input_order.label.quantity")}</th>
                        </tr>
                      </thead>
                      <tbody>{purchasedList}</tbody>
                    </Table>
                  </div>
                  <Row>
                    <Col className="text-center">
                      <Button
                        size="lg"
                        className="mr-10"
                        onClick={handleRegister}
                      >
                        {t("input_order.label.confirmation")}
                      </Button>
                      <Button size="lg" onClick={handleReloadPurchased}>
                        {t("input_order.label.re-reflecting_number_of_shelves")}
                      </Button>
                    </Col>
                  </Row>
                  <strong className="text-center">{t("input_order.label.notification_text")}</strong>
                </Fragment>
              )}
          </Card.Body>
        </Card>
        <Modal
          show={showLimitQuantityWarning}
          onHide={handleClose}
          backdrop="static"
          centered
          dialogClassName="limit-quantity-warning-modal"
        >
          <Modal.Body>
            {t("input_order.message.exceed_limit_quantity").replace(
              "{0}",
              orderLimitNum
            )}
          </Modal.Body>
          <Modal.Footer className="text-center d-flex">
            <Button
              variant="secondary"
              style={{ minWidth: "150px" }}
              onClick={handleClose}
            >
              {t("system.label.confirmation")}
            </Button>
          </Modal.Footer>
        </Modal>
      </Col>
    </Row>
  );
};
export default InputOrder;
