import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { doGetMenu } from "../../stores/actions";
import { FaUser } from "react-icons/fa";
import "antd/dist/antd.css";
import { doChooseStore } from "../../stores/actions";
import { TreeSelect } from "antd";
import "./navbar.scss";
import { useLocation } from "react-router-dom";
import { Button, Modal } from "react-bootstrap";

function Navbar() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const authState = useSelector((state) => state.auth);
  const menuState = useSelector((state) => state.menu);
  const inputOrderState = useSelector((state) => state.inputOrder);
  const [user, setUser] = useState({});
  const [stores, setStores] = useState(null);
  const [selectedStore, setSelectedStore] = useState(null);
  const [storeDefault, setStoreDefault] = useState(undefined);
  let [showConfirmChangeStore, setShowConfirmChangeStore] = useState(false);
  const location = useLocation();
  const allowPathsToDisplayStoreSelector = [
    "/",
    "/confirm-result-order",
    "/order-history",
  ];

  useEffect(() => {
    if (menuState.data) {
      setStores(menuState.data);
    }
  }, [menuState]);

  //get menu
  useEffect(() => {
    if (menuState.jobSelected) {
      const dataRequest = {
        level: menuState.jobSelected.level,
        shaincd: authState.user.shaincd,
        syozokucd: menuState.jobSelected.syozokucd,
        kaisyacd: menuState.jobSelected.kaisyacd,
      };
      dispatch(doGetMenu(dataRequest));
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  //set user default
  useEffect(() => {
    if (authState.user) {
      setUser(authState.user);
    }
  }, [authState]);

  //set store default
  useEffect(() => {
    setStoreDefault(menuState.storeSelected);
    if (menuState.data && menuState.data.length === 1) {
      setStoreDefault(menuState.data[0]);
    }
  }, [menuState]);

  //change store action
  const onChangeStore = (store, label) => {
    const data = {
      store: store,
      storeName: label.length > 0 ? label[0] : "",
    };
    if (
      location.pathname == "/" &&
      inputOrderState.inputOrderInfo.isChangedInput
    ) {
      setShowConfirmChangeStore(true);
      setSelectedStore(data);
    } else {
      setStoreDefault(store);
      dispatch(doChooseStore(data));
    }
  };

  const handleOK = () => {
    setShowConfirmChangeStore(false);
    if (selectedStore) {
      setStoreDefault(selectedStore.store);
      dispatch(doChooseStore(selectedStore));
      setSelectedStore(null);
    }
  };

  const handleClose = () => {
    setShowConfirmChangeStore(false);
    setSelectedStore(null);
  };

  return (
    <>
      <div className="navbar">
        <div className="d-flex w-100">
          <div className="flex-grow-1 d-flex align-items-center justify-content-center">
            {allowPathsToDisplayStoreSelector.includes(location.pathname) && (
              <div className="d-flex align-items-center">
                <div className="title">{t("system.label.store_selection")}</div>
                {stores ? (
                  <TreeSelect
                    style={{ width: "280px" }}
                    value={storeDefault}
                    dropdownStyle={{ maxHeight: 500, overflow: "auto" }}
                    treeData={stores}
                    placeholder={t("system.message.select_store")}
                    onChange={onChangeStore}
                  />
                ) : (
                  ""
                )}
              </div>
            )}
          </div>
          <div className="user">
            <FaUser /> {user.shainnm}
          </div>
        </div>
      </div>
      <Modal
        show={showConfirmChangeStore}
        onHide={handleClose}
        backdrop="static"
        centered
      >
        <Modal.Body>{t("system.message.confirm_change_store")}</Modal.Body>
        <Modal.Footer className="text-center d-flex">
          <Button
            variant="secondary"
            style={{ minWidth: "150px" }}
            onClick={handleOK}
          >
            {t("system.label.OK")}
          </Button>
          <Button
            variant="secondary"
            style={{ minWidth: "150px" }}
            onClick={handleClose}
          >
            {t("system.label.cancel")}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default Navbar;
