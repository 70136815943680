import ApiClient from "./apiClient";
import { getAccessToken } from "../../services/storages";

const preRequest = (requestConfig) => {
  return requestConfig;
};

/* eslint import/no-anonymous-default-export: [2, {"allowArrowFunction": true}] */
export default () => {
  const token = getAccessToken();

  const apiDefaultConfig = {
    baseURL: process.env.REACT_APP_API_BASE_URL,
    timeout: process.env.REACT_APP_TIME_OUT,
    headers: {
      ...(token ? { Authorization: `Token ${token}` } : {}),
    },
  };
  return new ApiClient(apiDefaultConfig, preRequest);
};
