import React from "react";
import { Form } from "react-bootstrap";
import { useSelector } from "react-redux";
export default function OrderTypeSelect(props) {
  const orderTypeList = useSelector(
    (state) => state.orderTypeMaintenanceMaster.order_type_list
  );
  return (
    <>
      <Form.Select
        name="order_typecd"
        value={props.defaultValue}
        onChange={(e) => {
          let requestData = {
            ...props.data,
            [props.column.field]: e.target.value,
          };
          let rowsClone = [...props.tableManager.rowsApi.rows];
          let updatedRowIndex = rowsClone.findIndex(
            (r) => r.id === props.data.id
          );
          rowsClone[updatedRowIndex] = requestData;
          props.setRowsData(rowsClone);
        }}
      >
        {orderTypeList &&
          orderTypeList.map((orderType, index) => (
            <option
              key={index}
              value={`${orderType.order_typecd}`}
            >
              {orderType.order_typecd}_{orderType.order_typenm}
            </option>
          ))}
      </Form.Select>
    </>
  );
}
