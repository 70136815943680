import CryptoJS from "crypto-js";

export default class EncryptUtils {
  static encrypt(plainText) {
    let encryptedString = CryptoJS.AES.encrypt(
      plainText,
      process.env.REACT_APP_ENCRYPT_KEY
    ).toString();

    return encryptedString;
  }

  static decrypt(encryptedString) {
    let bytes = CryptoJS.AES.decrypt(
      encryptedString,
      process.env.REACT_APP_ENCRYPT_KEY
    );

    let plainText = bytes.toString(CryptoJS.enc.Utf8);

    return plainText;
  }
}
