export default class StringUtils {
  static moneyFormatter(string) {
    return String(string).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,") + "円";
  }
  static yyyymmdd(dateIn) {
    var yyyy = dateIn.getFullYear();
    var mm = dateIn.getMonth() + 1; // getMonth() is zero-based
    var dd = dateIn.getDate();
    return String(10000 * yyyy + 100 * mm + dd); // Leading zeros for mm and dd
  }
  static convertDate(value) {
    let date = value.trim();
    let length = date.length;

    let year;
    let month;
    let day;

    if (length === 7) {
      year = date.substring(0, 4);
      month = `0${date.substring(4, 5)}`;
      day = date.substring(5, 7);
    }
    if (length === 8) {
      year = date.substring(0, 4);
      month = date.substring(4, 6);
      day = date.substring(6, 8);
    }
    return `${year}/${month}/${day}`;
  }

  static areaKbnValue = (value) => {
    switch (value.trim()) {
      case "1":
        return "関東";
      case "2":
        return "関西";
      case "3":
        return "中国";
      case "4":
        return "九州";
      default:
        return "";
    }
  };
  /**
   *
   * @param {Number} num
   * @returns String two digit string
   */
  static padTo2Digits = (num) => {
    return num.toString().padStart(2, "0");
  };
  /**
   *
   * @param {Date} date
   * @returns String yyyymmdd
   */
  static formatDate = (date) => {
    return [
      date.getFullYear(),
      this.padTo2Digits(date.getMonth() + 1),
      this.padTo2Digits(date.getDate()),
    ].join("");
  };
}
