import { useRef, useState } from "react";
import { Alert, Button, Modal, Spinner } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { FaUpload } from "react-icons/fa";
import { apiPurchasedProductUploadImage } from "../../services/purchased-product/purchasedProductService";

function UploadImageModal({ code, onClose, onReflect }) {
  const { t } = useTranslation();
  const [isSubmiting, setIsSubmiting] = useState(false);

  // ref
  const inputFileRef = useRef(null);
  const fileNameRef = useRef(null);

  // state
  const [showAlert, setShowAlert] = useState({
    status: false,
    message: "",
    key: "",
  });

  const handleCloseAlert = () => {
    setShowAlert({ status: false, message: "", key: "" });
  };

  const handleShowAlert = (message, key) => {
    setShowAlert({ status: true, message, key });
  };

  function handleClose() {
    onClose?.();
  }

  function handleSelectFile() {
    if (inputFileRef.current && fileNameRef.current) {
      inputFileRef.current.value = "";
      fileNameRef.current.innerHTML = "";
      inputFileRef.current.click();
    }
  }

  function handleFileChange() {
    if (inputFileRef.current && fileNameRef.current) {
      setShowAlert({ status: false, message: "", key: "" });

      // validate filetype
      if (inputFileRef.current.files) {
        const file = inputFileRef.current.files[0];
        if (file) {
          var rFilter = /^(image\/jpeg|image\/jpg)$/i;
          if (!rFilter.test(file.type)) {
            handleShowAlert(
              t("purchased_product.label.warn_upload_file_format"),
              "danger"
            );

            inputFileRef.current.value = "";
            fileNameRef.current.innerHTML = "";
            return;
          }

          fileNameRef.current.innerHTML = file.name;
        }
      }
    }
  }

  function handleReflect() {
    const bodyFormData = new FormData();

    if (inputFileRef.current && inputFileRef.current.files) {
      const file = inputFileRef.current.files[0];

      if (file) {
        bodyFormData.append("img_url", file, file.name);
        bodyFormData.append("code", code);

        (async () => {
          try {
            setIsSubmiting(true);
            const response = await apiPurchasedProductUploadImage(bodyFormData);
            onReflect?.(response.data.filename);

            handleClose();
          } catch (e) {
            setIsSubmiting(false);
            handleShowAlert(
              t("purchased_product.label.upload_image_unsuccessfully"),
              "danger"
            );
          }
        })();
      }
    }
  }

  return (
    <Modal show backdrop="static" centered onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title className="h6">
          {t("purchased_product.label.image_upload")}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {showAlert.status && (
          <Alert
            key={showAlert.key}
            variant={showAlert.key}
            onClose={handleCloseAlert}
            dismissible
          >
            {showAlert.message}
          </Alert>
        )}
        <div className="mb-0">
          <Button variant="secondary" size="sm" onClick={handleSelectFile}>
            <FaUpload />
            &nbsp;
            <span>{t("purchased_product.label.add_file")}</span>
          </Button>

          <input
            ref={inputFileRef}
            type="file"
            className="file-img-url"
            style={{ display: "none" }}
            onChange={handleFileChange}
          />
          <span className="d-inline-block ms-3" ref={fileNameRef}></span>
        </div>
      </Modal.Body>
      <Modal.Footer className="text-center">
        <Button onClick={handleReflect} disabled={isSubmiting}>
          {t("purchased_product.label.send")}
          {isSubmiting && (
            <Spinner
              as="span"
              animation="border"
              size="sm"
              role="status"
              aria-hidden="true"
            />
          )}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default UploadImageModal;
