import { useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { FaSearch } from "react-icons/fa";

const MAX_LENGTH_INPUT_ORDER_TYPE_CODE = 15;
const MAX_LENGTH_INPUT_ORDER_TYPE_NAME = 40;
const MAX_LENGTH_INPUT_STORE_CODE = 4;
const MAX_LENGTH_INPUT_STORE_NAME = 40;

function OrderTypeByStoreFilter({ filter, onChange }) {
  const { t } = useTranslation();

  // state
  const [searchOrderTypeCode, setSearchOrderTypeCode] = useState(
    filter.ordertype_code || ""
  );
  const [searchOrderTypeName, setSearchOrderTypeName] = useState(
    filter.ordertype_name || ""
  );
  const [searchStoreCode, setSearchStoreCode] = useState(
    filter.store_code || ""
  );
  const [searchStoreName, setSearchStoreName] = useState(
    filter.store_name || ""
  );

  const handleChangeSearchOrderTypeCode = (e) => {
    setSearchOrderTypeCode(
      e.target.value.trim().slice(0, MAX_LENGTH_INPUT_ORDER_TYPE_CODE)
    );
  };

  const handleChangeSearchOrderTypeName = (e) => {
    setSearchOrderTypeName(
      e.target.value.trim().slice(0, MAX_LENGTH_INPUT_ORDER_TYPE_NAME)
    );
  };

  const handleChangeSearchStoreCode = (e) => {
    setSearchStoreCode(
      e.target.validity.valid
        ? e.target.value.trim().slice(0, MAX_LENGTH_INPUT_STORE_CODE)
        : searchStoreCode
    );
  };

  const handleChangeSearchStoreName = (e) => {
    setSearchStoreName(
      e.target.value.trim().slice(0, MAX_LENGTH_INPUT_STORE_NAME)
    );
  };

  const handleChangeFilter = (e) => {
    e.preventDefault();

    if (!onChange) return;

    const newFilter = {
      ordertype_code: searchOrderTypeCode || undefined,
      ordertype_name: searchOrderTypeName || undefined,
      store_code: searchStoreCode || undefined,
      store_name: searchStoreName || undefined,
    };

    onChange(newFilter);
  };

  return (
    <Form
      className="formInline"
      style={{ maxWidth: "1024px", margin: "0 auto" }}
    >
      <Row className="mb-3">
        <Col sm={5}>
          <Form.Label>
            {t("ordertype_bystore.search.ordertype_code")}
          </Form.Label>
          <Form.Control
            value={searchOrderTypeCode}
            onChange={handleChangeSearchOrderTypeCode}
          />
        </Col>

        <Col sm={5}>
          <Form.Label>{t("ordertype_bystore.search.store_code")}</Form.Label>
          <Form.Control
            pattern="[0-9]*"
            value={searchStoreCode}
            onChange={handleChangeSearchStoreCode}
          />
        </Col>

        <Col sm={2}>
          <Button type="submit" onClick={handleChangeFilter}>
            <FaSearch />
            &nbsp;{t("system.label.search")}
          </Button>
        </Col>
      </Row>

      <Row>
        <Col sm={5}>
          <Form.Label>
            {t("ordertype_bystore.search.ordertype_name")}
          </Form.Label>
          <Form.Control
            value={searchOrderTypeName}
            onChange={handleChangeSearchOrderTypeName}
          />
        </Col>

        <Col sm={5}>
          <Form.Label>{t("ordertype_bystore.search.store_name")}</Form.Label>
          <Form.Control
            value={searchStoreName}
            onChange={handleChangeSearchStoreName}
          />
        </Col>

        <Col sm={2}>&nbsp;</Col>
      </Row>
    </Form>
  );
}

export default OrderTypeByStoreFilter;
