import { takeEvery, call, put } from "redux-saga/effects";
import * as actions from "../actions";
import * as actionTypes from "../../constants";
import { apiPurchasedProduct } from "../../services/purchased-product/purchasedProductService";
function* doGetPurchasedProduct(data) {
  try {
    const response = yield call(apiPurchasedProduct, data);
    yield put(actions.doGetPurchasedProductSuccess(response.data));
  } catch (error) {
    yield put(actions.throwError(error));
  }
}

export default function* watchPurchasedProduct() {
  yield takeEvery(actionTypes.DO_GET_PURCHASED_PRODUCT, doGetPurchasedProduct);
}
