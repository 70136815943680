import apiClient from "../../utils/apiClient";

export const getOrderDates = (params) => {
  return apiClient
    .get(`/api/v1/order-date`, { params })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
};

export const getOrderedItems = (params) => {
  return apiClient
    .get(`/api/v1/result-order`, { params })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
};

export const exportPdf = (params) => {
  let requestParams = `tenpo_cd=${params["tenpo_cd"]}`;
  requestParams += `&order_date=${params["order_date"]}`;
  requestParams += `&tenpo_nm=${params["store_name"]}`;
  requestParams += `&order_date_text=${params["order_date_text"]}`;
  return apiClient
    .get(`/api/v1/export-order?${requestParams}`, { responseType: "blob" })
    .then((response) => {
      const regExpFilename = /filename="(?<filename>.*)"/;
      const filename =
        regExpFilename.exec(response.headers["content-disposition"])?.groups
          ?.filename ?? null;
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", filename);
      document.body.appendChild(link);
      link.click();
      return response;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
};
