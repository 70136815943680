import apiClient from "../../utils/apiClient";

export const menu = (dataRequest) => {
  return apiClient
    .post("/api/v1/menu", dataRequest["data"])
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
};
