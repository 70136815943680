import * as actionTypes from "../../constants";

const initialState = {
  loading: false,
  data: [],
  rootData: {},
  modified: {},
  filter: {},
  error: [],
};

const orderTypeByStoreReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.DO_GET_ORDERTYPE_BYSTORE:
      return {
        ...state,
        loading: true,
        data: [],
        rootData: {},
        modified: {},
        error: [],
      };
    case actionTypes.DO_GET_ORDERTYPE_BYSTORE_SUCCESS:
      const rootData = action.data.reduce((dataMap, item) => {
        dataMap[item.tenpocd + "_" + item.order_typecd] = item;
        return dataMap;
      }, {});
      return {
        ...state,
        loading: false,
        data: action.data,
        rootData: rootData,
        error: [],
      };
    case actionTypes.DO_GET_ORDERTYPE_BYSTORE_FAILED:
      return {
        ...state,
        loading: false,
        error: action.data,
      };
    case actionTypes.DO_SET_FILTER_ORDERTYPE_BYSTORE:
      return {
        ...state,
        filter: action.data,
      };
    case actionTypes.SET_ERROR_ORDERTYPE_BYSTORE:
      return {
        ...state,
        error: action.data,
      };
    case actionTypes.DO_UPDATE_ITEM_ORDERTYPE_BYSTORE:
      const newData = state.data.map((item) => {
        const primaryKey = item.tenpocd + "_" + item.order_typecd;

        if (
          item.tenpocd === action.data.tenpocd &&
          item.order_typecd === action.data.order_typecd
        ) {
          const modifiedItem = state.modified[primaryKey];
          const rootItem = state.rootData[primaryKey];
          if (modifiedItem) {
            const actionData = {
              ...action.data,
              order_typecd:
                action.data.order_typecd_changed !== undefined
                  ? action.data.order_typecd_changed
                  : action.data.order_typecd,
            };
            if (
              actionData.deleteflg === rootItem.deleteflg &&
              actionData.order_typecd === rootItem.order_typecd
            ) {
              delete state.modified[primaryKey];
            } else {
              state.modified[primaryKey] = action.data;
            }
          } else {
            state.modified[primaryKey] = action.data;
          }

          return action.data;
        }

        return item;
      });

      return {
        ...state,
        data: newData,
      };
    case actionTypes.RESET_MODIFIED_ORDERTYPE_BYSTORE:
      const resetRootData = state.data.reduce((dataMap, item) => {
        dataMap[item.tenpocd + "_" + item.order_typecd] = item;
        return dataMap;
      }, {});
      return {
        ...state,
        rootData: resetRootData,
        modified: {},
      };
    default:
      return state;
  }
};

//selector
export const selectOrderTypeByStoreLoading = (state) =>
  state.orderTypeByStore.loading;
export const selectOrderTypeByStoreData = (state) =>
  state.orderTypeByStore.data;
export const selectOrderTypeByStoreModified = (state) =>
  state.orderTypeByStore.modified;
export const selectOrderTypeByStoreFilter = (state) =>
  state.orderTypeByStore.filter;
export const selectOrderTypeByStoreError = (state) =>
  state.orderTypeByStore.error;

export default orderTypeByStoreReducer;
