import React from "react";
import { Table } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { selectOrderTypeByStoreLoading } from "../../../stores/reducers/orderTypeByStoreReducer";
import OrderTypeByStoreRow from "./OrderTypeByStoreRow";

function OrderTypeByStoreTable({ data, orderTypeSource, onRowChange }) {
  const { t } = useTranslation();
  const orderTypeByStoreLoading = useSelector(selectOrderTypeByStoreLoading);

  const handleRowChange = (newRow) => {
    onRowChange && onRowChange(newRow);
  };

  return (
    <div className="table-fixed-header" style={{ maxHeight: "500px" }}>
      <Table bordered hover>
        <thead>
          <tr className="bg-title-table text-center">
            <th>{t("ordertype_bystore.table.thead.delete")}</th>
            <th>{t("ordertype_bystore.table.thead.business_format")}</th>
            <th>{t("ordertype_bystore.table.thead.area")}</th>
            <th>{t("ordertype_bystore.table.thead.store")}</th>
            <th>{t("ordertype_bystore.table.thead.order_type")}</th>
          </tr>
        </thead>
        <tbody>
          {data.length > 0 &&
            data.map((item) => {
              return (
                <OrderTypeByStoreRow
                  key={item.tenpocd4 + "_" + item.order_typecd}
                  data={item}
                  orderTypeSource={orderTypeSource} //orderTypeSource
                  onChange={handleRowChange}
                />
              );
            })}
          {!orderTypeByStoreLoading && data && data.length === 0 && (
            <tr>
              <td colSpan={6} className="text-center">
                {t("system.data.no_result")}
              </td>
            </tr>
          )}
        </tbody>
      </Table>
    </div>
  );
}

export default OrderTypeByStoreTable;
