import { useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { FaSearch } from "react-icons/fa";

const MAX_LENGTH_INPUT_CODE = 4;
const MAX_LENGTH_INPUT_NAME = 40;

function DeliveryPatternsFilter({ filter, onChange }) {
  const { t } = useTranslation();

  // state
  const [searchCode, setSearchCode] = useState(filter.code || "");
  const [searchName, setSearchName] = useState(filter.name || "");

  const handleChangeSearchCode = (e) => {
    setSearchCode(e.target.value.trim().slice(0, MAX_LENGTH_INPUT_CODE));
  };

  const handleChangeSearchName = (e) => {
    setSearchName(e.target.value.trim().slice(0, MAX_LENGTH_INPUT_NAME));
  };

  const handleChangeFilter = (e) => {
    e.preventDefault();

    if (!onChange) return;

    const newFilter = {
      code: searchCode || "",
      name: searchName || "",
    };

    onChange(newFilter);
  };

  return (
    <Form
      className="formInline"
      style={{ maxWidth: "1024px", margin: "0 auto" }}
    >
      <Row className="mb-3">
        <Col sm={5}>
          <Form.Label>{t("delivery_patterns.search.code")}</Form.Label>
          <Form.Control value={searchCode} onChange={handleChangeSearchCode} />
        </Col>

        <Col sm={5}>
          <Form.Label>{t("delivery_patterns.search.name")}</Form.Label>
          <Form.Control
            pattern="[0-9]*"
            value={searchName}
            onChange={handleChangeSearchName}
          />
        </Col>

        <Col sm={2}>
          <Button type="submit" onClick={handleChangeFilter}>
            <FaSearch />
            &nbsp;{t("system.label.search")}
          </Button>
        </Col>
      </Row>
    </Form>
  );
}

export default DeliveryPatternsFilter;
