import { all } from "redux-saga/effects";
import watchAuth from "./authSaga";
import watchDeliveryPatterns from "./deliveryPatternsSaga";
import watchError from "./errorSaga";
import watchInputOrder from "./inputOrderSaga";
import watchMenu from "./menuSaga";
import watchOrderTypeByStore from "./orderTypeByStoreSaga";
import watchOrderType from "./orderTypeSaga";
import watchPurchasedProduct from "./purchasedProductSaga";
import watchResultOrder from "./resultOrderSaga";
import watchOrderTypeMaintenanceMaster from "./orderTypeMaintenanceMasterSaga";
import watchOrderHistory from "./orderHistorySaga";
import watchNotificationMasterInfo from "./notificationMasterSaga";

export default function* rootSaga() {
  yield all([
    watchAuth(),
    watchInputOrder(),
    watchError(),
    watchMenu(),
    watchPurchasedProduct(),
    watchDeliveryPatterns(),
    watchResultOrder(),
    watchOrderTypeMaintenanceMaster(),
    watchOrderTypeByStore(),
    watchOrderType(),
    watchOrderHistory(),
    watchNotificationMasterInfo(),
  ]);
}
