import * as constants from "../../constants";
import EncryptUtils from "../../utils/EncryptUtils";

const getTokens = () => {
  const storage = localStorage.getItem(constants.TOKEN_KEY);
  return storage ? JSON.parse(storage) : null;
};

const getAccessToken = () => {
  const token = getTokens();

  return token && token.access ? EncryptUtils.decrypt(token.access) : null;
};

const getRefreshToken = () => {
  const token = getTokens();

  return token && token.refresh ? EncryptUtils.decrypt(token.refresh) : null;
};

const setTokens = (token) => {
  const tokenStorage = {
    access: EncryptUtils.encrypt(token.access),
    refresh: EncryptUtils.encrypt(token.refresh),
  };

  localStorage.setItem(constants.TOKEN_KEY, JSON.stringify(tokenStorage));
};

const handleLogout = () => {
  localStorage.removeItem(constants.TOKEN_KEY);
  localStorage.removeItem("persist:root");
};

const setCompamySelected = (compamy_id) => {
  localStorage.setItem(constants.COMPAMY_ID, compamy_id);
};

const isAuthenticated = () => {
  const tokens = getTokens();
  return tokens && tokens.access;
};

export {
  setTokens,
  handleLogout,
  getTokens,
  getAccessToken,
  getRefreshToken,
  isAuthenticated,
  setCompamySelected,
};
