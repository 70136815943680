import * as actionTypes from "../../constants";

export function doGetOrderHistory(data) {
  return {
    data,
    type: actionTypes.DO_GET_ORDER_HISTORY,
  };
}

export function doGetOrderHistorySuccess(data) {
  return {
    data,
    type: actionTypes.DO_GET_ORDER_HISTORY_SUCCESS,
  };
}

export function doGetOrderHistoryFailed(data) {
  return {
    data,
    type: actionTypes.DO_GET_ORDER_HISTORY_FAILED,
  };
}
