import apiClient from "../../utils/apiClient";

export const getOrderTypeByStoreData = async (params) => {
  const response = await apiClient.get("/api/v1/ordertype-linking", { params });
  return response.data;
};

export const updateOrderTypeByStoreData = async (data) => {
  const response = await apiClient.patch("/api/v1/ordertype-linking", data);
  return response;
};

export const addOrderTypeByStoreData = async (data) => {
  const response = await apiClient.post("/api/v1/ordertype-linking", data);
  return response.data;
};

// ordertype source
export const getOrderTypeByStoreOrderTypeSource = async () => {
  const response = await apiClient.get("/api/v1/ordertype-linking-ordertype");
  return response.data;
};

//business
export const getOrderTypeByStoreBusinessSource = async () => {
  const response = await apiClient.get("/api/v1/ordertype-linking-business");
  return response.data;
};

//store
export const getOrderTypeByStoreStoreSource = async (params) => {
  const response = await apiClient.get("/api/v1/ordertype-linking-store", {
    params,
  });
  return response.data;
};
