import * as actionTypes from "../../constants";
import EncryptUtils from "../../utils/EncryptUtils";

const initialState = {
  access: null,
  user: null,
  role: null,
  jobs: [],
  companies: [],
  error: {},
  logout: {},
};

const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.AUTH_DO_GET_COMPANIES_SUCCESS:
      return {
        ...state,
        companies: action.data,
      };
    case actionTypes.AUTH_DO_GET_COMPANIES_FAILURE:
      return {
        ...state,
        error: action.data,
      };
    case actionTypes.AUTH_DO_ACCESS_TOKEN_SUCCESS:
      return {
        ...state,
        access: EncryptUtils.encrypt(action.data.access),
        user: action.data.user,
        role: action.data.user_role,
        jobs: action.data.user_jobs,
      };
    case actionTypes.AUTH_DO_ACCESS_TOKEN_FAILURE:
      return {
        ...state,
        error: action.data,
      };
    case actionTypes.AUTH_DO_LOGOUT_SUCCESS:
      return {
        ...state,
        access: null,
        user: null,
        role: null,
        jobs: [],
        companies: [],
        error: {},
        logout: {},
      };
    default:
      return state;
  }
};

export default authReducer;
