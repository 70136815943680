import { takeEvery, call, put } from "redux-saga/effects";
import * as actions from "../actions";
import * as actionTypes from "../../constants";
import { getOrderHistoryData } from "../../services/order-history/orderHistoryService";

function* doGetOrderHistoryByData(action) {
  try {
    const response = yield call(getOrderHistoryData, action.data);
    yield put(actions.doGetOrderHistorySuccess(response));
  } catch (error) {
    yield put(actions.doGetOrderHistoryFailed(error));
    yield put(actions.throwError(error));
  }
}

export default function* watchOrderHistory() {
  yield takeEvery(actionTypes.DO_GET_ORDER_HISTORY, doGetOrderHistoryByData);
}
