import { takeEvery, call, put } from "redux-saga/effects";
import * as actions from "../actions";
import * as actionTypes from "../../constants";
import { getDeliveryPatternsData } from "../../services/delivery-patterns/deliveryPatternsService";

function* doGetOrderTypeByData(action) {
  try {
    const response = yield call(getDeliveryPatternsData, action.data);
    yield put(actions.doGetDeliveryPatternsSuccess(response));
  } catch (error) {
    yield put(actions.doGetDeliveryPatternsFailed(error));
    yield put(actions.throwError(error));
  }
}

export default function* watchOrderType() {
  yield takeEvery(actionTypes.DO_GET_DELIVERY_PATTERNS, doGetOrderTypeByData);
}
