import * as actionTypes from "../../constants";
const initialState = {
  orderDatesInfo: {
    isLoaded: false,
    dates: [],
  },
  orderInfo: {
    isLoaded: false,
    error_code: null,
    data: null,
  },
};
const resultOrderReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.RESULT_ORDER_DO_GET_ORDERED_DATES_INFO:
      return {
        ...state,
        orderDatesInfo: {
          isLoaded: false,
          dates: [],
        },
        orderInfo: {
          isLoaded: false,
          error_code: null,
          data: null,
        },
      };
    case actionTypes.RESULT_ORDER_DO_GET_ORDERED_ITEMS_INFO:
      return {
        ...state,
        orderInfo: {
          isLoaded: false,
          error_code: null,
          data: null,
        },
      };
    case actionTypes.RESULT_ORDER_DO_GET_ORDERED_DATES_INFO_SUCCESS:
      return {
        ...state,
        orderDatesInfo: {
          isLoaded: true,
          dates: action.data.result,
        },
      };
    case actionTypes.RESULT_ORDER_DO_GET_ORDERED_ITEMS_INFO_SUCCESS:
      return {
        ...state,
        orderInfo: {
          isLoaded: true,
          error_code: action.data.error_code,
          error_message: action.data.error_message,
          data: action.data.data,
        },
      };
    default:
      return state;
  }
};

export default resultOrderReducer;
