import { useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { FaSearch } from "react-icons/fa";

const MAX_LENGTH_INPUT_ORDER_TYPE_CODE = 15;
const MAX_LENGTH_INPUT_ORDER_TYPE_NAME = 40;
const MAX_LENGTH_INPUT_PATTERNCD = 4;
const MAX_LENGTH_INPUT_PATTERN_NAME = 40;

function OrderTypeFilter({ filter, onChange }) {
  const { t } = useTranslation();

  // state
  const [searchOrderTypeCode, setSearchOrderTypeCode] = useState(
    filter.order_typecd || ""
  );
  const [searchOrderTypeName, setSearchOrderTypeName] = useState(
    filter.order_typenm || ""
  );
  const [searchPatterncd, setSearchPatterncd] = useState(
    filter.patterncd || ""
  );
  const [searchPatternName, setSearchPatternName] = useState(
    filter.patternnm || ""
  );

  const handleChangeSearchOrderTypeCode = (e) => {
    setSearchOrderTypeCode(
      e.target.value.trim().slice(0, MAX_LENGTH_INPUT_ORDER_TYPE_CODE)
    );
  };

  const handleChangeSearchOrderTypeName = (e) => {
    setSearchOrderTypeName(
      e.target.value.trim().slice(0, MAX_LENGTH_INPUT_ORDER_TYPE_NAME)
    );
  };

  const handleChangeSearchPatterncd = (e) => {
    setSearchPatterncd(
      e.target.value.trim().slice(0, MAX_LENGTH_INPUT_PATTERNCD)
    );
  };

  const handleChangeSearchPatternName = (e) => {
    setSearchPatternName(
      e.target.value.trim().slice(0, MAX_LENGTH_INPUT_PATTERN_NAME)
    );
  };

  const handleChangeFilter = (e) => {
    e.preventDefault();

    if (!onChange) return;

    const newFilter = {
      order_typecd: searchOrderTypeCode || undefined,
      order_typenm: searchOrderTypeName || undefined,
      patterncd: searchPatterncd || undefined,
      patternnm: searchPatternName || undefined,
    };

    onChange(newFilter);
  };

  return (
    <Form
      className="formInline"
      style={{ maxWidth: "1024px", margin: "0 auto" }}
    >
      <Row className="mb-3">
        <Col sm={5}>
          <Form.Label>{t("ordertype.search.ordertype_code")}</Form.Label>
          <Form.Control
            value={searchOrderTypeCode}
            onChange={handleChangeSearchOrderTypeCode}
          />
        </Col>

        <Col sm={5}>
          <Form.Label>{t("ordertype.search.patterncd")}</Form.Label>
          <Form.Control
            value={searchPatterncd}
            onChange={handleChangeSearchPatterncd}
          />
        </Col>

        <Col sm={2}>
          <Button type="submit" onClick={handleChangeFilter}>
            <FaSearch />
            &nbsp;{t("system.label.search")}
          </Button>
        </Col>
      </Row>

      <Row>
        <Col sm={5}>
          <Form.Label>{t("ordertype.search.ordertype_name")}</Form.Label>
          <Form.Control
            value={searchOrderTypeName}
            onChange={handleChangeSearchOrderTypeName}
          />
        </Col>

        <Col sm={5}>
          <Form.Label>{t("ordertype.search.pattern_name")}</Form.Label>
          <Form.Control
            value={searchPatternName}
            onChange={handleChangeSearchPatternName}
          />
        </Col>

        <Col sm={2}>&nbsp;</Col>
      </Row>
    </Form>
  );
}

export default OrderTypeFilter;
