import { takeEvery, call, put } from "redux-saga/effects";
import * as actions from "../actions";
import * as actionTypes from "../../constants";
import {
  getOrderDates,
  getOrderedItems,
  exportPdf,
} from "../../services/result-order/resultOrderService";

function* doGetOrderedDates(action) {
  try {
    const { data } = action;
    const response = yield call(getOrderDates, data);
    yield put(actions.doGetOrderedDatesSuccess(response.data));
  } catch (error) {
    yield put(actions.throwError(error));
  }
}

function* doGetOrderedItems(action) {
  try {
    const { data } = action;
    const response = yield call(getOrderedItems, data);
    yield put(actions.doGetOrderedItemsSuccess(response.data));
  } catch (error) {
    yield put(actions.throwError(error));
  }
}

function* doExportPdf(action) {
  try {
    const { data } = action;
    yield call(exportPdf, data);
    yield put(actions.doExportPdfSuccess());
  } catch (error) {
    yield put(actions.throwError(error));
  }
}

export default function* watchResultOrder() {
  yield takeEvery(
    actionTypes.RESULT_ORDER_DO_GET_ORDERED_DATES_INFO,
    doGetOrderedDates
  );
  yield takeEvery(
    actionTypes.RESULT_ORDER_DO_GET_ORDERED_ITEMS_INFO,
    doGetOrderedItems
  );
  yield takeEvery(actionTypes.RESULT_ORDER_DO_EXPORT_PDF, doExportPdf);
}
