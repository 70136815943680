export const DO_GET_DELIVERY_PATTERNS = "DO_GET_DELIVERY_PATTERNS";
export const DO_GET_DELIVERY_PATTERNS_SUCCESS =
  "DO_GET_DELIVERY_PATTERNS_SUCCESS";
export const DO_GET_DELIVERY_PATTERNS_FAILED =
  "DO_GET_DELIVERY_PATTERNS_FAILED";
export const DO_SET_FILTER_DELIVERY_PATTERNS =
  "DO_SET_FILTER_DELIVERY_PATTERNS";
export const SET_ERROR_DELIVERY_PATTERNS = "SET_ERROR_DELIVERY_PATTERNS";
export const DO_UPDATE_ITEM_DELIVERY_PATTERNS =
  "DO_UPDATE_ITEM_DELIVERY_PATTERNS";
