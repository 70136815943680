import apiClient from "../../utils/apiClient";
import { getRefreshToken, setTokens, setCompamySelected, handleLogout } from "../storages";

export const login = ({ company_code, employee_code, password }) => {
  const data = {
    company_code,
    employee_code,
    password,
  };

  return apiClient
    .post("/api/v1/login", data)
    .then((response) => {
      setTokens(response.data);
      setCompamySelected(response.data["user"]["kaisyacd"]);
      return response;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
};

export const logout = () => {
  return apiClient.post("/api/v1/logout").then(rep => {
    console.log(rep)
  }).catch(err => {
    handleLogout();
    window.location.reload();
  });
};

export const refreshToken = () => {
  return apiClient.post(
    "/token/refresh",
    {
      refresh: getRefreshToken(),
    },
    { auth: false }
  );
};

export const companies = () => {
  return apiClient.get("/api/v1/companies");
};
