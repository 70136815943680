import _ from "lodash";
import * as actionTypes from "../../constants";

const initialState = {
  loading: false,
  data: [],
  rootData: {},
  modified: {},
  filter: {},
  error: [],
};

const orderTypeReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.DO_GET_ORDERTYPE:
      return {
        ...state,
        loading: true,
        data: [],
        rootData: {},
        modified: {},
        error: [],
      };
    case actionTypes.DO_GET_ORDERTYPE_SUCCESS:
      const rootData = action.data.reduce((dataMap, item) => {
        dataMap[item.order_typecd + "_" + item.patterncd] = item;
        return dataMap;
      }, {});

      return {
        ...state,
        loading: false,
        data: action.data,
        rootData: rootData,
      };
    case actionTypes.DO_GET_ORDERTYPE_FAILED:
      return {
        ...state,
        loading: false,
        error: action.data,
      };
    case actionTypes.DO_SET_FILTER_ORDERTYPE:
      return {
        ...state,
        filter: action.data,
      };
    case actionTypes.SET_ERROR_ORDERTYPE:
      return {
        ...state,
        error: action.data,
      };
    case actionTypes.DO_UPDATE_ITEM_ORDERTYPE:
      const newData = state.data.map((item) => {
        const primaryKey = item.order_typecd + "_" + item.patterncd;
        if (
          item.order_typecd === action.data.order_typecd &&
          item.patterncd === action.data.patterncd
        ) {
          const modifiedItem = state.modified[primaryKey];
          const rootItem = state.rootData[primaryKey];
          if (modifiedItem) {
            const actionData = {
              ...action.data,
              order_typecd:
                action.data.order_typecd_changed !== undefined
                  ? action.data.order_typecd_changed
                  : action.data.order_typecd,
              patterncd:
                action.data.patterncd_changed !== undefined
                  ? action.data.patterncd_changed
                  : action.data.patterncd,
            };
            if (
              actionData.order_typecd === rootItem.order_typecd &&
              actionData.patterncd === rootItem.patterncd &&
              actionData.deleteflg === rootItem.deleteflg &&
              actionData.order_typenm === rootItem.order_typenm
            ) {
              delete state.modified[primaryKey];
            } else {
              state.modified[primaryKey] = action.data;
            }
          } else {
            state.modified[primaryKey] = action.data;
          }

          //sort modified
          var sortModified = {};
          _(state.modified)
            .keys()
            .sort()
            .each(function (key) {
              sortModified[key] = state.modified[key];
            });
          state.modified = sortModified;

          return action.data;
        }

        return item;
      });

      return {
        ...state,
        data: newData,
      };
    case actionTypes.RESET_MODIFIED_ORDERTYPE:
      const resetRootData = state.data.reduce((dataMap, item) => {
        dataMap[item.order_typecd + "_" + item.patterncd] = item;
        return dataMap;
      }, {});
      return {
        ...state,
        rootData: resetRootData,
        modified: {},
      };
    default:
      return state;
  }
};

//selector
export const selectOrderTypeLoading = (state) => state.orderType.loading;
export const selectOrderTypeData = (state) => state.orderType.data;
export const selectOrderTypeModified = (state) => state.orderType.modified;
export const selectOrderTypeFilter = (state) => state.orderType.filter;
export const selectOrderTypeError = (state) => state.orderType.error;

export default orderTypeReducer;
