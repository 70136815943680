export const AUTH_DO_ACCESS_TOKEN = "AUTH_DO_ACCESS_TOKEN";
export const AUTH_DO_ACCESS_TOKEN_SUCCESS = "AUTH_DO_ACCESS_TOKEN_SUCCESS";
export const AUTH_DO_ACCESS_TOKEN_FAILURE = "AUTH_DO_ACCESS_TOKEN_FAILURE";
export const AUTH_DO_LOGOUT = "AUTH_DO_LOGOUT";
export const AUTH_DO_LOGOUT_SUCCESS = "AUTH_DO_LOGOUT_SUCCESS";
export const AUTH_THROW_ERROR = "AUTH_THROW_ERROR";

// company
export const AUTH_DO_GET_COMPANIES = "AUTH_DO_GET_COMPANIES";
export const AUTH_DO_GET_COMPANIES_SUCCESS = "AUTH_DO_GET_COMPANIES_SUCCESS";
export const AUTH_DO_GET_COMPANIES_FAILURE = "AUTH_DO_GET_COMPANIES_FAILURE";
