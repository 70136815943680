import * as actionTypes from "../../constants";

export function doGetDeliveryPatterns(data) {
  return {
    data,
    type: actionTypes.DO_GET_DELIVERY_PATTERNS,
  };
}

export function doGetDeliveryPatternsSuccess(data) {
  return {
    data,
    type: actionTypes.DO_GET_DELIVERY_PATTERNS_SUCCESS,
  };
}

export function doGetDeliveryPatternsFailed(data) {
  return {
    data,
    type: actionTypes.DO_GET_DELIVERY_PATTERNS_FAILED,
  };
}

export function setDeliveryPatternsFilter(data) {
  return {
    data,
    type: actionTypes.DO_SET_FILTER_DELIVERY_PATTERNS,
  };
}

export function updateItemDeliveryPatterns(data) {
  return {
    data,
    type: actionTypes.DO_UPDATE_ITEM_DELIVERY_PATTERNS,
  };
}

export function setDeliveryPatternsError(data) {
  return {
    data,
    type: actionTypes.SET_ERROR_DELIVERY_PATTERNS,
  };
}
