import { takeEvery, call, put } from "redux-saga/effects";
import * as actions from "../actions";
import * as actionTypes from "../../constants";
import { getOrderTypeData } from "../../services/ordertype/orderTypeService";

function* doGetOrderTypeByData(action) {
  try {
    const response = yield call(getOrderTypeData, action.data);
    yield put(actions.doGetOrderTypeSuccess(response));
  } catch (error) {
    yield put(actions.doGetOrderTypeFailed(error));
    yield put(actions.throwError(error));
  }
}

export default function* watchOrderType() {
  yield takeEvery(actionTypes.DO_GET_ORDERTYPE, doGetOrderTypeByData);
}
