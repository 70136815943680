import { yupResolver } from "@hookform/resolvers/yup";
import React from "react";
import { Button, Col, Form, Modal, Row, Spinner } from "react-bootstrap";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import * as yup from "yup";
import { addDeliveryPatternsData } from "../../../services/delivery-patterns/deliveryPatternsService";

const MAX_LENGTH_INPUT_CODE = 4;
const MAX_LENGTH_INPUT_NAME = 40;
const MAX_LENGTH_INPUT_DAY = 3;
const MAX_LENGTH_INPUT_REMARK = 40;

function DeliveryPatternsForm({ open = false, onClose, onSubmitSuccessfully }) {
  const { t } = useTranslation();

  const schema = yup
    .object({
      patterncd: yup
        .string()
        .required(t("delivery_patterns.message.warn_patterncd_required")),
      patternnm: yup
        .string()
        .required(t("delivery_patterns.message.warn_patternnm_required")),
    })
    .required();

  const {
    control,
    handleSubmit,
    clearErrors,
    reset,
    setError,
    formState: { errors, isSubmitting },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const onSubmit = async (formValue) => {
    try {
      const days = [
        "mon_delivery_days",
        "tue_delivery_days",
        "wed_delivery_days",
        "thu_delivery_days",
        "fri_delivery_days",
        "sat_delivery_days",
        "sun_delivery_days",
      ];
      days.forEach((day) => {
        formValue[day] = formValue[day]
          ? formValue[day].trim().length === 0
            ? "0"
            : formValue[day]
          : "0";
      });
      formValue["remarks"] = formValue["remarks"]
        ? formValue["remarks"].trim()
        : "";

      await addDeliveryPatternsData(formValue);
      onSubmitSuccessfully && onSubmitSuccessfully();
      handleClose();
    } catch (e) {
      if (e.response && e.response.data) {
        if (e.response.data["non_field_errors"]) {
          setError("common", {
            type: "custom",
            message: e.response.data["non_field_errors"],
          });
        } else if (e.response.data["patterncd"]) {
          setError("patterncd", {
            type: "custom",
            message: e.response.data["patterncd"],
          });
        } else {
          setError("common", {
            type: "custom",
            message: t("delivery_patterns.message.add_failed"),
          });
        }
      }
    }
  };

  const handleClose = () => {
    clearErrors();
    reset();
    onClose && onClose();
  };

  const handlePatternCodeChange = (onChange) => (e) => {
    const value = e.target.value.slice(0, MAX_LENGTH_INPUT_CODE);
    onChange(value);
  };

  const handlePatternNameChange = (onChange) => (e) => {
    const value = e.target.value.slice(0, MAX_LENGTH_INPUT_NAME);
    onChange(value);
  };

  const handleDeliveryDaysChange = (onChange, value) => (e) => {
    if (e.target.validity.valid) {
      e.target.value = e.target.value.slice(0, MAX_LENGTH_INPUT_DAY);
      onChange(e.target.value);
    } else {
      e.target.value = value;
    }
  };

  const handlePatternRemarkChange = (onChange) => (e) => {
    const value = e.target.value.slice(0, MAX_LENGTH_INPUT_REMARK);
    onChange(value);
  };

  return (
    <Modal show={open} onHide={handleClose} backdrop="static" centered>
      <form noValidate onSubmit={handleSubmit(onSubmit)}>
        <Modal.Header closeButton>
          <Modal.Title>
            {t("ordertype.label.form_title_add_new_data")}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group className="mb-1">
            <Row>
              <Col sm={3}>
                <Form.Label
                  dangerouslySetInnerHTML={{
                    __html: t("delivery_patterns.table.thead.code"),
                  }}
                ></Form.Label>
              </Col>
              <Col sm={9}>
                <Controller
                  control={control}
                  name="patterncd"
                  render={({
                    field: { onChange, onBlur, value, name, ref },
                  }) => (
                    <Form.Control
                      name={name}
                      value={value || ""}
                      onChange={handlePatternCodeChange(onChange)}
                      onBlur={onBlur}
                      ref={ref}
                      className={errors.patterncd && "is-invalid"}
                    />
                  )}
                />
                {errors.patterncd && (
                  <div className="mt-1 text-danger">{`${errors.patterncd?.message}`}</div>
                )}
              </Col>
            </Row>
          </Form.Group>

          <Form.Group className="mb-1">
            <Row>
              <Col sm={3}>
                <Form.Label
                  dangerouslySetInnerHTML={{
                    __html: t("delivery_patterns.table.thead.name"),
                  }}
                ></Form.Label>
              </Col>
              <Col sm={9}>
                <Controller
                  control={control}
                  name="patternnm"
                  render={({
                    field: { onChange, onBlur, value, name, ref },
                  }) => (
                    <Form.Control
                      name={name}
                      value={value || ""}
                      onChange={handlePatternNameChange(onChange)}
                      onBlur={onBlur}
                      ref={ref}
                      className={errors.patternnm && "is-invalid"}
                    />
                  )}
                />
                {errors.patternnm && (
                  <div className="mt-1 text-danger">{`${errors.patternnm?.message}`}</div>
                )}
              </Col>
            </Row>
          </Form.Group>

          <Form.Group className="mb-1">
            <Row>
              <Col sm={3}>
                <Form.Label
                  dangerouslySetInnerHTML={{
                    __html: t("delivery_patterns.table.thead.mon"),
                  }}
                ></Form.Label>
              </Col>
              <Col sm={9}>
                <Controller
                  control={control}
                  name="mon_delivery_days"
                  render={({
                    field: { onChange, onBlur, value, name, ref },
                  }) => (
                    <Form.Control
                      type="number"
                      pattern="[0-9]*"
                      min="0"
                      name={name}
                      value={value || ""}
                      onInput={handleDeliveryDaysChange(onChange, value)}
                      onBlur={onBlur}
                      ref={ref}
                      className={errors.mon_delivery_days && "is-invalid"}
                    />
                  )}
                />
                {errors.mon_delivery_days && (
                  <div className="mt-1 text-danger">{`${errors.mon_delivery_days?.message}`}</div>
                )}
              </Col>
            </Row>
          </Form.Group>

          <Form.Group className="mb-1">
            <Row>
              <Col sm={3}>
                <Form.Label
                  dangerouslySetInnerHTML={{
                    __html: t("delivery_patterns.table.thead.tue"),
                  }}
                ></Form.Label>
              </Col>
              <Col sm={9}>
                <Controller
                  control={control}
                  name="tue_delivery_days"
                  render={({
                    field: { onChange, onBlur, value, name, ref },
                  }) => (
                    <Form.Control
                      type="number"
                      pattern="[0-9]*"
                      min="0"
                      name={name}
                      value={value || ""}
                      onInput={handleDeliveryDaysChange(onChange, value)}
                      onBlur={onBlur}
                      ref={ref}
                      className={errors.tue_delivery_days && "is-invalid"}
                    />
                  )}
                />
                {errors.tue_delivery_days && (
                  <div className="mt-1 text-danger">{`${errors.tue_delivery_days?.message}`}</div>
                )}
              </Col>
            </Row>
          </Form.Group>

          {/* wed */}
          <Form.Group className="mb-1">
            <Row>
              <Col sm={3}>
                <Form.Label
                  dangerouslySetInnerHTML={{
                    __html: t("delivery_patterns.table.thead.wed"),
                  }}
                ></Form.Label>
              </Col>
              <Col sm={9}>
                <Controller
                  control={control}
                  name="wed_delivery_days"
                  render={({
                    field: { onChange, onBlur, value, name, ref },
                  }) => (
                    <Form.Control
                      type="number"
                      pattern="[0-9]*"
                      min="0"
                      name={name}
                      value={value || ""}
                      onInput={handleDeliveryDaysChange(onChange, value)}
                      onBlur={onBlur}
                      ref={ref}
                      className={errors.wed_delivery_days && "is-invalid"}
                    />
                  )}
                />
                {errors.wed_delivery_days && (
                  <div className="mt-1 text-danger">{`${errors.wed_delivery_days?.message}`}</div>
                )}
              </Col>
            </Row>
          </Form.Group>

          {/* thu */}
          <Form.Group className="mb-1">
            <Row>
              <Col sm={3}>
                <Form.Label
                  dangerouslySetInnerHTML={{
                    __html: t("delivery_patterns.table.thead.thu"),
                  }}
                ></Form.Label>
              </Col>
              <Col sm={9}>
                <Controller
                  control={control}
                  name="thu_delivery_days"
                  render={({
                    field: { onChange, onBlur, value, name, ref },
                  }) => (
                    <Form.Control
                      type="number"
                      pattern="[0-9]*"
                      min="0"
                      name={name}
                      value={value || ""}
                      onInput={handleDeliveryDaysChange(onChange, value)}
                      onBlur={onBlur}
                      ref={ref}
                      className={errors.thu_delivery_days && "is-invalid"}
                    />
                  )}
                />
                {errors.thu_delivery_days && (
                  <div className="mt-1 text-danger">{`${errors.thu_delivery_days?.message}`}</div>
                )}
              </Col>
            </Row>
          </Form.Group>

          {/* fri */}
          <Form.Group className="mb-1">
            <Row>
              <Col sm={3}>
                <Form.Label
                  dangerouslySetInnerHTML={{
                    __html: t("delivery_patterns.table.thead.fri"),
                  }}
                ></Form.Label>
              </Col>
              <Col sm={9}>
                <Controller
                  control={control}
                  name="fri_delivery_days"
                  render={({
                    field: { onChange, onBlur, value, name, ref },
                  }) => (
                    <Form.Control
                      type="number"
                      pattern="[0-9]*"
                      min="0"
                      name={name}
                      value={value || ""}
                      onInput={handleDeliveryDaysChange(onChange, value)}
                      onBlur={onBlur}
                      ref={ref}
                      className={errors.fri_delivery_days && "is-invalid"}
                    />
                  )}
                />
                {errors.fri_delivery_days && (
                  <div className="mt-1 text-danger">{`${errors.fri_delivery_days?.message}`}</div>
                )}
              </Col>
            </Row>
          </Form.Group>

          {/* sat */}
          <Form.Group className="mb-1">
            <Row>
              <Col sm={3}>
                <Form.Label
                  dangerouslySetInnerHTML={{
                    __html: t("delivery_patterns.table.thead.sat"),
                  }}
                ></Form.Label>
              </Col>
              <Col sm={9}>
                <Controller
                  control={control}
                  name="sat_delivery_days"
                  render={({
                    field: { onChange, onBlur, value, name, ref },
                  }) => (
                    <Form.Control
                      type="number"
                      pattern="[0-9]*"
                      min="0"
                      name={name}
                      value={value || ""}
                      onInput={handleDeliveryDaysChange(onChange, value)}
                      onBlur={onBlur}
                      ref={ref}
                      className={errors.sat_delivery_days && "is-invalid"}
                    />
                  )}
                />
                {errors.sat_delivery_days && (
                  <div className="mt-1 text-danger">{`${errors.sat_delivery_days?.message}`}</div>
                )}
              </Col>
            </Row>
          </Form.Group>

          {/* sun */}
          <Form.Group className="mb-1">
            <Row>
              <Col sm={3}>
                <Form.Label
                  dangerouslySetInnerHTML={{
                    __html: t("delivery_patterns.table.thead.sun"),
                  }}
                ></Form.Label>
              </Col>
              <Col sm={9}>
                <Controller
                  control={control}
                  name="sun_delivery_days"
                  render={({
                    field: { onChange, onBlur, value, name, ref },
                  }) => (
                    <Form.Control
                      type="number"
                      pattern="[0-9]*"
                      min="0"
                      name={name}
                      value={value || ""}
                      onInput={handleDeliveryDaysChange(onChange, value)}
                      onBlur={onBlur}
                      ref={ref}
                      className={errors.sun_delivery_days && "is-invalid"}
                    />
                  )}
                />
                {errors.sun_delivery_days && (
                  <div className="mt-1 text-danger">{`${errors.sun_delivery_days?.message}`}</div>
                )}
              </Col>
            </Row>
          </Form.Group>

          <Form.Group className="mb-1">
            <Row>
              <Col sm={3}>
                <Form.Label
                  dangerouslySetInnerHTML={{
                    __html: t("delivery_patterns.table.thead.remark"),
                  }}
                ></Form.Label>
              </Col>
              <Col sm={9}>
                <Controller
                  control={control}
                  name="remarks"
                  render={({
                    field: { onChange, onBlur, value, name, ref },
                  }) => (
                    <Form.Control
                      name={name}
                      value={value || ""}
                      onChange={handlePatternRemarkChange(onChange)}
                      onBlur={onBlur}
                      ref={ref}
                      className={errors.remarks && "is-invalid"}
                    />
                  )}
                />
                {errors.remarks && (
                  <div className="mt-1 text-danger">{`${errors.remarks?.message}`}</div>
                )}
              </Col>
            </Row>
          </Form.Group>
        </Modal.Body>
        <Modal.Footer className="text-center d-flex">
          <div>
            <Button
              type="submit"
              className="me-3"
              style={{ minWidth: "150px" }}
              disabled={isSubmitting}
            >
              {isSubmitting && (
                <Spinner
                  animation="border"
                  size="sm"
                  variant="light"
                  className="me-1"
                />
              )}
              {t("ordertype.label.register")}
            </Button>
            <Button
              variant="secondary"
              style={{ minWidth: "150px" }}
              onClick={handleClose}
            >
              {t("ordertype.label.return")}
            </Button>
          </div>

          {errors.common && (
            <div className="mt-3 fw-bold text-danger">{`${errors.common.message}`}</div>
          )}
        </Modal.Footer>
      </form>
    </Modal>
  );
}

export default DeliveryPatternsForm;
