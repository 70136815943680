import React from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import "./sidebar.scss";
import { NavLink, useLocation } from "react-router-dom";
import {
  ProSidebar,
  Menu,
  MenuItem,
  SubMenu,
  SidebarHeader,
  SidebarFooter,
  SidebarContent,
} from "react-pro-sidebar";
import {
  FaClipboardList,
  FaRegFileAlt,
  FaCogs,
  FaSignOutAlt,
  FaHistory,
} from "react-icons/fa";

const SUB_MENUS = [
  "/purchased-product",
  "/delivery-patterns",
  "/ordertype",
  "/ordertype-by-store",
  "/order-type-maintenance-master",
];

const Aside = ({ collapsed, toggled, handleToggleSidebar, handleLogout }) => {
  const { t } = useTranslation();
  const location = useLocation();
  const role = useSelector((state) => state.menu.jobSelected.level);
  return (
    <ProSidebar
      width={285}
      image={false}
      collapsed={collapsed}
      toggled={toggled}
      breakPoint="md"
      onToggle={handleToggleSidebar}
    >
      <SidebarHeader>
        <div className="sidebar-header">{t("system.label.title_header")}</div>
      </SidebarHeader>

      <SidebarContent>
        <Menu iconShape="circle">
          <MenuItem icon={<FaClipboardList />}>
            <NavLink to={"/"}>{t("system.label.input_order")}</NavLink>
          </MenuItem>
          <MenuItem icon={<FaRegFileAlt />}>
            <NavLink to={"/confirm-result-order"}>
              {t("system.label.result_order_confirmation")}
            </NavLink>
          </MenuItem>
          <MenuItem icon={<FaHistory />}>
            <NavLink to={"/order-history"}>
              {t("system.label.order_cumulative_amount_confirmation_screen")}
            </NavLink>
          </MenuItem>
          {parseInt(role) >= 300 && (
            <SubMenu
              title={"マスタメンテ"}
              icon={<FaCogs />}
              defaultOpen={SUB_MENUS.includes(location.pathname)}
            >
              <MenuItem>
                <NavLink to={"/purchased-product"}>
                  {t("system.label.purchased_product")}
                </NavLink>
              </MenuItem>
              <MenuItem>
                <NavLink to={"/delivery-patterns"}>
                  {t("system.label.delivery_cycle_pattern_master")}
                </NavLink>
              </MenuItem>
              <MenuItem>
                <NavLink to={"/ordertype"}>
                  {t("system.label.order_type_master")}
                </NavLink>
              </MenuItem>
              <MenuItem>
                <NavLink to={"/ordertype-by-store"}>
                  {t("system.label.order_type_tying_master_by_store")}
                </NavLink>
              </MenuItem>
              <MenuItem>
                <NavLink to={"/order-type-maintenance-master"}>
                  {t("system.label.order_type_tying_master_by_special_period")}
                </NavLink>
              </MenuItem>
              <MenuItem>
                <NavLink to={"/notification-master"}>
                  {t("system.label.notification_master")}
                </NavLink>
              </MenuItem>
            </SubMenu>
          )}
        </Menu>
      </SidebarContent>

      <SidebarFooter>
        <Menu iconShape="circle">
          <MenuItem icon={<FaSignOutAlt />} onClick={handleLogout}>
            {t("system.label.logout")}
          </MenuItem>
        </Menu>
      </SidebarFooter>
    </ProSidebar>
  );
};

export default Aside;
