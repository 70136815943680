import * as actionTypes from "../../constants";

const initialState = {
  data: null,
  store: undefined,
  error: {},
  jobSelected: null,
  storeSelected: null,
  storeNameSelected: null,
};

const menuReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.DO_GET_MENU_SUCCESS:
      let storeSelected = null;
      if (action.data && action.data.length === 1) {
        storeSelected = action.data[0].value;
      }
      return {
        ...state,
        data: action.data,
        storeSelected: storeSelected,
      };
    case actionTypes.DO_SET_JOB_SUCCESS:
      return {
        ...state,
        jobSelected: action.data.job,
      };
    case actionTypes.DO_CHOOSE_STORE_SUCCESS:
      return {
        ...state,
        storeSelected: action.data.store,
        storeNameSelected: action.data.storeName,
      };
    default:
      return state;
  }
};

export default menuReducer;
