import React, { useState, useEffect } from "react";
import { CSVLink } from "react-csv";
import { useSelector } from "react-redux";
import { Button } from "react-bootstrap";
import { FaDownload } from "react-icons/fa";
import StringUtils from "../../../utils/StringUtils";

export default function CSVLinkCustom() {
  const data = useSelector((state) => state.orderTypeMaintenanceMaster.data);
  const [dataExport, setDataExport] = useState(data);
  useEffect(() => {
    if (data) {
      let rows = data.map((row) => ({
        ...row,
        area_kbn: StringUtils.areaKbnValue(row.area_kbn),
        order_typenm: row.order_typenm,
        startdate: StringUtils.convertDate(row.startdate),
        enddate: StringUtils.convertDate(row.enddate),
      }));
      setDataExport(rows);
    }
  }, [data]);

  // eslint-disable-next-line no-unused-vars
  const [headers, setHeaders] = useState([
    { label: "業態", key: "meisyo" },
    { label: "エリア", key: "area_kbn" },
    { label: "店舗コード", key: "tenpocd4"},
    { label: "店舗名", key: "tenpo_meisyo"},
    { label: "適用開始日", key: "startdate" },
    { label: "適用終了日", key: "enddate" },
    { label: "発注タイプID", key: "order_typecd"},
    { label: "発注タイプ名", key: "order_typenm"},
  ]);

  return (
    <>
      {dataExport ? (
        <CSVLink
          target="_blank"
          filename={"linked_order_types_periods.csv"}
          data={dataExport}
          headers={headers}
          className="btn btn-primary csv-export"
        >
          <FaDownload /> CSVダウンロード
        </CSVLink>
      ) : (
        <Button className="btn-add" variant="primary">
          CSVダウンロード
        </Button>
      )}
    </>
  );
}
