import * as actionTypes from "../../constants";

export const throwError = (data) => ({
  data,
  type: actionTypes.THROW_ERROR,
});

export function handleError(data) {
  return { data, type: actionTypes.HANDLE_ERROR };
}

export function doShowTimeoutError() {
  return { type: actionTypes.TIMEOUT_ERROR };
}

export function doResetError() {
  return { type: actionTypes.RESET_ERROR };
}

export function doResetErrorSuccess() {
  return { type: actionTypes.RESET_ERROR_SUCCESS };
}
