import Form from "react-bootstrap/Form";
import { PERIOD_MONTH } from "../../../constants/orderHistoryConstant";
import React from "react";
import { useTranslation } from "react-i18next";

export function SelectedYearMonth(props) {
  const { t } = useTranslation();
  const count_year = PERIOD_MONTH / 12;
  let arr_month_years = [];
  const crr_year = new Date().getFullYear();
  const crr_month = new Date().getMonth();
  for (let i = 0; i < count_year + 1; i++) {
    for (let j = 12; j > 0; j--) {
      if (i === 0 && j <= crr_month + 1) {
        arr_month_years.push({
          value: crr_year - i + String(j).padStart(2, "0"),
          text:
            crr_year -
            i +
            t("system.label.year") +
            String(j).padStart(2, "0") +
            t("system.label.month"),
        });
      } else if (i > 0) {
        arr_month_years.push({
          value: crr_year - i + String(j).padStart(2, "0"),
          text:
            crr_year -
            i +
            t("system.label.year") +
            String(j).padStart(2, "0") +
            t("system.label.month"),
        });
        if (i === count_year && j <= crr_month + 1) break;
      }
    }
  }

  return (
    <>
      <Form.Select
        defaultValue={props.selectedYearMonth}
        onChange={(e) => props.handleChangeYearMonth(e)}
        className="d-inline-block w-auto"
      >
        {arr_month_years.map((monthYear, index) => (
          <option key={index} value={monthYear.value}>
            {monthYear.text}
          </option>
        ))}
      </Form.Select>
    </>
  );
}

export function SelectWeek(props) {
  const { t } = useTranslation();
  const arrWeeks = [
    { value: 0, text: t("order_history.select.month") }, // value 0 all weeks
    { value: 1, text: t("order_history.select.first_week") },
    { value: 2, text: t("order_history.select.second_week") },
    { value: 3, text: t("order_history.select.third_week") },
    { value: 4, text: t("order_history.select.fourth_week") },
    { value: 5, text: t("order_history.select.fifth_week") },
  ];

  return (
    <>
      <Form.Select
        defaultValue={props.selectedWeek}
        onChange={(e) => props.handleChangeOrderPattern(e)}
        className="d-inline-block w-auto"
      >
        {arrWeeks.map((week, index) => (
          <option key={index} value={week.value}>
            {week.text}
          </option>
        ))}
      </Form.Select>
    </>
  );
}

export function OrderHistoryDateRange({
  handleChangeOrderPattern,
  handleChangeYearMonth,
  orderPattern,
  yearMonth,
}) {
  return (
    <>
      <td>
        <SelectedYearMonth
          selectedYearMonth={yearMonth}
          handleChangeYearMonth={handleChangeYearMonth}
        />
      </td>
      <td>
        <SelectWeek
          selectedWeek={orderPattern}
          handleChangeOrderPattern={handleChangeOrderPattern}
        />
      </td>
    </>
  );
}
