import apiClient from "../../utils/apiClient";

export const apiGetOrderTypeMaintenanceMaster = (request) => {
  const data = request["data"];
  return apiClient
    .get("/api/v1/order-type", {
      params: data,
    })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
};

export const apiSaveOrderType = (dataRequest) => {
  return apiClient
    .post("/api/v1/linked-order-types-periods", dataRequest["data"])
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
};

export const apiUpdateOrderType = (dataRequest) => {
  return apiClient
    .post(`/api/v1/order-type/update-multi`, { data: dataRequest["data"] })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
};

export const apiGetBusinessType = (dataRequest) => {
  return apiClient
    .get(`/api/v1/order-type/business`)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
};

export const apiGetStoreByBusinessType = (dataRequest) => {
  return apiClient
    .post(`/api/v1/order-type/business`, dataRequest["data"])
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
};

export const apiGetOrderTypeList = () => {
  return apiClient
    .get(`/api/v1/order-type/list`)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
};

export const apiOrderTypeImportCsv = (dataForm) => {
  return apiClient
    .post(`/api/v1/linked-order-types-periods/import-csv`, dataForm)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
};
