import { Breadcrumb, Row, Col, Card, Table, Alert } from "react-bootstrap";
import "./index.scss";
import React, { useEffect, Fragment, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { doGetOrderHistory } from "../../stores/actions";
import StringUtils from "../../utils/StringUtils";
import { OrderHistoryDateRange } from "./components/OrderHistoryDateRange";
import {
  DATE_START_WEEK,
  DEFAULT_PATTERN_WEEK,
} from "../../constants/orderHistoryConstant";

function OrderHistory(params) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const menuState = useSelector((state) => state.menu);
  // selector order history state
  const orderHistoryState = useSelector((state) => state.orderHistory);
  // list order history
  const [orderHistory, setOrderHistory] = useState();
  // condition to select order history , select week number of month default is month
  const [selectedOrderPattern, setSelectedOrderPattern] =
    useState(DEFAULT_PATTERN_WEEK);
  // yyyymm
  const currentYearMonth = StringUtils.formatDate(new Date()).slice(0, 6);
  // state current year month
  const [selectedYearMonth, setSelectedYearMonth] = useState(currentYearMonth);
  // get range of selected year month from date to date
  const [rangeOfDate, setRangeOfDate] = useState(null);

  // dispatch on date changed, store changed
  useEffect(() => {
    if (menuState.storeSelected) {
      const dateTimeRange = getRangeOfDate();
      setRangeOfDate(dateTimeRange);
      const tenpo_cd = menuState.storeSelected;
      const data = {
        tenpo_cd,
        order_from: dateTimeRange.orderFrom,
        order_to: dateTimeRange.orderTo,
      };
      dispatch(doGetOrderHistory(data));
    }
  }, [menuState.storeSelected, selectedYearMonth, selectedOrderPattern]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (orderHistoryState) {
      setOrderHistory(orderHistoryState.data);
    }
  }, [orderHistoryState]);

  /**
   * Function calculates the range of order history for the given month end week.
   * @returns Object from the date to date
   */
  const getRangeOfDate = () => {
    const currentYear = selectedYearMonth.slice(0, 4);
    const currentMonth = selectedYearMonth.slice(-2);
    const endDateOfMonth = new Date(currentYear, currentMonth, 0);

    let startDate = new Date(currentYear + "-" + currentMonth + "-01");
    let endDate = new Date(currentYear + "-" + currentMonth + "-01");
    const dayOfWeekOfStartDate = startDate.getDay();

    if (selectedOrderPattern === 0) {
      endDate = endDateOfMonth;
    }
    for (let idx = 1; idx <= selectedOrderPattern; idx++) {
      if (idx === 1) {
        // in case first week
        if (dayOfWeekOfStartDate < DATE_START_WEEK) {
          endDate.setDate(
            endDate.getDate() + (DATE_START_WEEK - dayOfWeekOfStartDate - 1)
          );
        } else {
          endDate.setDate(
            endDate.getDate() + (6 - (dayOfWeekOfStartDate - DATE_START_WEEK))
          );
        }
      } else {
        startDate.setDate(endDate.getDate() + 1);
        endDate.setDate(endDate.getDate() + 7);
        if (idx === 5) {
          endDate = endDateOfMonth;
        }
      }
    }
    return {
      orderFrom: StringUtils.formatDate(startDate),
      orderTo: StringUtils.formatDate(endDate),
    };
  };

  const handleChangeYearMonth = (e) => {
    let value = e.target.value;
    setSelectedYearMonth(value);
  };

  const handleChangeOrderPattern = (e) => {
    let value = e.target.value;
    setSelectedOrderPattern(parseInt(value));
  };

  // summary total orders price
  let order_price_total = 0;
  if (orderHistory != null) {
    order_price_total = orderHistory.data.reduce((accumulator, object) => {
      return accumulator + object.total_price;
    }, 0);
  }

  return (
    <Row>
      <Col md={12}>
        <Breadcrumb>
          <Breadcrumb.Item active>{t("system.label.home")}</Breadcrumb.Item>
          <Breadcrumb.Item active>
            {t("order_history.label.order_history_screen")}
          </Breadcrumb.Item>
        </Breadcrumb>
        <Card>
          <Card.Header
            as="h2"
            bg="light"
            style={{ textAlign: "center", color: "#ef8412" }}
          >
            {t("order_history.label.order_history_screen")}
          </Card.Header>

          <Card.Body>
            {!menuState.storeSelected && (
              <Alert variant="warning">
                {t("system.message.select_store")}
              </Alert>
            )}
            {menuState.storeSelected && (
              <Fragment>
                <Table
                  bordered
                  responsive
                  className="custom-table-width text-center"
                >
                  <thead className="bg-common">
                    <tr className="bg-title-table">
                      <th style={{ width: "50%" }}>
                        {t("order_history.label.order_month")}
                      </th>
                      <th>{t("order_history.label.ordering_pattern")}</th>
                    </tr>
                  </thead>
                  <tbody className="list-body">
                    <tr>
                      <OrderHistoryDateRange
                        handleChangeYearMonth={handleChangeYearMonth}
                        handleChangeOrderPattern={handleChangeOrderPattern}
                      />
                    </tr>
                  </tbody>
                </Table>
                {rangeOfDate && (
                  <div className="text-left custom-table-width mb-1">
                    <div>
                      <p className="mb-0 ps-5">
                        <strong>
                          {t("order_history.label.target_period")}
                        </strong>
                      </p>
                      <p className="mb-0">
                        <strong>
                          {StringUtils.convertDate(rangeOfDate.orderFrom)}
                          &nbsp;~&nbsp;
                          {StringUtils.convertDate(rangeOfDate.orderTo)}
                        </strong>
                      </p>
                    </div>
                  </div>
                )}

                <div
                  className="table-fixed-header"
                  style={{ maxHeight: "520px" }}
                >
                  <Table
                    bordered
                    hover
                    size="sm"
                    className="custom-table-width"
                  >
                    <thead>
                      <tr className="bg-title-table text-center">
                        <th style={{ width: "50%" }}>
                          {t("order_history.table.meisyo")}
                        </th>
                        <th>{t("order_history.table.order_total_price")}</th>
                      </tr>
                    </thead>
                    <tbody>
                      {orderHistory != null && orderHistory.data.length > 0 ? (
                        orderHistory.data.map((order, index) => (
                          <tr key={index}>
                            <td className="text-center">{order.meisyo}</td>
                            <td className="text-right">
                              {StringUtils.moneyFormatter(order.total_price)}
                            </td>
                          </tr>
                        ))
                      ) : (
                        <tr key="1">
                          <td colSpan="6" className="text-center">
                            {t("system.data.no_result")}
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </Table>
                </div>
                <div className="text-center">
                  <Card.Header
                    as="h2"
                    bg="none"
                    style={{
                      textAlign: "center",
                      color: "#ef8412",
                      border: "none",
                      background: "none",
                    }}
                  >
                    {String(t("order_history.label.total_amount")).replace(
                      "{0}",
                      StringUtils.moneyFormatter(order_price_total)
                    )}
                  </Card.Header>
                </div>
              </Fragment>
            )}
          </Card.Body>
        </Card>
      </Col>
    </Row>
  );
}

export default OrderHistory;
