import React, { Fragment } from "react";
import "react-pro-sidebar/dist/css/styles.css";
import "bootstrap/dist/css/bootstrap.min.css";
import Layout from "./components/Layout/Layout";
import Login from "./containers/Login";
import { isAuthenticated } from "./services/storages";
import { doLogout } from "./stores/actions";
import { Spinner, Modal, Button } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import * as HttpStatus from "http-status-codes";
import { useTranslation } from "react-i18next";
import { doResetError } from "./stores/actions";

function App() {
  const { t } = useTranslation();
  const loading = useSelector((state) => state.app.loading);
  const error = useSelector((state) => state.app.error);
  const dispatch = useDispatch();
  let isLogin = isAuthenticated();
  let error_message = "";
  if (!isLogin) {
    return <Login />;
  }
  const requireAuth = (nextState, replace, next) => {
    if (!isLogin) {
      replace({
        pathname: "/",
        state: { nextPathname: nextState.location.pathname },
      });
    }
    next();
  };

  const handleLogout = () => {
    dispatch(doLogout());
  };

  const handleClose = () => {
    dispatch(doResetError());
  };

  switch (error) {
    case HttpStatus.StatusCodes.REQUEST_TIMEOUT:
      error_message = t("system.error_message.timeout_error");
      break;
    default:
      break;
  }
  return (
    <Fragment>
      <Layout requireAuth={requireAuth} handleLogout={handleLogout} />
      {loading && (
        <Spinner
          animation="border"
          role="status"
          variant="warning"
          className="loader"
        />
      )}
      <div hidden={!loading} className="loader-fade"></div>
      {error && (
        <Modal
          show={true}
          backdrop="static"
          centered
          dialogClassName="limit-quantity-warning-modal"
        >
          <Modal.Body>{error_message}</Modal.Body>
          <Modal.Footer className="text-center d-flex">
            <Button
              variant="secondary"
              style={{ minWidth: "150px" }}
              onClick={handleClose}
            >
              {t("system.label.confirmation")}
            </Button>
          </Modal.Footer>
        </Modal>
      )}
    </Fragment>
  );
}
export default App;
