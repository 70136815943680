import {
  Breadcrumb,
  Button,
  Card,
  Col,
  Form,
  Row,
  Table,
} from "react-bootstrap";
import "./index.scss";

import React, { Fragment, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { FaSearch } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import PurchasedProductImage from "../../components/PurchaseProductImage";
import { doGetPurchasedProduct } from "../../stores/actions";
import StringUtils from "../../utils/StringUtils";

function PurchasedProduct() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const purchased_products_state = useSelector(
    (state) => state.purchasedProduct
  );
  const [purchased_products, setPurchasedProducts] = useState(null);
  const [code, setCode] = useState("");
  const [name, setName] = useState("");
  const [isSearch, setSearch] = useState(false);

  useEffect(() => {
    const data = { name, code, isSearch };
    dispatch(doGetPurchasedProduct(data));
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (purchased_products_state) {
      setPurchasedProducts(purchased_products_state.data);
      setSearch(false);
    }
  }, [purchased_products_state]);

  const onChangeCode = (event) => {
    setCode(event.target.value);
  };

  const onChangeName = (event) => {
    setName(event.target.value);
  };

  const search = (event) => {
    event.preventDefault();
    const data = { name, code, isSearch: true };
    dispatch(doGetPurchasedProduct(data));
    setSearch(true);
  };

  return (
    <Row>
      <Col md={12}>
        <Breadcrumb>
          <Breadcrumb.Item active>{t("system.label.home")}</Breadcrumb.Item>
          <Breadcrumb.Item active>
            {t("purchased_product.label.purchased_product_screen")}
          </Breadcrumb.Item>
        </Breadcrumb>
        <Card>
          <Card.Header
            as="h2"
            bg="light"
            style={{ textAlign: "center", color: "#ef8412" }}
          >
            {t("purchased_product.label.purchased_product_screen")}
          </Card.Header>

          <Card.Body>
            <Form
              className="formInline"
              style={{ maxWidth: "1024px", margin: "0 auto" }}
            >
              <Row>
                <Col sm={5}>
                  <Form.Label>{t("purchased_product.search.code")}</Form.Label>
                  <Form.Control onChange={onChangeCode} />
                </Col>
                <Col sm={5}>
                  <Form.Label>{t("purchased_product.search.name")}</Form.Label>
                  <Form.Control onChange={onChangeName} />
                </Col>
                <Col sm={2}>
                  <Button type="submit" onClick={search}>
                    <FaSearch />
                    &nbsp;{t("purchased_product.search.button")}
                  </Button>
                </Col>
              </Row>
            </Form>
            <Fragment>
              <div
                className="table-fixed-header"
                style={{ maxHeight: "520px" }}
              >
                <Table bordered hover>
                  <thead>
                    <tr className="bg-title-table text-center">
                      <th>{t("purchased_product.table.thead.code")}</th>
                      <th>{t("purchased_product.table.thead.name")}</th>
                      <th style={{ width: "160px" }}>&nbsp;</th>
                      <th>{t("purchased_product.table.thead.unit")}</th>
                      <th>{t("purchased_product.table.thead.quantity")}</th>
                      <th>{t("purchased_product.table.thead.unit_price")}</th>
                      <th>
                        {t("purchased_product.table.thead.classification")}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {purchased_products ? (
                      purchased_products.map((purchased_product, index) => (
                        <tr key={index}>
                          <td className="align-middle">
                            {purchased_product.code}
                          </td>
                          <td className="align-middle text-center">
                            {purchased_product.name}
                          </td>
                          <td>
                            <PurchasedProductImage
                              code={purchased_product.code}
                              name={purchased_product.name}
                              imageUrl={purchased_product.imageurl}
                              showAddButton={true}
                            />
                          </td>
                          <td className="align-middle text-center">
                            {"" + purchased_product.unit}
                          </td>
                          <td className="align-middle text-center">
                            {purchased_product.quantity}
                          </td>
                          <td className="align-middle text-center">
                            {StringUtils.moneyFormatter(
                              purchased_product.unit_price
                            )}
                          </td>
                          <td className="align-middle text-center">
                            {purchased_product.classification}
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr key="1">
                        <td colSpan="6" className="text-center">
                          {t("system.data.no_result")}
                        </td>
                      </tr>
                    )}
                  </tbody>
                </Table>
              </div>
            </Fragment>
          </Card.Body>
        </Card>
      </Col>
    </Row>
  );
}

export default PurchasedProduct;
