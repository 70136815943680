export const INPUT_ORDER_DO_CHECK_ORDER_CLOSING_TIME =
  "INPUT_ORDER_DO_CHECK_ORDER_CLOSING_TIME";
export const INPUT_ORDER_DO_CHECK_ORDER_CLOSING_TIME_SUCCESS =
  "INPUT_ORDER_DO_CHECK_ORDER_CLOSING_TIME_SUCCESS";
export const INPUT_ORDER_DO_GET_PURCHASED_ITEMS_INFO =
  "INPUT_ORDER_DO_GET_PURCHASED_ITEMS_INFO";
export const INPUT_ORDER_DO_GET_PURCHASED_ITEMS_INFO_SUCCESS =
  "INPUT_ORDER_DO_GET_PURCHASED_ITEMS_INFO_SUCCESS";
export const INPUT_ORDER_DO_CONFIRM_ORDER = "INPUT_ORDER_DO_CONFIRM_ORDER";
export const INPUT_ORDER_DO_CONFIRM_ORDER_SUCCESS =
  "INPUT_ORDER_DO_CONFIRM_ORDER_SUCCESS";
export const INPUT_ORDER_DO_CONFIRM_ORDER_RESET =
  "INPUT_ORDER_DO_CONFIRM_ORDER_RESET";
export const INPUT_ORDER_DO_CONFIRM_ORDER_RESET_SUCCESS =
  "INPUT_ORDER_DO_CONFIRM_ORDER_RESET_SUCCESS";
export const INPUT_ORDER_DO_RELOAD = "INPUT_ORDER_DO_RELOAD";
export const INPUT_ORDER_DO_RELOAD_SUCCESS = "INPUT_ORDER_DO_RELOAD_SUCCESS";
export const INPUT_ORDER_DO_INPUT_CHANGED = "INPUT_ORDER_DO_INPUT_CHANGED";
export const INPUT_ORDER_DO_INPUT_CHANGED_SUCCESS =
  "INPUT_ORDER_DO_INPUT_CHANGED_SUCCESS";
