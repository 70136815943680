import "./index.scss";
import {
  Breadcrumb,
  Row,
  Col,
  Card,
  Button,
  Alert,
  Table,
} from "react-bootstrap";
import GridTable from "@nadavshaar/react-grid-table";
import OrderTypeForm from "./components/OrderTypeForm";
import OrderTypeSearch from "./components/OrderTypeSearch";
import { isEmpty } from "lodash";
import React, { useEffect, Fragment, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import DatePickerCustom from "./components/DatePickerCustom";
import OrderTypeSelect from "./components/OrderTypeSelect";
import StringUtils from "../../utils/StringUtils";
import CSVLinkCustom from "./components/CSVLinkCustom";
import ImportDataCSV from "./components/ImportDataCSV";
import {
  doGetOrderTypeMaintenanceMaster,
  doUpdateOrderTypeMaintenanceMaster,
  doGetOrderTypeList,
} from "../../stores/actions";
import { FaPlus } from "react-icons/fa";

export default function OrderTypeMaintenanceMaster() {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const orderType = useSelector(
    (state) => state.orderTypeMaintenanceMaster.data
  );
  const orderTypeError = useSelector(
    (state) => state.orderTypeMaintenanceMaster.error
  );
  const orderTypeAdd = useSelector(
    (state) => state.orderTypeMaintenanceMaster.status_add
  );
  const orderTypeImport = useSelector(
    (state) => state.orderTypeMaintenanceMaster.status_import
  );
  const csvErrors = useSelector(
    (state) => state.orderTypeMaintenanceMaster.csv_errors
  );
  const orderTypeEdit = useSelector(
    (state) => state.orderTypeMaintenanceMaster.status_edit
  );
  const editErrors = useSelector(
    (state) => state.orderTypeMaintenanceMaster.edit_errors
  );
  const [rowsData, setRowsData] = useState([]);
  const [modalShow, setModalShow] = useState(false);
  const [errorForm, setErrorForm] = useState({});
  const [showAlert, setShowAlert] = useState({
    status: false,
    message: "",
    key: "",
  });
  const myRef = useRef(null);

  //Load data
  useEffect(() => {
    dispatch(doGetOrderTypeMaintenanceMaster());
    dispatch(doGetOrderTypeList());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //Set data from store to local
  useEffect(() => {
    if (orderType) {
      setRowsData(orderType);
    }
  }, [orderType]);

  //Set Error message
  useEffect(() => {
    setErrorForm(orderTypeError);
    if (orderTypeAdd === true && isEmpty(orderTypeError)) {
      setModalShow(false);
      setErrorForm({});
      setShowAlert({
        status: true,
        message: t("arcmeal_order_type_maintenance_master.add.Succeeded"),
        key: "success",
      });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderTypeError, orderTypeAdd]);

  useEffect(() => {
    if (orderTypeImport === true) {
      setShowAlert({
        status: true,
        message: t("arcmeal_order_type_maintenance_master.csv.Succeeded"),
        key: "success",
      });
      dispatch(doGetOrderTypeMaintenanceMaster());
    }
    if (orderTypeImport === true && csvErrors.length > 0) {
      setShowAlert({
        status: true,
        message: t("arcmeal_order_type_maintenance_master.csv.Failed"),
        key: "warning",
      });
      alert(csvErrors);
      dispatch(doGetOrderTypeMaintenanceMaster());
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderTypeImport, csvErrors]);

  useEffect(() => {
    if (orderTypeEdit === false && isEmpty(editErrors) === false ) {
      let firstKey = Object.keys(editErrors)[0];
      setShowAlert({
        status: true,
        message: editErrors[firstKey],
        key: "danger",
      });
      dispatch(doGetOrderTypeMaintenanceMaster());
    }
    else if (orderTypeEdit === true && isEmpty(editErrors) === false ) {
      let firstKey = Object.keys(editErrors)[0];
      setShowAlert({
        status: true,
        message: editErrors[firstKey],
        key: "danger",
      });
      dispatch(doGetOrderTypeMaintenanceMaster());
    }
    else if (orderTypeEdit === true && isEmpty(editErrors) === true) {
      setShowAlert({
        status: true,
        message: t("arcmeal_order_type_maintenance_master.edit.Succeeded"),
        key: "success",
      });
      dispatch(doGetOrderTypeMaintenanceMaster());
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderTypeEdit, editErrors]);

  //Action hide modal
  const onHide = () => {
    setModalShow(false);
    setErrorForm({});
    dispatch(doGetOrderTypeMaintenanceMaster());
  };

  const saveEdit = () => {
    let dataRequest = rowsData.map((row) => {
      const requestData = {
        ...row,
        startdate: StringUtils.convertDate(row["startdate"]),
        enddate: StringUtils.convertDate(row["enddate"]),
        order_typecd: row["order_typecd"],
        order_type: row["order_type"],
        tenpocd: `${row["tenpocd4"]}`,
        deleteflg:
          row["deleteflg"] === "" || row["deleteflg"] === false ? false : true,
      };
      return requestData;
    });
    // cal api update
    dispatch(doUpdateOrderTypeMaintenanceMaster(dataRequest));
    // run this function from an event handler or pass it to useEffect to execute scroll
    myRef.current.scrollIntoView();
  };

  const columns = [
    {
      id: 1,
      field: "deleteflg",
      label: t("order_type.table.deleteflg"),
      width: "max-content",
      cellRenderer: ({ tableManager, value, data }) => (
        <div className="text-center">
          <div className="large-size">
            <input
              type="checkbox"
              className="form-check-input"
              defaultChecked={value}
              onChange={(e) => {
                let requestData = {
                  ...data,
                  deleteflg: e.target.checked ? true : false,
                };
                let rowsClone = [...tableManager.rowsApi.rows];
                let updatedRowIndex = rowsClone.findIndex(
                  (r) => r.id === data.id
                );
                rowsClone[updatedRowIndex] = requestData;
                setRowsData(rowsClone);
              }}
            />
          </div>
        </div>
      ),
    },
    {
      id: 2,
      field: "meisyo",
      label: t("order_type.table.meisyo"),
      width: "max-content",
      cellRenderer: ({ value }) => (
        <div className="rgt-cell-inner rgt-text-truncate">{value}</div>
      ),
    },
    {
      id: 3,
      field: "area_kbn",
      label: t("order_type.table.area_kbn"),
      width: "max-content",
      cellRenderer: ({ value }) => (
        <div className="rgt-cell-inner rgt-text-truncate">
          {StringUtils.areaKbnValue(value)}
        </div>
      ),
    },
    {
      id: 4,
      field: "meisyo_tenpo",
      width: "max-content",
      label: t("order_type.table.meisyo_tenpo"),
      cellRenderer: ({ value }) => (
        <div className="rgt-cell-inner rgt-text-truncate">{value}</div>
      ),
    },
    {
      id: 5,
      field: "startdate",
      width: "max-content",
      label: t("order_type.table.startdate"),
      cellRenderer: ({ tableManager, value, data, column }) => (
        <div className="rgt-cell-inner rgt-text-truncate">
          <DatePickerCustom
            date={value}
            name="startdate"
            data={data}
            column={column}
            tableManager={tableManager}
            setRowsData={setRowsData}
          />
        </div>
      ),
    },
    {
      id: 6,
      field: "enddate",
      width: "max-content",
      label: t("order_type.table.enddate"),
      cellRenderer: ({ tableManager, value, data, column }) => {
        return (
          <div className="rgt-cell-inner rgt-text-truncate">
            <DatePickerCustom
              date={value}
              name="enddate"
              data={data}
              column={column}
              tableManager={tableManager}
              setRowsData={setRowsData}
            />
          </div>
        );
      },
    },
    {
      id: 7,
      field: "order_type",
      className: "custom-order-type",
      width: "max-content",
      label: t("order_type.table.order_type"),
      cellRenderer: ({ tableManager, value, data, column }) => (
        <div className="rgt-cell-inner rgt-text-truncate">
          <OrderTypeSelect
            defaultValue={value}
            data={data}
            column={column}
            tableManager={tableManager}
            setRowsData={setRowsData}
          />
        </div>
      ),
    },
  ];

  const Header = () => {
    return <></>;
  };
  return (
    <Row>
      <Col md={12}>
        <Breadcrumb>
          <Breadcrumb.Item>{t("order_type.label.home")}</Breadcrumb.Item>
          <Breadcrumb.Item active>
            {t("order_type.label.order_type_screen")}
          </Breadcrumb.Item>
        </Breadcrumb>
        <Card className="order-type-maintenance-master">
          <Card.Header
            as="h2"
            bg="light"
            style={{ textAlign: "center", color: "#ef8412" }}
          >
            {t("order_type.label.order_type_screen")}
          </Card.Header>
          <Card.Body ref={myRef}>
            <OrderTypeSearch></OrderTypeSearch>
            <Row className="padding-bottom-row">
              <Col sm={9} md={6} lg={6}>
                {showAlert.status && (
                  <Alert
                    key={showAlert.key}
                    variant={showAlert.key}
                    onClose={() => setShowAlert(false)}
                    dismissible
                  >
                    {showAlert.message}
                  </Alert>
                )}
              </Col>
              <Col sm={1} md={2} lg={2} className="text-right">
                <CSVLinkCustom></CSVLinkCustom>
              </Col>
              <Col sm={1} md={2} lg={2} className="text-right">
                <ImportDataCSV></ImportDataCSV>
              </Col>
              <Col sm={1} md={2} lg={2} className="text-right">
                <Button variant="primary" onClick={() => setModalShow(true)}>
                  <FaPlus /> {t("delivery_patterns.button.new")}
                </Button>
              </Col>
            </Row>

            <OrderTypeForm
              size="lg"
              show={modalShow}
              onHide={onHide}
              error={errorForm}
            />
            <Fragment>
              {rowsData && rowsData.length > 0 ? (
                <GridTable
                  components={{ Header }}
                  columns={columns}
                  rows={rowsData}
                  isPaginated={false}
                  showSearch={false}
                  showRowsInformation={false}
                />
              ) : (
                <>
                  <Table bordered hover>
                    <thead>
                      <tr className="bg-title-table text-center">
                        <th>{t("order_type.table.deleteflg")}</th>
                        <th>{t("order_type.table.meisyo")}</th>
                        <th>{t("order_type.table.area_kbn")}</th>
                        <th>{t("order_type.table.meisyo_tenpo")}</th>
                        <th>{t("order_type.table.startdate")}</th>
                        <th>{t("order_type.table.enddate")}</th>
                        <th>{t("order_type.table.order_type")}</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr key="1">
                        <td colSpan="7" className="text-center">
                          {t("system.data.no_result")}
                        </td>
                      </tr>
                    </tbody>
                  </Table>
                </>
              )}
            </Fragment>
            {rowsData && rowsData.length > 0 && (
              <div className="text-center">
                <Button
                  className="btn-add"
                  variant="primary"
                  onClick={() => saveEdit()}
                >
                  {t("order_type.submit.save")}
                </Button>
              </div>
            )}
          </Card.Body>
        </Card>
      </Col>
    </Row>
  );
}
