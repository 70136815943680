import { takeEvery, call, put } from "redux-saga/effects";
import * as actions from "../actions";
import * as actionTypes from "../../constants";
import * as HttpStatus from "http-status-codes";
import { login, logout, companies } from "../../services/auth/authService";
function* doGetCompanies() {
  try {
    const response = yield call(companies);
    yield put(actions.doGetCompaniesSuccess(response.data));
  } catch (error) {
    yield put(actions.authThrowError(error));
  }
}

function* doLogin(action) {
  try {
    const { data } = action;
    // call get access token ...
    const response = yield call(login, {
      company_code: data.company_code,
      employee_code: data.employee_code,
      password: data.password,
    });
    // set data ...
    yield put(actions.doLoginSuccess(response.data));
  } catch (error) {
    yield put(actions.authThrowError(error));
  }
}

function* doLogout() {
  try {
    // call logout ...
    let response = yield call(logout);
    // set data ...
    yield put(actions.doLogoutSuccess(response.data));
  } catch (error) {
    yield put(actions.throwError(error));
  }
}

function* authThrowError(action) {
  const { response } = action.data;

  if (!response || response.status === HttpStatus.StatusCodes.NOT_FOUND) {
    yield put(
      actions.doLoginFailure({
        error: "not-found",
      })
    );
  } else {
    yield put(actions.doLoginFailure(response.data));
  }
}

function* doSetJob(action) {
  try {
    const { data } = action;
    yield put(actions.doSetJobSuccess(data));
  } catch (error) {
    yield put(actions.authThrowError(error));
  }
}

export default function* watchAuth() {
  yield takeEvery(actionTypes.AUTH_DO_GET_COMPANIES, doGetCompanies);
  yield takeEvery(actionTypes.AUTH_DO_ACCESS_TOKEN, doLogin);
  yield takeEvery(actionTypes.AUTH_DO_LOGOUT, doLogout);
  yield takeEvery(actionTypes.DO_SET_JOB, doSetJob);
  yield takeEvery(actionTypes.AUTH_THROW_ERROR, authThrowError);
}
