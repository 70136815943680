//kbn
// 1=[関東] 2=[関西] 3=[中国] 4=[九州]
export const AREA_KBN = {
  jp: {
    1: "関東",
    2: "関西",
    3: "中国",
    4: "九州",
  },
  en: {
    1: "Kanto",
    2: "Kansai",
    3: "China",
    4: "Kyushu",
  },
};

export const DO_GET_ORDERTYPE_BYSTORE = "DO_GET_ORDERTYPE_BYSTORE";
export const DO_GET_ORDERTYPE_BYSTORE_SUCCESS =
  "DO_GET_ORDERTYPE_BYSTORE_SUCCESS";
export const DO_GET_ORDERTYPE_BYSTORE_FAILED =
  "DO_GET_ORDERTYPE_BYSTORE_FAILED";

export const DO_SET_FILTER_ORDERTYPE_BYSTORE =
  "DO_SET_FILTER_ORDERTYPE_BYSTORE";
export const DO_UPDATE_ITEM_ORDERTYPE_BYSTORE =
  "DO_UPDATE_ITEM_ORDERTYPE_BYSTORE";

export const RESET_MODIFIED_ORDERTYPE_BYSTORE =
  "RESET_MODIFIED_ORDERTYPE_BYSTORE";

export const SET_ERROR_ORDERTYPE_BYSTORE = "SET_ERROR_ORDERTYPE_BYSTORE";
