import axios from "axios";
import * as storageUtils from "../../services/storages";
import * as HttpStatus from "http-status-codes";

axios.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    if (error.code === "ECONNABORTED") {
      return Promise.reject(error);
    } else {
      const {
        response: { status },
      } = error;

      if (status === HttpStatus.StatusCodes.UNAUTHORIZED) {
        if (storageUtils.isAuthenticated()) {
          storageUtils.handleLogout();
          window.location.reload();
        }
      }
    }
    return Promise.reject(error);
  }
);

export default class ApiClient {
  constructor(defaultConfig, preRequest) {
    this.defaultConfig = defaultConfig;
    this.preRequest = preRequest;

    this.client = axios;
  }

  withConfig(config) {
    const accessToken = storageUtils.getAccessToken();

    config.headers = {
      ...config.headers,
      ...(accessToken && config.auth !== false
        ? { Authorization: `Token ${accessToken}` }
        : {}),
    };

    return {
      ...this.defaultConfig,
      ...config,
    };
  }

  request(config) {
    if (this.preRequest !== undefined) {
      config = this.preRequest(config);
    }

    return this.client.request(config);
  }

  get(url, config) {
    return this.request(this.withConfig({ ...config, method: "get", url }));
  }

  post(url, data, config) {
    return this.request(
      this.withConfig({ ...config, method: "post", url, data })
    );
  }

  patch(url, data, config) {
    return this.request(
      this.withConfig({ ...config, method: "patch", url, data })
    );
  }

  put(url, data, config) {
    return this.request(
      this.withConfig({ ...config, method: "put", url, data })
    );
  }

  delete(url, data, config) {
    return this.request(
      this.withConfig({ ...config, method: "delete", url, data })
    );
  }
}
