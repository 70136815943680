import _ from "lodash";
import * as actionTypes from "../../constants";

const initialState = {
  loading: false,
  data: [],
  rootData: {},
  modified: {},
  filter: {},
  error: [],
};

const deliveryPatternsReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.DO_GET_DELIVERY_PATTERNS:
      return {
        ...state,
        loading: true,
        data: [],
        rootData: {},
        modified: {},
        error: [],
      };
    case actionTypes.DO_GET_DELIVERY_PATTERNS_SUCCESS:
      // create rootData
      const rootData = action.data.reduce((dataMap, item) => {
        dataMap[item.patterncd] = item;
        return dataMap;
      }, {});

      return {
        ...state,
        loading: false,
        data: action.data,
        rootData: rootData,
      };
    case actionTypes.DO_GET_DELIVERY_PATTERNS_FAILED:
      return {
        ...state,
        loading: false,
        error: action.data,
      };
    case actionTypes.DO_SET_FILTER_DELIVERY_PATTERNS:
      return {
        ...state,
        filter: action.data,
      };
    case actionTypes.SET_ERROR_DELIVERY_PATTERNS:
      return {
        ...state,
        error: action.data,
      };
    case actionTypes.DO_UPDATE_ITEM_DELIVERY_PATTERNS:
      const newData = state.data.map((item) => {
        const primaryKey = item.patterncd;
        if (item.patterncd === action.data.patterncd) {
          const modifiedItem = state.modified[primaryKey];
          const rootItem = state.rootData[primaryKey];
          if (modifiedItem) {
            if (
              action.data.patterncd === rootItem.patterncd &&
              action.data.patternnm === rootItem.patternnm &&
              action.data.mon_delivery_days === rootItem.mon_delivery_days &&
              action.data.tue_delivery_days === rootItem.tue_delivery_days &&
              action.data.wed_delivery_days === rootItem.wed_delivery_days &&
              action.data.thu_delivery_days === rootItem.thu_delivery_days &&
              action.data.fri_delivery_days === rootItem.fri_delivery_days &&
              action.data.sat_delivery_days === rootItem.sat_delivery_days &&
              action.data.sun_delivery_days === rootItem.sun_delivery_days &&
              action.data.remarks === rootItem.remarks &&
              action.data.deleteflg === rootItem.deleteflg
            ) {
              delete state.modified[primaryKey];
            } else {
              state.modified[primaryKey] = action.data;
            }
          } else {
            state.modified[primaryKey] = action.data;
          }

          //sort modified
          var sortModified = {};
          _(state.modified)
            .keys()
            .sort()
            .each(function (key) {
              sortModified[key] = state.modified[key];
            });
          state.modified = sortModified;

          return action.data;
        }

        return item;
      });

      return {
        ...state,
        data: newData,
      };
    default:
      return state;
  }
};

//selector
export const selectDeliveryPatternsLoading = (state) =>
  state.deliveryPatterns.loading;
export const selectDeliveryPatternsData = (state) =>
  state.deliveryPatterns.data;
export const selectDeliveryPatternsModified = (state) =>
  state.deliveryPatterns.modified;
export const selectDeliveryPatternsFilter = (state) =>
  state.deliveryPatterns.filter;
export const selectDeliveryPatternsError = (state) =>
  state.deliveryPatterns.error;

export default deliveryPatternsReducer;
