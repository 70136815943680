import apiClient from "../../utils/apiClient";

export const getOrderTypeData = async (params) => {
  const response = await apiClient.get("/api/v1/ordertype", { params });
  return response.data;
};

export const updateOrderTypeData = async (data) => {
  const response = await apiClient.patch("/api/v1/ordertype", data);
  return response;
};

export const addOrderTypeData = async (data) => {
  const response = await apiClient.post("/api/v1/ordertype", data);
  return response.data;
};

// delivery patterns source
export const getOrderTypePatternSource = async () => {
  const response = await apiClient.get("/api/v1/ordertype-patterns");
  return response.data;
};

// get name by order_typecd
// ordertype-getnamebycode?order_typecd=KS01
export const getNameByOrderTypeCode = async (params) => {
  const response = await apiClient.get("/api/v1/ordertype-getnamebycode", {
    params,
  });
  return response.data;
};
